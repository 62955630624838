const prefix = "notification";

// just sample, can be FlowItemCreated, CollectionCreated and etc
export const FlowCreated = `${prefix}:FlowCreated`;
export const FlowsUpdated = `${prefix}:FlowsUpdated`;
export const FlowEditSuccess = `${prefix}:FlowEditSuccess`;
export const FlowEditFailure = `${prefix}:FlowEditFailure`;
export const FlowEditExternalTriggersSuccess = `${prefix}:FlowEditExternalTriggersSuccess`;
export const FlowEditExternalTriggersFailure = `${prefix}:FlowEditExternalTriggersFailure`;
export const FlowEditDefinitionSuccess = `${prefix}:FlowEditDefinitionSuccess`;
export const FlowEditDefinitionFailure = `${prefix}:FlowEditDefinitionFailure`;
export const FlowEditGoalsSuccess = `${prefix}:FlowEditGoalsSuccess`;
export const FlowEditGoalsFailure = `${prefix}:FlowEditGoalsFailure`;
export const FlowDeleteSuccess = `${prefix}:FlowDeleteSuccess`;
export const FlowDeleteFailure = `${prefix}:FlowDeleteFailure`;
export const FlowDeleteAllSuccess = `${prefix}:FlowDeleteAllSuccess`;
export const FlowDeleteAllFailure = `${prefix}:FlowDeleteAllFailure`;
export const FlowDuplicateSuccess = `${prefix}:FlowDuplicateSuccess`;
export const FlowDuplicateFailure = `${prefix}:FlowDuplicateFailure`;
export const FlowDuplicateAllSuccess = `${prefix}:FlowDuplicateAllSuccess`;
export const FlowDuplicateAllFailure = `${prefix}:FlowDuplicateAllFailure`;
export const FlowEditVisibilitySuccess = `${prefix}:FlowEditVisibilitySuccess`;
export const FlowEditVisibilityFailure = `${prefix}:FlowEditVisibilityFailure`;
export const FlowEditVisibilityAllSuccess = `${prefix}:FlowEditVisibilityAllSuccess`;
export const FlowEditVisibilityAllFailure = `${prefix}:FlowEditVisibilityAllFailure`;
export const FlowStartSuccess = `${prefix}:FlowStartSuccess`;
export const FlowStartFailure = `${prefix}:FlowStartFailure`;
export const FlowStartAllSuccess = `${prefix}:FlowStartAllSuccess`;
export const FlowStartAllFailure = `${prefix}:FlowStartAllFailure`;
export const FlowGroupStartSuccess = `${prefix}:FlowGroupStartSuccess`;
export const FlowGroupStartFailure = `${prefix}:FlowGroupStartFailure`;
export const FlowGroupStartAllSuccess = `${prefix}:FlowGroupStartAllSuccess`;
export const FlowGroupStartAllFailure = `${prefix}:FlowGroupStartAllFailure`;
export const FlowsCommandCompleted = `${prefix}:FlowsCommandCompleted`;
export const FlowPublishSuccess = `${prefix}:FlowPublishSuccess`;
export const FlowPublishFailure = `${prefix}:FlowPublishFailure`;
export const FlowDiscardSuccess = `${prefix}:FlowDiscardSuccess`;
export const FlowDiscardFailure = `${prefix}:FlowDiscardFailure`;
export const AddFlowUserSuccess = `${prefix}:AddFlowUserSuccess`;
export const AddAllFlowUserSuccess = `${prefix}:AddAllFlowUserSuccess`;
export const AddAllFlowUserFail = `${prefix}:AddAllFlowUserFail`;
export const AddFlowGroupSuccess = `${prefix}:AddFlowGroupSuccess`;
export const AddFlowGroupFailure = `${prefix}:AddFlowGroupFailure`;
export const AddAllFlowGroupSuccess = `${prefix}:AddAllFlowGroupSuccess`;
export const AddAllFlowGroupFailure = `${prefix}:AddAllFlowGroupFailure`;
export const FlowLockSuccess = `${prefix}:FlowLockSuccess`;
export const FlowLockFailure = `${prefix}:FlowLockFailure`;
export const RemoveAllFlowUserSuccess = `${prefix}:RemoveAllFlowUserSuccess`;
export const RemoveAllFlowUserFailure = `${prefix}:RemoveAllFlowUserFailure`;
export const RemoveFlowUserSuccess = `${prefix}:RemoveFlowUserSuccess`;
export const RemoveFlowUserFailure = `${prefix}:RemoveFlowUserFailure`;
export const RemoveFlowGroupSuccess = `${prefix}:RemoveFlowGroupSuccess`;
export const RemoveFlowGroupFailure = `${prefix}:RemoveFlowGroupFailure`;
export const RemoveAllFlowGroupSuccess = `${prefix}:RemoveAllFlowGroupSuccess`;
export const RemoveAllFlowGroupFailure = `${prefix}:RemoveAllFlowGroupFailure`;
export const RemoveAllFlowUserPrioritiesSuccess = `${prefix}:RemoveAllFlowUserPrioritiesSuccess`;
export const RemoveAllFlowUserPrioritiesFailure = `${prefix}:RemoveAllFlowUserPrioritiesFailure`;
export const RemoveFlowUserPrioritiesSuccess = `${prefix}:RemoveFlowUserPrioritiesSuccess`;
export const RemoveFlowUserPrioritiesFailure = `${prefix}:RemoveFlowUserPrioritiesFailure`;
export const RemoveAllFlowGroupPrioritySuccess = `${prefix}:RemoveAllFlowGroupPrioritySuccess`;
export const RemoveAllFlowGroupPriorityFailure = `${prefix}:RemoveAllFlowGroupPriorityFailure`;
export const RemoveFlowGroupPrioritySuccess = `${prefix}:RemoveFlowGroupPrioritySuccess`;
export const RemoveFlowGroupPriorityFailure = `${prefix}:RemoveFlowGroupPriorityFailure`;
export const FlowAddTagsSuccess = `${prefix}:FlowAddTagsSuccess`;
export const FlowAddTagsFailure = `${prefix}:FlowAddTagsFailure`;

export const VideoAssetPublishSuccess = `${prefix}:VideoAssetPublishSuccess`;
export const VideoAssetPublishFailure = `${prefix}:VideoAssetPublishFailure`;
export const VideoAssetsDiscardSuccess = `${prefix}:VideoAssetsDiscardSuccess`;
export const VideoAssetEditSuccess = `${prefix}:VideoAssetEditSuccess`;
export const VideoAssetsUpdateCommandCompleted = `${prefix}:VideoAssetsCommandCompleted`;
export const VideoTagsEditSuccess = `${prefix}:VideoTagsEditSuccess`;
export const DeleteVideoDistributedSuccess = `${prefix}:DeleteVideoDistributedSuccess`;
export const DeleteVideoDistributedFailure = `${prefix}:DeleteVideoDistributedFailure`;
export const DeleteAllVideoSuccess = `${prefix}:DeleteAllVideoSuccess`;
export const DeleteAllVideoFailure = `${prefix}:DeleteAllVideoFailure`;
export const LockVideoAssetSuccess = `${prefix}:LockVideoAssetSuccess`;
export const VideoTagsAddSuccess = `${prefix}:VideoTagsAddSuccess`;
export const VideoTagsAddFailure = `${prefix}:VideoTagsAddFailure`;
export const ClosedCaptionsAddSuccess = `${prefix}:ClosedCaptionsAddSuccess`;
export const ClosedCaptionsAddFailure = `${prefix}:ClosedCaptionsAddFailure`;
export const BulkClosedCaptionsDeletionSuccess = `${prefix}:BulkClosedCaptionsDeletionSuccess`;
export const BulkClosedCaptionsDeletionFailure = `${prefix}:BulkClosedCaptionsDeletionFailure`;
export const ManifestEditSuccess = `${prefix}:ManifestEditSuccess`;
export const ThumbnailEditSuccess = `${prefix}:ThumbnailEditSuccess`;
export const ManifestEditFailure = `${prefix}:ManifestEditFailure`;
export const ThumbnailEditFailure = `${prefix}:ThumbnailEditFailure`;

export const PdfPublishSuccess = `${prefix}:PdfPublishSuccess`;
export const PdfDiscardSuccess = `${prefix}:PdfDiscardSuccess`;
export const PdfLockSuccess = `${prefix}:LockPdfSuccess`;
export const PdfEditSuccess = `${prefix}:PdfEditSuccess`;
export const DeleteAllPdfSuccess = `${prefix}:DeleteAllPdfSuccess`;
export const DeleteAllPdfFailure = `${prefix}:DeleteAllPdfFailure`;
export const DeletePdfDistributedSuccess = `${prefix}:DeletePdfDistributedSuccess`;
export const DeletePdfDistributedFailure = `${prefix}:DeletePdfDistributedFailure`;
export const PdfTagsAddSuccess = `${prefix}:PdfTagsAddSuccess`;
export const PdfTagsAddFailure = `${prefix}:PdfTagsAddFailure`;
export const PdfTagsEditSuccess = `${prefix}:PdfTagsEditSuccess`;
export const PdfThumbnailEditSuccess = `${prefix}:PdfThumbnailEditSuccess`;
export const PdfThumbnailEditFailure = `${prefix}:PdfThumbnailEditFailure`;
export const PdfUpdateCommandCompleted = `${prefix}:PdfsCommandCompleted`;

export const EmailEditSuccess = `${prefix}:EmailEditSuccess`;
export const EmailContentEditSuccess = `${prefix}:EmailContentEditSuccess`;
export const EmailUpdateCommandCompleted = `${prefix}:EmailsCommandCompleted`;
export const EmailPublishSuccess = `${prefix}:EmailPublishSuccess`;
export const EmailPeopleEditSuccess = `${prefix}:EmailPeopleEditSuccess`;
export const EmailPeopleDeleteSuccess = `${prefix}:EmailPeopleDeleteSuccess`;
export const EmailPeopleDeleteFailure = `${prefix}:EmailPeopleDeleteFailure`;
export const EmailRevertSuccess = `${prefix}:EmailRevertSuccess`;
export const EmailDuplicationSuccess = `${prefix}:EmailDuplicationSuccess`;
export const EmailDuplicationFailure = `${prefix}:EmailDuplicationFailure`;
export const EmailTestSuccess = `${prefix}:EmailTestSuccess`;
export const EmailEditModeSuccess = `${prefix}:EmailEditModeSuccess`;
export const CancelSendSessionSuccess = `${prefix}:CancelSendSessionSuccess`;
export const CancelSendSessionFailure = `${prefix}:CancelSendSessionFailure`;
export const EmailDeleteSuccess = `${prefix}:EmailDeleteSuccess`;
export const EmailDeleteFailure = `${prefix}:EmailDeleteFailure`;
export const AddEmailTagsSuccess = `${prefix}:AddEmailTagsSuccess`;
export const AddEmailTagsFailure = `${prefix}:AddEmailTagsFailure`;
export const CreateEmailSendSessionSuccess = `${prefix}:CreateEmailSendSessionSuccess`;
export const CreateEmailSendSessionFailure = `${prefix}:CreateEmailSendSessionFailure`;
export const MessageEditSuccess = `${prefix}:MessageEditSuccess`;
export const MessageUpdateCommandCompleted = `${prefix}:MessagesCommandCompleted`;
export const MessageContentEditSuccess = `${prefix}:MessageContentEditSuccess`;
export const MessageContentEditFailure = `${prefix}:MessageContentEditFailure`;
export const MessagePublishSuccess = `${prefix}:MessagePublishSuccess`;
export const MessageMadeDraftSuccess = `${prefix}:MessageMadeDraftSuccess`;
export const MessageRevertSuccess = `${prefix}:MessageRevertSuccess`;
export const MessageDeleteSuccess = `${prefix}:MessageDeleteSuccess`;
export const MessageDeleteFailure = `${prefix}:MessageDeleteFailure`;
export const CreateMessageSendSessionSuccess = `${prefix}:CreateMessageSendSessionSuccess`;
export const CreateMessageSendSessionFailure = `${prefix}:CreateMessageSendSessionFailure`;
export const EventPublishSuccess = `${prefix}:ExternalEventPublishSuccess`;
export const EventInfoEditSuccess = `${prefix}:ExternalEventEditInfoSuccess`;
export const EventUpdateCommandCompleted = `${prefix}:ExternalEventCommandCompleted`;
export const EventDiscardedSuccess = `${prefix}:ExternalEventRevertSuccess`;
export const EventEditSessionsSuccess = `${prefix}:ExternalEventEditSessionsSuccess`;
export const DuplicateAllExternalEventSuccess = `${prefix}:DuplicateAllExternalEventSuccess`;
export const DuplicateAllExternalEventFailure = `${prefix}:DuplicateAllExternalEventFailure`;
export const DuplicateExternalEventDistributedSuccess = `${prefix}:DuplicateExternalEventDistributedSuccess`;
export const DuplicateExternalEventDistributedFailure = `${prefix}:DuplicateExternalEventDistributedFailure`;
export const ExternalEventEditModeSuccess = `${prefix}:ExternalEventEditModeSuccess`;
export const EventDeleteSuccess = `${prefix}:ExternalEventDeleteSuccess`;
export const EventDeleteFailure = `${prefix}:ExternalEventDeleteFailure`;
export const ExternalEventAddTagsSuccess = `${prefix}:ExternalEventAddTagsSuccess`;
export const ExternalEventAddTagsFailure = `${prefix}:ExternalEventAddTagsFailure`;
export const ExternalEventReminderDeletionSuccess = `${prefix}:ExternalEventReminderDeletionSuccess`;
export const ExternalEventReminderDeletionFailure = `${prefix}:ExternalEventReminderDeletionFailure`;

export const AssessmentInfoEditSuccess = `${prefix}:AssessmentInfoEditSuccess`;
export const AssessmentQuestionsEditSuccess = `${prefix}:AssessmentQuestionsEditSuccess`;
export const AssessmentUpdateCommandCompleted = `${prefix}:AssessmentsCommandCompleted`;
export const AssessmentTagsEditSuccess = `${prefix}:AssessmentTagsEditSuccess`;
export const AssessmentPublishSuccess = `${prefix}:AssessmentPublishSuccess`;
export const AssessmentDiscardSuccess = `${prefix}:AssessmentDiscardSuccess`;
export const AssessmentDuplicateSuccess = `${prefix}:AssessmentDuplicateSuccess`;
export const AssessmentDuplicateFailure = `${prefix}:AssessmentDuplicateFailure`;
export const AssessmentDuplicateAllSuccess = `${prefix}:AssessmentDuplicateAllSuccess`;
export const AssessmentDuplicateAllFailure = `${prefix}:AssessmentDuplicateAllFailure`;
export const AssessmentDeleteSuccess = `${prefix}:AssessmentDeleteSuccess`;
export const AssessmentDeleteFailure = `${prefix}:AssessmentDeleteFailure`;
export const AssessmentDeleteAllSuccess = `${prefix}:AssessmentDeleteAllSuccess`;
export const AssessmentDeleteAllFailure = `${prefix}:AssessmentDeleteAllFailure`;
export const AssessmentLockSuccess = `${prefix}:AssessmentLockSuccess`;
export const AssessmentTagsAddSuccess = `${prefix}:AssessmentTagsAddSuccess`;
export const AssessmentTagsAddFailure = `${prefix}:AssessmentTagsAddFailure`;

export const SurveyUpdateSuccess = `${prefix}:SurveyUpdateSuccess`;
export const SurveyQuestionsUpdateSuccess = `${prefix}:SurveyQuestionsUpdateSuccess`;
export const SurveyUpdateCommandCompleted = `${prefix}:SurveysCommandCompleted`;
export const SurveyPublishSuccess = `${prefix}:SurveyPublishSuccess`;
export const SurveyDiscardSuccess = `${prefix}:SurveyDiscardSuccess`;
export const SurveyDuplicationSuccess = `${prefix}:DuplicateSurveyDistributedSuccess`;
export const SurveyDuplicationFailure = `${prefix}:DuplicateSurveyDistributedFailure`;
export const SurveyAllDuplicationSuccess = `${prefix}:DuplicateAllSurveySuccess`;
export const SurveyAllDuplicationFailure = `${prefix}:DuplicateAllSurveyFailure`;
export const SurveyAllDeleteSuccess = `${prefix}:DeleteAllSurveySuccess`;
export const SurveyDeleteSuccess = `${prefix}:DeleteSurveyDistributedSuccess`;
export const SurveyAllDeleteFailure = `${prefix}:SurveyAllDeleteFailure`;
export const SurveyDeleteFailure = `${prefix}:DeleteSurveyDistributedFailure`;
export const SurveyEditSuccess = `${prefix}:SurveyEditSuccess`;
export const SurveyAddTagsSuccess = `${prefix}:SurveyAddTagsSuccess`;
export const SurveyAddTagsFailure = `${prefix}:SurveyAddTagsFailure`;

export const EmailTemplateEditSuccess = `${prefix}:EmailTemplateEditSuccess`;
export const EmailTemplateContentEditSuccess = `${prefix}:EmailTemplateContentEditSuccess`;
export const EmailTemplateUpdateCommandCompleted = `${prefix}:EmailTemplatesCommandCompleted`;
export const EmailTemplatePublishSuccess = `${prefix}:EmailTemplatePublishSuccess`;
export const EmailTemplateDiscardSuccess = `${prefix}:EmailTemplateDiscardSuccess`;
export const EmailTemplateLockSuccess = `${prefix}:EmailTemplateLockSuccess`;

export const DirectSendSendingProfilePublishSuccess = `${prefix}:DirectSendSendingProfilePublishSuccess`;
export const DirectSendSendingProfileDiscardSuccess = `${prefix}:DirectSendSendingProfileDiscardSuccess`;
export const DirectSendSendingProfileUpdateSuccess = `${prefix}:DirectSendSendingProfileUpdateSuccess`;
export const DirectSendSendingProfileUpdateCommandCompleted = `${prefix}:DirectSendSendingProfilesCommandCompleted`;
export const DirectSendSendingProfileLockSuccess = `${prefix}:DirectSendSendingProfileLockSuccess`;

export const SmtpSendingProfileConfigEditSuccess = `${prefix}:SmtpSendingProfileConfigEditSuccess`;
export const SmtpSendingProfileSettingsEditSuccess = `${prefix}:SmtpSendingProfileSettingsEditSuccess`;
export const SmtpSendingProfileUpdateCommandCompleted = `${prefix}:SmtpSendingProfilesCommandCompleted`;
export const SmtpSendingProfilePublishSuccess = `${prefix}:SmtpSendingProfilePublishSuccess`;
export const SmtpSendingProfileVerifySuccess = `${prefix}:SmtpSendingProfileVerifySuccess`;
export const SmtpSendingProfileVerifyFail = `${prefix}:SmtpSendingProfileVerifyFail`;
export const SmtpSendingProfileDiscardSuccess = `${prefix}:SmtpSendingProfileDiscardSuccess`;
export const SmtpSendingProfileLockSuccess = `${prefix}:SmtpSendingProfileLockSuccess`;

export const LandingPageEditSuccess = `${prefix}:LandingPageEditSuccess`;
export const LandingPageContentEditSuccess = `${prefix}:LandingPageContentEditSuccess`;
export const LandingPageUpdateCommandCompleted = `${prefix}:LandingPagesCommandCompleted`;
export const LandingPagePublishSuccess = `${prefix}:LandingPagePublishSuccess`;
export const LandingPageDiscardSuccess = `${prefix}:LandingPageDiscardSuccess`;
export const LandingPageLockSuccess = `${prefix}:LandingPageLockSuccess`;

export const FeedbackPageEditSuccess = `${prefix}:FeedbackPageEditSuccess`;
export const FeedbackPageUpdateCommandCompleted = `${prefix}:FeedbackPagesCommandCompleted`;
export const FeedbackPagePublishSuccess = `${prefix}:FeedbackPagePublishSuccess`;
export const FeedbackPageDiscardSuccess = `${prefix}:FeedbackPageDiscardSuccess`;
export const FeedbackPageLockSuccess = `${prefix}:FeedbackPageLockSuccess`;

export const CampaignPublishSuccess = `${prefix}:CampaignPublishSuccess`;
export const CampaignEditSuccess = `${prefix}:CampaignEditSuccess`;
export const CampaignContentEditSuccess = `${prefix}:CampaignContentEditSuccess`;
export const CampaignUpdateCommandCompleted = `${prefix}:CampaignsCommandCompleted`;
export const CampaignDiscardSuccess = `${prefix}:CampaignDiscardSuccess`;
export const CampaignLockSuccess = `${prefix}:CampaignLockSuccess`;

export const PackUpdateSuccess = `${prefix}:PackEditSuccess`;
export const PackUpdateCommandCompleted = `${prefix}:PacksCommandCompleted`;
export const PackPublishSuccess = `${prefix}:PackPublishSuccess`;
export const PacksCommandCompleted = `${prefix}:PacksCommandCompleted`;
export const PackEditSuccess = `${prefix}:PackEditSuccess`;
export const PackLockSuccess = `${prefix}:PackLockSuccess`;
export const PackDiscardSuccess = `${prefix}:PackDiscardSuccess`;
export const PackContentEditSuccess = `${prefix}:EditPackContentSuccess`;
export const PackDeleteSuccess = `${prefix}:PackDeleteSuccess`;
export const PackDeleteFailure = `${prefix}:PackDeleteFailure`;
export const EditAccountPackVisibilitySuccess = `${prefix}:EditAccountPackVisibilitySuccess`;
export const EditAccountTypePackVisibilitySuccess = `${prefix}:EditAccountTypePackVisibilitySuccess`;
export const AddContentToPackSuccess = `${prefix}:AddContentToPackSuccess`;
export const AddContentToPackFailure = `${prefix}:AddContentToPackFailure`;

export const UserFlowPriorityChangedSuccess = `${prefix}:UserFlowPriorityChangedSuccess`;
export const UserFlowPriorityChangedFailure = `${prefix}:UserFlowPriorityChangedFailure`;

export const AddPackUserSuccess = `${prefix}:AddPackUserSuccess`;
export const AddAllPackUserSuccess = `${prefix}:AddAllPackUserSuccess`;
export const AddAllPackUserFailure = `${prefix}:AddAllPackUserFailure`;

export const AddPackGroupSuccess = `${prefix}:AddPackGroupSuccess`;
export const AddAllPackGroupSuccess = `${prefix}:AddAllPackGroupSuccess`;
export const AddAllPackGroupFailure = `${prefix}:AddAllPackGroupFailure`;

export const GroupFlowPriorityChangedSuccess = `${prefix}:GroupFlowPriorityChangedSuccess`;
export const GroupFlowPriorityChangedFailure = `${prefix}:GroupFlowPriorityChangedFailure`;

// noAdd

export const SetAllFlowGroupPrioritySuccess = `${prefix}:SetAllFlowGroupPrioritySuccess`;
export const SetAllFlowGroupPriorityFailure = `${prefix}:SetAllFlowGroupPriorityFailure`;
export const SetFlowUserPrioritiesSuccess = `${prefix}:SetFlowUserPrioritiesSuccess`;
export const SetFlowUserPrioritiesFailure = `${prefix}:SetFlowUserPrioritiesFailure`;

export const SetAllFlowTemplateGroupPrioritySuccess = `${prefix}:SetAllFlowTemplateGroupPrioritySuccess`;
export const SetAllFlowTemplateGroupPriorityFailure = `${prefix}:SetAllFlowTemplateGroupPriorityFailure`;
export const SetFlowTemplateGroupPrioritySuccess = `${prefix}:SetFlowTemplateGroupPrioritySuccess`;
export const SetFlowTemplateGroupPriorityFailure = `${prefix}:SetFlowTemplateGroupPriorityFailure`;

export const SetAllFlowUserPrioritiesSuccess = `${prefix}:SetAllFlowUserPrioritiesSuccess`;
export const SetAllFlowUserPrioritiesFailure = `${prefix}:SetAllFlowUserPrioritiesFailure`;
export const SetFlowGroupPrioritySuccess = `${prefix}:SetFlowGroupPrioritySuccess`;
export const SetFlowGroupPriorityFailure = `${prefix}:SetFlowGroupPriorityFailure`;

export const AddAllUserLicenseMessageSuccess = `${prefix}:AddAllUserLicenseMessageSuccess`;
export const AddAllUserLicenseMessageFailure = `${prefix}:AddAllUserLicenseMessageFailure`;
export const AddUserLicenseSuccess = `${prefix}:AddUserLicenseSuccess`;
export const AddUserLicenseFailure = `${prefix}:AddUserLicenseFailure`;

export const RemoveAllUserLicenseMessageSuccess = `${prefix}:RemoveAllUserLicenseMessageSuccess`;
export const RemoveAllUserLicenseMessageFailure = `${prefix}:RemoveAllUserLicenseMessageFailure`;
export const RemoveUserLicenseSuccess = `${prefix}:RemoveUserLicenseSuccess`;
export const RemoveUserLicenseFailure = `${prefix}:RemoveUserLicenseFailure`;

export const AddAllGroupLicenseMessageSuccess = `${prefix}:AddAllGroupLicenseMessageSuccess`;
export const AddAllGroupLicenseMessageFailure = `${prefix}:AddAllGroupLicenseMessageFailure`;
export const AddGroupLicenseSuccess = `${prefix}:AddGroupLicenseSuccess`;
export const AddGroupLicenseFailure = `${prefix}:AddGroupLicenseFailure`;

export const RemoveAllGroupLicenseMessageSuccess = `${prefix}:RemoveAllGroupLicenseMessageSuccess`;
export const RemoveAllGroupLicenseMessageFailure = `${prefix}:RemoveAllGroupLicenseMessageFailure`;
export const RemoveGroupLicenseSuccess = `${prefix}:RemoveGroupLicenseSuccess`;
export const RemoveGroupLicenseFailure = `${prefix}:RemoveGroupLicenseFailure`;

export const EditPackIsPrivateSuccess = `${prefix}:EditPackIsPrivateSuccess`;

export const VideoEditVisibilitySuccess = `${prefix}:VideoEditVisibilitySuccess`;
export const VideoEditVisibilityFailure = `${prefix}:VideoEditVisibilityFailure`;
export const VideoEditVisibilityAllSuccess = `${prefix}:VideoEditVisibilityAllSuccess`;
export const VideoEditVisibilityAllFailure = `${prefix}:VideoEditVisibilityAllFailure`;

export const PdfEditVisibilitySuccess = `${prefix}:PdfEditVisibilitySuccess`;
export const PdfEditVisibilityFailure = `${prefix}:PdfEditVisibilityFailure`;
export const PdfEditVisibilityAllSuccess = `${prefix}:PdfEditVisibilityAllSuccess`;
export const PdfEditVisibilityAllFailure = `${prefix}:PdfEditVisibilityAllFailure`;
