import { ColumnOption } from "interfaces/columnOptions";
import { ContentColumns, ContentItem, FlowItem } from "../../types/models";
import { Tooltip } from "components/common/tooltip";
import { EditPriorityLinkButton, TextTruncate } from "components";
import { Title } from "components/listViewTemplates";
import { PublishedStatus } from "components/common/publishedStatus";
import { PriorityLevels, PublishedStatusTypes } from "enums";
import { ObjectiveLabel } from "features/Library/Flows/common/ObjectiveLabel/ObjectiveLabel";
import PriorityCell from "components/priorityLevels/priorityCell/PriorityCell";
import { ContentType, PeopleType } from "features/Library/PeopleAssignments/types";
import EllipsisPopupButton from "components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";
import { noop } from "lodash";

interface GetColumnOptionsParams {
  onTitleClick: (id: number) => void;
  selectedIds: number[];
  contextMenuButtonHandlers: {
    onEditPriority: (item: FlowItem) => void;
    onClearPriority: (ids: number[]) => void;
  };
  disabled: boolean;
}

/* istanbul ignore next */
export const getFlowColumnOptions = (props: GetColumnOptionsParams): ColumnOption<FlowItem>[] => {
  const {
    onTitleClick,
    selectedIds,
    contextMenuButtonHandlers: { onEditPriority, onClearPriority },
    disabled,
  } = props;
  return [
    {
      name: ContentColumns.Title,
      width: 3,
      isSortable: true,
      render: (item) => (
        <Title
          title={item.title}
          className={"normal-color"}
          onTitleClick={() => (disabled ? noop() : onTitleClick(item.id))}
        />
      ),
    },
    {
      name: ContentColumns.Objective,
      width: 3,
      isSortable: true,
      render: (item) => <ObjectiveLabel objective={item.goals?.objective} />,
    },
    {
      name: ContentColumns.Publisher,
      width: 2,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: ContentColumns.Status,
      width: 2,
      isSortable: false,
      render: (item) => (
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(item.published)} />
      ),
    },
    {
      name: ContentColumns.PriorityLevel,
      width: 5,
      isSortable: false,
      render: (item) => (
        <PriorityCell
          inherited={item.isInherited}
          peopleType={PeopleType.Group}
          contentType={ContentType.Flow}
          priorityLevel={item.priorityLevelId as PriorityLevels}
          dueDate={item.dueDate}
          daysToComplete={item.daysToComplete}
        />
      ),
    },
    {
      name: "",
      width: 1,
      render: (item) => (
        <EllipsisPopupButton circle disabled={item.isInherited || selectedIds.includes(item.id) || disabled}>
          <EditPriorityLinkButton onClick={() => onEditPriority(item)} />
          <RemovePriorityButton
            key="remove-priority-menu-button"
            onClick={() => onClearPriority([item.id])}
            isDisabled={!item.canRemove}
            context="people"
            entityType={ContentType.Flow}
            peopleType={PeopleType.Group}
            showTooltip={!item.canRemove}
            multiple={false}
          />
        </EllipsisPopupButton>
      ),
      className: "align-right",
    },
  ];
};

/* istanbul ignore next */
export const getColumnOptions = (
  onTitleClick: (id: number) => void,
  disabled: boolean,
): ColumnOption<ContentItem>[] => {
  return [
    {
      name: ContentColumns.Title,
      width: 4,
      isSortable: true,
      render: (item) => (
        <Title
          title={item.title}
          className="normal-color"
          onTitleClick={() => (disabled ? noop : onTitleClick(item.id))}
        />
      ),
    },
    {
      name: ContentColumns.Publisher,
      width: 3,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: ContentColumns.Status,
      width: 6,
      isSortable: false,
      render: (item) => (
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!item.isDraft)} />
      ),
    },
  ];
};
