import { createAsyncThunk } from "@reduxjs/toolkit";

import DataService from "../../../../Application/services/dataServices/typedDataService";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";

import { AppDispatch, AppThunk, RootState } from "../../../../Application/globaltypes/redux";
import { PdfOverviewRequest } from "../../types/requests";
import { FetchActionPayload, DistributedOpUpdateParams } from "../../../../../interfaces";
import { escapeTerm } from "../../../../../utils/searchUtils";
import { Filters } from "../../../../../utils/queryUtils";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { getPrefix } from "../../../../Application/slices/models";
import { formatFilters } from "../../../../../utils/filterMapUtils";
import { PdfOverview } from "../../types/state";
import { pdfsAppliedFilterSelector, pdfSearchSelector } from "../../selectors";
import { pluralize } from "../../../../../utils/stringUtils";
import { fetchFailure, updatePdfBegin, updatePdfSuccess } from "../slices/pdfOverviewSlice";
import { DEFAULT_LOAD_COUNT } from "../../types/constants";
import { pdfFilterTypes } from "../../types/models";

export interface PdfsRequest {
  appliedFilter: Filters;
  skip: number;
  top: number;
  term: string;
  sortBy?: string;
  sortOrder?: string;
}

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const sendGetPdfRequest = (payload: PdfsRequest, signal?: AbortSignal) => {
  const { appliedFilter, skip, top, term, sortBy, sortOrder } = payload;
  const filterQueryParams = formatFilters(appliedFilter, pdfFilterTypes);

  return DataService.content.getContent(
    {
      top,
      skip,
      sortBy,
      sortOrder,
      filters: filterQueryParams,
      term,
      showPurchased: true,
      contentType: "pdf",
    },
    signal,
  );
};

export const fetchOverviewPdfs = createAsyncThunk<FetchActionPayload<PdfOverview>, PdfOverviewRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Pdfs,
    name: "overview",
  }),
  async (request: PdfOverviewRequest, { getState, signal }) => {
    const rootState = getState() as RootState;
    const appliedFilter = pdfsAppliedFilterSelector(rootState);
    const searchTerm = escapeTerm(pdfSearchSelector(rootState));

    const result = await sendGetPdfRequest(
      {
        appliedFilter,
        skip: request.skip ?? 0,
        top: request.top ?? DEFAULT_LOAD_COUNT,
        term: searchTerm ?? "",
        sortBy: request.sortBy,
        sortOrder: request.sortDirection,
      },
      signal,
    );

    const data = result.data.map((item) => ({ ...item, thumbnailUrl: item.bag?.thumbnailUrl! }));

    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: data,
      totalCount: recordsCount,
      append: request.append,
    };
  },
);

export const deletePdfs =
  (ids: number[], fetchPdfs: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getOperationProps = async () => {
      const { data } = await DataService.pdfs.deletePdfsAsync(ids);
      return data;
    };

    const idsCount = ids.length;
    const entity = pluralize("PDF", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "DeletePdf",
      title: "Deletion of PDFs",
      onCompleted: () => fetchPdfs(),
      getOperationProps,
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been deleted!`,
      failureTransientMessage: `${entity} delete failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const updatePdf = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(updatePdfBegin());
  try {
    const result = await DataService.pdfs.getPdfAsync(id);
    dispatch(updatePdfSuccess(result.data as unknown as PdfOverview));
  } catch (error) {
    dispatch(fetchFailure(error));
  }
};

export const changeVisibility =
  (ids: number[], visible: boolean, onCompleted: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const idsCount = ids.length;
    const entity = pluralize("PDF", idsCount);
    const asset = pluralize("Asset", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "VisibilityVideosDistributedOperation",
      title: `${visible ? "Unhiding" : "Hiding"} ${asset}`,
      onCompleted,
      getOperationProps: async () => {
        const { data } = await DataService.pdfs.changeVisibility(ids, visible);
        return data;
      },
      successTitle:`${entity} ${visible ? "Unhidden" : "Hidden"}!`,
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been ${visible ? "unhidden" : "hidden"} successfully!`,
      failureTransientMessage: `${entity} ${visible ? "unhiding" : "hiding"} failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
