import { FC, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../Application/globaltypes/redux";
import CustomerPackReport from "./Customer/CustomerPackReport";
import SpPackReport from "./SolutionProvider/SpPackReport";
import { useAddOnQuery } from "../Content/queries/useAddOnQuery";
import { Loader } from "semantic-ui-react";

import "./reportingPacks.scss";

export const ReportingPacks: FC<PropsFromRedux> = ({ accountId }) => {
  const { data: addons, isLoading } = useAddOnQuery(accountId);

  const body = useMemo(() => {
    if (!isLoading) {
      const hasCreateAccount = addons?.find((addon) => addon.name === "Create Accounts")?.isEnabled;
      return hasCreateAccount ? <SpPackReport /> : <CustomerPackReport />;
    }
    return <Loader active />;
  }, [isLoading, addons]);

  return <div className="nested-content reportingPacksRoot">{body}</div>;
};

const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
});

const connector = connect(mapStateToProps, {});
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportingPacks);
