import { PublishedStatusTypes } from "../../../../enums";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import SortOptions from "../../../../enums/SortOptions";
import { ColumnOption } from "../../../../interfaces/columnOptions";
import { Title } from "../../../../components/listViewTemplates";
import { Tooltip } from "../../../../components/common/tooltip";
import { TextTruncate } from "../../../../components";
import { PublishedStatus } from "../../../../components/common/publishedStatus";
import { PdfOverview } from "../types/state";
import { PdfContentItemMenu } from "./PdfContentItemMenu/PdfContentItemMenu";
import { PdfPublishConfirmationParams } from "../types/models";

export enum Columns {
  Title = "Title", //NOSONAR
  Publisher = "Publisher",
  Status = "Status",
  Added = "Added",
  Modified = "Modified",
}

export const sortOptions = [
  {
    text: "Most Recent",
    value: SortOptions.CreatedDateDesc,
    default: true,
  },
  {
    text: "Last Modified",
    value: SortOptions.ModifiedDateDesc,
  },
  {
    text: "Sorted A-Z",
    value: SortOptions.Title,
  },
];

export interface GetColumnOptionsParams {
  readonly?: boolean;
  handleEditClick: (id: number, isDraft: boolean) => void;
  handlePublishClick: (params: PdfPublishConfirmationParams) => void;
  handleDeleteClick: (ids: number[], flowsCount?: number, packsCount?: number) => void;
  hideDisabled?: boolean;
  onToggleVisibility: (id: number[], hidden: boolean) => () => void;
}

export const getColumnOptions = (params: GetColumnOptionsParams): ColumnOption<PdfOverview>[] => {
  return [
    {
      name: Columns.Title,
      width: 4,
      isSortable: true,
      render: (pdf) => <Title url={pdf.id.toString()} title={pdf.title} clamp={2} className="normal-color" />,
    },
    {
      name: Columns.Publisher,
      width: 3,
      isSortable: false,
      render: (pdf) => <Tooltip target={<TextTruncate>{pdf.publisher}</TextTruncate>} content={pdf.publisher} />,
    },
    {
      name: Columns.Status,
      width: 3,
      isSortable: false,
      render: (pdf) => (
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!pdf.isDraft)} />
      ),
    },
    {
      name: Columns.Added,
      width: 2,
      isSortable: true,
      render: (pdf) => dateTimeUtils.formatDate(pdf.dateCreated),
    },
    {
      name: Columns.Modified,
      width: 2,
      isSortable: true,
      render: (pdf) => dateTimeUtils.formatDate(pdf.dateModified),
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      isSortable: false,
      render: (pdf, isChecked) => {
        return (
          <PdfContentItemMenu
            circle
            outlinedEllipsis
            item={pdf}
            isChecked={isChecked}
            readonly={params.readonly}
            hasPermission={!params.readonly}
            handleEditClick={params.handleEditClick}
            handlePublishClick={params.handlePublishClick}
            handleDeleteClick={params.handleDeleteClick}
            onToggleVisibility={params.onToggleVisibility}
            hideDisabled={params.hideDisabled}
          />
        );
      },
    },
  ];
};
