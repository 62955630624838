import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import {
  EnrollmentRule,
  defaultEnrollmentRule,
} from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/utils";
import _ from "lodash";

export interface TemplateConfigurationView {
  name: string;
  description: string;
  imageUrl: string;
  dateModified?: string;
  isWithAutoEnroll: boolean;
  autoStartFlows: boolean;
}

export interface GroupTemplateState {
  configuration: TemplateConfigurationView;
  isEnrollmentValid: boolean;
  templateUsersOnly: boolean | null;
  enrollmentRules: EnrollmentRule[];
  isLoading: boolean;
  error: Error | undefined;
  groupId: number;
  isAccessAuthorized?: boolean;
  isPreview?: boolean;
}

const initialState: GroupTemplateState = {
  configuration: {
    name: "",
    description: "",
    imageUrl: "",
    isWithAutoEnroll: true,
    autoStartFlows: false,
  },
  templateUsersOnly: null,
  isEnrollmentValid: false,
  enrollmentRules: [defaultEnrollmentRule],
  isLoading: false,
  error: undefined,
  groupId: -1,
  isAccessAuthorized: true,
  isPreview: false,
};

const slice = createSlice({
  name: `${ReducerNamespaceTypes.People}/${ReducerEntityPrefixTypes.Groups}/GroupTemplate`,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setGroupId: (state, action: PayloadAction<number>) => {
      state.groupId = action.payload;
    },
    setConfiguration: (state, action: PayloadAction<TemplateConfigurationView>) => {
      state.configuration = action.payload;
    },
    setEnrollmentRules: (state, action: PayloadAction<EnrollmentRule[]>) => {
      state.enrollmentRules = _.isEmpty(action.payload) ? [defaultEnrollmentRule] : action.payload;
    },
    setIsEnrollmentValid: (state, action: PayloadAction<boolean>) => {
      state.isEnrollmentValid = action.payload;
    },
    setTemplateUsersOnly: (state, action: PayloadAction<boolean | null>) => {
      state.templateUsersOnly = action.payload;
    },
    setAccessAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isAccessAuthorized = action.payload;
    },
    setIsPreview: (state, action: PayloadAction<boolean>) => {
      state.isPreview = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setConfiguration,
  setEnrollmentRules,
  setIsEnrollmentValid,
  reset,
  setIsLoading,
  setGroupId,
  setAccessAuthorized,
  setIsPreview,
  setTemplateUsersOnly,
} = slice.actions;

export default slice.reducer;
