import { SemanticICONS } from "semantic-ui-react";

export type EmailLineData = {
  Clicks: number;
  CumulativeClicks: number;
  CumulativeOpens: number;
  CumulativeSends: number;
  Date: string;
  Opens: number;
  Sends: number;
};

export type EmailActivity = {
  Sends: number;
  Opens: number;
  Clicks: number;
  OpenRate: number;
  ClickRate: number;
};

// Currently only in use by ReportingOverview,
// can be removed once we make a change to that
// Written 10/4/2023
export type EmailPerformance = {
  Sends: number;
  Opens: number;
  Clicks: number;
};

export const EmailStatusToIcon: { [key in EmailStatuses]: SemanticICONS } = {
  Completed: "check circle",
  Scheduled: "calendar check",
  InProgress: "clock",
  Canceled: "times circle",
  None: "exclamation circle",
};

export const EmailStatusToColor: { [key in EmailStatuses]: string } = {
  Completed: "icon-send-complete",
  Scheduled: "icon-send-scheduled",
  InProgress: "icon-send-progress",
  Canceled: "icon-send-canceled",
  None: "icon-send-failed",
};

export const EmailStatusToString: { [key in EmailStatuses]: string } = {
  Completed: "Complete",
  Scheduled: "Scheduled",
  InProgress: "In Progress",
  Canceled: "Canceled",
  None: "Failed",
};

export enum EmailSendDetailStatus {
  Scheduled = "Scheduled",
  Send = "Send",
  Sent = "Sent",
  Opened = "Opened",
  Clicked = "Clicked",
  Failed = "Failed",
  Cancelled = "Cancelled",
}

export enum SendSessionOrigin {
  Direct = "Direct",
  Flow = "Flow",
}

export type EmailEngagement = {
  AvgSendSize: number;
  ClickThroughRate: number; // between 0 & 1
  ClickToOpen: number;
  Clicks: number;
  Customers: number;
  Emails: number;
  OpenRate: number; // between 0 & 1
  Opens: number;
  Sends: number;
};

export type EmailSendDetailEntity = {
  UserId: number;
  Status: EmailSendDetailStatus;
  SendDate?: string | null;
  OpenDate?: string | null;
  ClickDate?: string | null;
  FailDate: string | null;
  EmailId: number;
  EmailTitle: string;
  SendSessionId: number;
  AccountId: number;
  UserDepartment: string;
  UserEmail: string;
  UserFirstName: string;
  UserFullName: string;
  UserLastName: string;
  UserTitle: string;
  id: string;
};

export type EmailSendDetailEntitySansId = Omit<EmailSendDetailEntity, "id">;

export type EmailStatuses = "Scheduled" | "InProgress" | "Canceled" | "None" | "Completed";
export type EmailOrigins = "Direct" | "Flow";

export type EmailSendSession = {
  ClickRate: number; // between 0 & 1
  OpenRate: number; // between 0 & 1
  Origin: EmailOrigins;
  /**
   * The date/time of the send session, e.g."2023-08-18T12:59:45.099",
   */
  SendSession: string;
  SendSessionId: number;
  Status: EmailStatuses;
  Clicks?: number;
  Opens?: number;
  Sends?: number;
  TimeZone: string | null;
};
