enum ReducerNamespaceTypes {
  Accounts = "ACCOUNTS",
  Assessments = "ASSESSMENTS",
  People = "PEOPLE",
  Reporting = "REPORTING",
  Collections = "COLLECTIONS",
  Library = "LIBRARY",
  Licensing = "LICENSING",
  Survey = "SURVEY",
  Flows = "FLOWS",
  Videos = "VIDEOS",
  Pdfs = "PDFS",
  SystemNotifications = "SYSTEM_NOTIFICATIONS",
  Content = "CONTENT",
  Communications = "COMMUNICATIONS",
}

export default ReducerNamespaceTypes;
