import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { bindAction, IWizardStep } from "interfaces";

import { RootState } from "../../../Application/globaltypes/redux";
import { reset, selectEngagementPeopleValue } from "../state/slices/videoPerformanceSlice";
import { ReportingExport } from "../../../../components/reportingExport/ReportingExport";
import ReportingFilter from "../../../../components/reportingFilter/ReportingFilter";

import VideoPerformanceBody from "./VideoPerformanceBody";
import {
  selectExporting,
  reset as resetExport,
  selectIsExportActive,
  selectExportInfo,
  selectHasDataToExport,
} from "features/Reporting/state/export/exportSlice";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

import "./videoPerformance.scss";
import "../../Common/utils/performanceSCSSUtils.scss";
import { defaultDateFilter, isBsi } from "features/Library/Common/utils/performanceUtils";
import { useAccountFilterShouldShow } from "features/Reporting/Content/queries/useAccountFilterShouldShow";

export type Props = PropsFromRedux &
  IWizardStep & {
    videoId: number;
    videoTitle: string;
  };

export type VideoStatusColors = "icon-send-complete" | "icon-send-progress" | "icon-send-canceled";

export const VideoPerformance: React.FC<Props> = ({
  videoId,
  videoTitle,
  resetState,
  exportStatus,
  resetExports,
  exportInfo,
  acceptHandlers,
  isExportEnabled,
  accountId,
  accountType,
  hasDataToExport,
}) => {
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);
  const [dateFilter, setDateFilter] = useState(
    defaultDateFilter({
      includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
      accountId,
      includeDistinct: true,
    }),
  );
  const reportEnabled = useFeatureFlag(FeatureFlags.VideoReport);

  useEffect(() => {
    setDateFilter(
      defaultDateFilter({
        includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
        accountId,
        includeDistinct: true,
      }),
    );
  }, [accountFilterShouldShow, accountId]);

  useEffect(() => {
    return () => {
      resetState();
      resetExports();
    };
  }, [resetState, resetExports]);

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="performanceRoot">
      <header className="performanceHeader">
        <h2 className="performanceTitle">Summary</h2>
        <div className="videoActions">
          <ReportingFilter
            currentFilter={{
              ...dateFilter,
            }}
            callback={setDateFilter}
            includeAccountsDropdown={accountFilterShouldShow && isBsi(accountId)}
            ignoreInCountAndHide={["type"]}
            includeDistinct
          />
          {isExportEnabled && (
            <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={!hasDataToExport} />
          )}
        </div>
      </header>
      <div className="performanceRoot">
        <VideoPerformanceBody
          includeAccounts={accountFilterShouldShow && isBsi(accountId) && dateFilter.accounts?.length > 0}
          videoId={videoId}
          videoTitle={videoTitle}
          videoFilter={dateFilter}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    peopleDetailsEntities: selectEngagementPeopleValue(state),
    exportInfo: selectExportInfo(state),
    exportStatus: selectExporting(state),
    isExportEnabled: selectIsExportActive(state),
    accountId: state.userProfile.accountId,
    accountType: state.userProfile.accountTypeId,
    hasDataToExport: selectHasDataToExport(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetState: bindAction(reset, dispatch),
    resetExports: bindAction(resetExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VideoPerformance);
