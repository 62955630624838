import cn from "classnames";
import { max, scaleLinear } from "d3";
import { FC, useEffect, useMemo, useState } from "react";
import { ConnectedProps, batch, connect } from "react-redux";

import { bindActionCreators } from "@reduxjs/toolkit";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import { setExportAction } from "features/Reporting/state/export/exportSlice";
import { reset, setIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { bindAction } from "interfaces";
import {
  ChartWrapper,
  HorizontalBarChart,
  LineChart,
  TickValueType,
  getDateFormatByCount,
} from "../../../../components/charts";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import {
  PerformanceFilter, completes,
  completesColor,
  createDateRange,
  dailyActivity, lineChartMargins,
  lineChartTitles,
  noBarData,
  noData,
  totalActivity,
  transitionTime,
  validLineData
} from "../../Common/utils/performanceUtils";
import {
  selectEngagementCompleted,
  selectEngagementCumulativeCompleted,
  selectEngagementDates,
  selectEngagementHorizontalData,
  selectEngagementHorizontalValue,
  selectEngagementLineData,
} from "../selectors/performanceSelectors";
import { doExport, fetchEngagementHorizontalData, fetchEngagementLineData } from "../state/thunks/pdfPerformanceThunk";
import PdfPerformancePeople from "./PdfPerformancePeople";
import { sharedAccountReportingHorizontalBarProps, sharedAccountReportingLineProps } from "features/Reporting/Content/shared";
import { ChartPeriod, useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";

export type PdfPerformanceBodyProps = {
  pdfId: number;
  pdfTitle: string;
  pdfFilter: PerformanceFilter;
  includeAccounts?: boolean;
  flowId?: number;
} & PropsFromRedux;

const lineChartLegendLabels = [completes];
const lineChartColorRange = [completesColor];

export const PdfPerformanceBody: FC<PdfPerformanceBodyProps> = ({
  pdfId,
  pdfTitle,
  flowId,
  includeAccounts,
  pdfFilter,
  engagementLineStatus,
  engagementDates,
  engagementCompleted,
  lineCumulativeCompleted,
  engagementHorizontalData,
  engagementHorizontalStatus,
  fetchData,
  setExportAction,
  doExport,
  setIsReportEnabled,
  resetIsReportEnabled,
}) => {
  const [newLineChartState, setNewLineChartState] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.PdfReport);

  const dateRange = useMemo(() => createDateRange(pdfFilter.dateFrom, pdfFilter.dateTo), [pdfFilter.dateFrom, pdfFilter.dateTo]);

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );

  useEffect(() => {
    if (reportEnabled) {
      fetchData(pdfId, pdfFilter, chartPeriod, flowId);
    }
  }, [fetchData, pdfId, flowId, pdfFilter, reportEnabled, chartPeriod]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: doExport,
      args: [pdfId, { ...pdfFilter, flowId }, pdfTitle],
      isExportEnabled: true,
    });
  }, [doExport, flowId, pdfFilter, pdfId, pdfTitle, setExportAction]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const newVersionLineData = useMemo<number[]>(() => {
    if (newLineChartState === dailyActivity) {
      return engagementCompleted;
    }
    return lineCumulativeCompleted;
  }, [engagementCompleted, lineCumulativeCompleted, newLineChartState]);

  const lineInfo = useMemo(() => {
    return {
      xData: [engagementDates],
      yData: [newVersionLineData],
      yFormatterFunc: ",d",
      yScaleRatio: scaleLinear,
      colorRange: lineChartColorRange,
    };
  }, [engagementDates, newVersionLineData]);

  const renderLineChart = () => (
    <ChartWrapper
      showLegend
      titles={lineChartTitles}
      legendLabels={lineChartLegendLabels}
      onChange={setNewLineChartState}
      colorRange={lineChartColorRange}
    >
      <RequestStatusRenderer state={engagementLineStatus}>
        {validLineData(lineInfo.yData) ? (
          <LineChart
            margins={lineChartMargins}
            xScaleRatio={timeScale}
            xFormatterFunc={getDateFormatByCount(engagementDates.length)}
            transitionDuration={transitionTime}
            yTickValueType={TickValueType.IntegersOnly}
            {...lineInfo}
          />
        ) : (
          noData(pdfFilter)
        )}
      </RequestStatusRenderer>
    </ChartWrapper>
  );

  const horizontalBarData = useMemo(
    () => [
      {
        id: "completed-bar",
        category: completes,
        value: engagementHorizontalData.CompletedCount,
        fillColor: completesColor,
      },
    ],
    [engagementHorizontalData.CompletedCount],
  );

  const horizontalBarDomain = useMemo(() => {
    if (reportEnabled) return [0, max([engagementHorizontalData.CompletedCount]) || 1];
    return [0, max([engagementHorizontalData.CompletedCount]) || 1];
  }, [engagementHorizontalData, reportEnabled]);

  const renderHorizontalBarChart = () => (
    <ChartWrapper titles={["Engagement"]}>
      <RequestStatusRenderer state={engagementHorizontalStatus}>
        {!noBarData(engagementHorizontalData.CompletedCount) ? (
          <HorizontalBarChart
            {...sharedAccountReportingHorizontalBarProps}
            data={horizontalBarData}
            domain={horizontalBarDomain}
          />
        ) : (
          noData(pdfFilter)
        )}
      </RequestStatusRenderer>
    </ChartWrapper>
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="performanceBody">
      <div className="performanceChartsSection">
        <div className="graphs">
          <div className={cn("lineChartContainer", "user_chart_container")} ref={measureRef}>{renderLineChart()}</div>
          <div className={cn("horizontalChartContainer", "user_chart_container")}>{renderHorizontalBarChart()}</div>
        </div>
        <div className="performanceTableSection">
          <PdfPerformancePeople includeAccounts={includeAccounts} pdfId={pdfId} flowId={flowId} filter={pdfFilter} />
        </div>
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  engagementLineStatus: selectEngagementLineData(state),
  engagementDates: selectEngagementDates(state),
  engagementCompleted: selectEngagementCompleted(state),
  lineCumulativeCompleted: selectEngagementCumulativeCompleted(state),
  engagementHorizontalData: selectEngagementHorizontalValue(state),
  engagementHorizontalStatus: selectEngagementHorizontalData(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchData: (pdfId: number, pdfFilter: PerformanceFilter, aggregation: ChartPeriod, flowId?: number) => {
    batch(() => {
      dispatch(fetchEngagementHorizontalData(pdfId, { ...pdfFilter, flowId }));
      dispatch(fetchEngagementLineData(pdfId, { ...pdfFilter, aggregation, flowId }));
    });
  },
  doExport: bindActionCreators(doExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PdfPerformanceBody);
