import { SankeyWrapper } from "components/charts";
import { Node } from "components/charts/types/Sankey";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { RootState } from "features/Application/globaltypes/redux";
import { PerformanceFilter, sankeyWrapperMargins } from "features/Library/Common/utils/performanceUtils";
import { ConnectedProps, connect } from "react-redux";
import {
  selectEngagementError,
  selectEngagementStatus,
  selectEngagementValue,
} from "../../state/slices/flowPerformanceSlice";
import FlowPerformancePeopleComponent from "../FlowPerformancePeople";

export interface Props extends PropsFromRedux {
  dateFilter: PerformanceFilter;
  onClick: (node: Node) => void;
  includeAccounts?: boolean;
}

export const FlowEngagement = ({
  dateFilter,
  engagementStatus,
  engagementData,
  engagementError,
  onClick,
  includeAccounts,
}: Props) => {
  return (
    <>
      <SankeyWrapper
        title="Engagement"
        margins={sankeyWrapperMargins}
        data={engagementData}
        loading={engagementStatus === RStatus.Pending}
        error={engagementError}
        onClick={onClick}
      />
      <div className="performanceTableSection">
        <FlowPerformancePeopleComponent filter={dateFilter} includeAccounts={includeAccounts} />
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  engagementStatus: selectEngagementStatus(state),
  engagementData: selectEngagementValue(state),
  engagementError: selectEngagementError(state),
});

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowEngagement);
