import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { QueryObserverSuccessResult } from "@tanstack/react-query";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import {
  chartColorScale,
  chartLegendLabels,
  FormattedLineData,
  noBarData,
  noData,
  validLineData,
  dailyActivity,
  lineChartTitles,
  totalActivity,
  PerformanceWithAccountFilter,
  createDateRange,
  lineChartMargins,
} from "features/Library/Common/utils/performanceUtils";
import {
  useFlowEngagementQuery,
  useFlowLineChartQuery,
} from "features/Reporting/Content/queries/useFlowLineChartQuery";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import { setExportAction } from "features/Reporting/state/export/exportSlice";
import { AssetActivityCounts, FlowCards } from "features/Reporting/types/content";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { bindAction } from "interfaces";
import CardReporting from "../../../../components/cards/CardReporting/CardReporting";
import { ChartWrapper, getDateFormatByCount, HorizontalBarChart, LineChart } from "../../../../components/charts";
import { Node } from "../../../../components/charts/types/Sankey";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "../../../../featureFlags";
import { RootState } from "../../../Application/globaltypes/redux";
import { RegisterBreadcrumbCallback } from "../../Common/Hooks/usePerformanceBreadcrumbs";
import { selectFlowGoalPerformanceInfo } from "../state/slices/flowGoalSlice";
import {
  selectPeopleDetailsStatus,
  selectPeopleDetailsValue,
  selectUniqueEngagementNodes,
} from "../state/slices/flowPerformanceSlice";
import * as flowPerformanceActions from "../state/thunks/flowPerformanceThunk";
import FlowPerformanceDrilldown from "./Drilldown/FlowPerformanceDrilldown";
import { AssetInfo } from "./FlowPerformance";
import FlowPerformanceTabs from "./Tabs/FlowPerformanceTabs";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";

import "../../Common/utils/performanceSCSSUtils.scss";
import "./flowPerformance.scss";

export interface Props extends PropsFromRedux {
  flowId: number;
  flowTitle: string;
  registerBreadcrumb: RegisterBreadcrumbCallback;
  dateFilter: PerformanceWithAccountFilter | null;
  setSelectedAssetFlow?: (content: AssetInfo | undefined) => void;
  includeAccounts?: boolean;
}

const lineData = (
  chartState: string,
  lineChartQuery: QueryObserverSuccessResult<FormattedLineData<AssetActivityCounts>, unknown>,
) => {
  if (chartState === dailyActivity) {
    return {
      xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date],
      yData: [lineChartQuery.data?.InProgress, lineChartQuery.data?.Completed],
    };
  }
  return {
    xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date],
    yData: [lineChartQuery.data?.InProgressTotal, lineChartQuery.data?.CompletedTotal],
  };
};

const barDomain = (
  engagementQuery: QueryObserverSuccessResult<
    FlowCards & {
      barData: DataPoint[];
    },
    unknown
  >,
) => {
  if (engagementQuery.isSuccess) {
    let maxValue = Math.max(...engagementQuery.data.barData.map((d) => d.value));
    return [0, Math.max(maxValue, 1)];
  }
};

const validDates = (dateFilter: PerformanceWithAccountFilter | null) => {
  return dateFilter !== null && dateFilter !== undefined ? dateFilter : null;
};

export const FlowPerformanceBody: FC<Props> = ({
  flowId,
  flowTitle,
  registerBreadcrumb,
  dateFilter,
  uniqueSankeyNodes,
  goalInfo,
  actions,
  setExportAction,
  setSelectedAssetFlow,
  includeAccounts,
}) => {
  const dateRange = useMemo(() => {
    if (dateFilter === null) return null;
    return createDateRange(dateFilter.dateFrom, dateFilter.dateTo);
  }, [dateFilter]);

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange ?? [],
    lineChartMargins.left + lineChartMargins.right,
  );

  const [selectedAsset, setSelectedAsset] = useState<AssetInfo>();
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);
  const [chartState, setChartState] = useState(totalActivity);
  const reportEnabled = !!useFeatureFlag(FeatureFlags.FlowReport);
  const lineChartQuery = useFlowLineChartQuery(dateFilter, chartPeriod, flowId);
  const engagementQuery = useFlowEngagementQuery(dateFilter, flowId);
  const shouldSendRequests = reportEnabled && dateFilter !== null;

  useEffect(() => {
    if (setSelectedAssetFlow !== undefined) setSelectedAssetFlow(selectedAsset);
  }, [setSelectedAssetFlow, selectedAsset]);

  useEffect(() => {
    // Should only fetch when endpoints are available (and date range is required)
    if (shouldSendRequests) {
      actions.fetchEngagementData(flowId, dateFilter);
      actions.fetchPeopleDetails(flowId, dateFilter);
      // No dates to filter, so set default values so cards / sankey appear
      // in their "empty" states
    } else {
      actions.setDefaultValues();
    }
  }, [actions, flowId, dateFilter, shouldSendRequests]);

  useEffect(() => {
    if (shouldSendRequests && goalInfo) {
      const { includeMyData, accounts, isDistinct, type, ...restOfFilter } = dateFilter;
      actions.fetchGoalsLineChart(flowId, restOfFilter, goalInfo);
      actions.fetchGoalsTotals(flowId, restOfFilter, goalInfo);
      actions.fetchGoalsCards(flowId, restOfFilter, goalInfo);
    }
  }, [actions, dateFilter, flowId, goalInfo, shouldSendRequests]);

  const getSankeyNodeIndex = useCallback(
    (currentNodeId: number): number => uniqueSankeyNodes.findIndex((value) => value.id === currentNodeId),
    [uniqueSankeyNodes],
  );

  const handleNodeTitleClick = useCallback(
    (node: Node) => {
      setSelectedAsset({ id: node.id!, type: node.type, name: node.name });
      setCurrentAssetIndex(getSankeyNodeIndex(node.id!));
    },
    [getSankeyNodeIndex],
  );

  const handleReset = useCallback(() => {
    setSelectedAsset(undefined);
  }, []);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    if (!selectedAsset) {
      setExportAction({
        method: actions.handleFlowExport,
        args: [flowId, dateFilter, flowTitle],
        isExportEnabled: true,
      });
    }
  }, [actions.handleFlowExport, dateFilter, flowId, setExportAction, selectedAsset, flowTitle]);

  const handleNextAsset = useCallback(() => {
    const currentIdx = getSankeyNodeIndex(selectedAsset!.id);
    const wrappedIndex = (currentIdx + 1) % uniqueSankeyNodes.length;
    setSelectedAsset(uniqueSankeyNodes[wrappedIndex]);
    setCurrentAssetIndex(wrappedIndex);
  }, [getSankeyNodeIndex, selectedAsset, uniqueSankeyNodes]);

  const handlePrevAsset = useCallback(() => {
    const currentIdx = getSankeyNodeIndex(selectedAsset!.id);
    const wrappedIndex = (currentIdx - 1 + uniqueSankeyNodes.length) % uniqueSankeyNodes.length;

    setSelectedAsset(uniqueSankeyNodes[wrappedIndex]);
    setCurrentAssetIndex(wrappedIndex);
  }, [getSankeyNodeIndex, selectedAsset, uniqueSankeyNodes]);

  const renderRoot = useMemo(
    () => (
      <div className="flowBody" data-testid="flow performance body root">
        <div className="chartsGraphs">
          <div className="lineChartContainer" ref={measureRef}>
            <ChartWrapper
              titles={lineChartTitles}
              showLegend
              legendLabels={chartLegendLabels}
              colorRange={chartColorScale}
              onChange={setChartState}
            >
              <RequestStatusRenderer state={lineChartQuery.status}>
                {lineChartQuery.isSuccess &&
                validLineData([
                  lineChartQuery.data.Completed,
                  lineChartQuery.data.CompletedTotal,
                  lineChartQuery.data.InProgress,
                  lineChartQuery.data.InProgressTotal,
                ]) ? (
                  <LineChart
                    {...sharedAccountReportingLineProps}
                    xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                    colorRange={chartColorScale}
                    {...lineData(chartState, lineChartQuery)}
                  />
                ) : (
                  noData(validDates(dateFilter))
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
          <div className="funnelChartContainer">
            <ChartWrapper titles={["Engagement"]}>
              <RequestStatusRenderer state={engagementQuery.status}>
                {engagementQuery.isSuccess && !noBarData(...engagementQuery.data.barData.map((d) => d.value)) ? (
                  <HorizontalBarChart
                    {...sharedAccountReportingHorizontalBarProps}
                    domain={barDomain(engagementQuery)}
                    data={engagementQuery.data.barData}
                  />
                ) : (
                  noData(validDates(dateFilter))
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </div>
        <div className="cardSection">
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess && <CardReporting items={engagementQuery.data.cardDataIndividual} />}
          </RequestStatusRenderer>
        </div>
        <FlowPerformanceTabs
          includeAccounts={includeAccounts}
          onClick={handleNodeTitleClick}
          dateFilter={dateFilter!}
        />
      </div>
    ),
    [lineChartQuery, chartState, dateFilter, engagementQuery, handleNodeTitleClick, measureRef, includeAccounts],
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return selectedAsset ? (
    <FlowPerformanceDrilldown
      flowId={flowId}
      assetId={selectedAsset.id}
      assetType={selectedAsset.type}
      assetName={selectedAsset.name}
      dateFilter={dateFilter!}
      onReset={handleReset}
      registerBreadcrumb={registerBreadcrumb}
      onNextAsset={handleNextAsset}
      onPrevAsset={handlePrevAsset}
      currentAssetIndex={currentAssetIndex}
      totalAssetsCount={uniqueSankeyNodes.length}
    />
  ) : (
    renderRoot
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    peopleDetailsStatus: selectPeopleDetailsStatus(state),
    peopleDetailsData: selectPeopleDetailsValue(state),
    uniqueSankeyNodes: selectUniqueEngagementNodes(state),
    goalInfo: selectFlowGoalPerformanceInfo(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(flowPerformanceActions, dispatch),
    setExportAction: bindAction(setExportAction, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowPerformanceBody);
