import { SortingDirection } from "enums";
import { AppThunk } from "features/Application/globaltypes/redux";
import DataService from "features/Application/services/dataServices/typedDataService";
import { fetchBegin, fetchSuccess } from "../TemplateOverviewSlice";
import { formatFilters } from "utils/filterMapUtils";
import { FilterType } from "utils/filterUtils";
import { escapeTerm } from "utils/searchUtils";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import { UpdateParams } from "interfaces";

export const fetchTemplates =
  (skip: number, top: number, sortBy: string, sortOrder: SortingDirection, onlyOwn: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());
      const filtersState = getState().people.groupTemplate.filters;
      const cb = onlyOwn ? DataService.groupTemplates.getTemplates : DataService.groupTemplates.getInheritedGroups;
      const result = await cb({
        top,
        skip,
        sortBy,
        sortOrder,
        term: escapeTerm(filtersState.search),
        ...formatFilters(filtersState.appliedFilter, {
          dateCreated: FilterType.DateRange,
        }),
      });

      const accId = getState().userProfile.accountId;

      dispatch(
        fetchSuccess({
          items: result.items.map((x) => ({ ...x, isOwn: x.publisherId === accId })),
          totalCount: result.count,
        }),
      );
    } catch {}
  };

export const approveTemplate =
  (templateId: number, owner: string, onSuccess: () => void): AppThunk =>
  async (dispatch) => {
    const updateParams: UpdateParams = {
      id: "ApprovingTemplateOperation",
      title: `Approving Template`,
      onCompleted: onSuccess,
      getMessageIds: async () => {
        const { data } = await DataService.groupTemplates.approve(templateId);
        return [data];
      },
      successTransientMessage: `You have successfully approved ${owner ? owner + "'s" : ""} request.`,
      successTitle: "Request Approved!",
      failureTransientMessage: `Template approving failed!`,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };

export const declineTemplate =
  (templateId: number, owner: string, onSuccess: () => void): AppThunk =>
  async (dispatch) => {
    const updateParams: UpdateParams = {
      id: "DecliningTemplateOperation",
      title: `Declining Template`,
      onCompleted: onSuccess,
      getMessageIds: async () => {
        const { data } = await DataService.groupTemplates.decline(templateId);
        return [data];
      },
      successTransientMessage: `You have successfully declined ${owner}'s request.`,
      successTitle: "Request Declined!",
      failureTransientMessage: `Template declining failed!`,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };

export const deleteTemplate =
  (templateId: number, onSuccess: () => void): AppThunk =>
  async (dispatch) => {
    const updateParams: UpdateParams = {
      id: "DeletingTemplateOperation",
      title: `Deleting Template`,
      onCompleted: onSuccess,
      getMessageIds: async () => {
        const { data } = await DataService.groupTemplates.deleteTemplate(templateId);
        return [data];
      },
      successTransientMessage: `Template has been deleted successfully.`,
      successTitle: "Template deleted!",
      failureTransientMessage: `Template deleting failed!`,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };
