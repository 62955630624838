import { FilterFormBuilder, FilterViewType } from "components/filterForms/FilterFormBuilder/FilterFormBuilder";
import { FilterFormPropsBase } from "components/filterForms/types/props";
import { FC } from "react";
import { TemplatesFiltersEnum } from "../types";

export interface FilterFormProps extends FilterFormPropsBase<TemplatesFiltersEnum> {}

export const TemplatesFilterForm: FC<FilterFormProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={[{ label: "Date Created", type: FilterViewType.DateRange, propertyName: TemplatesFiltersEnum.DateCreated }]}
  />
);
