import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { EnrollmentRule, isDefaultRuleSet } from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/utils";
import { isEmpty } from "lodash";
import enrollmentRulesUtils from "utils/enrollmentRulesUtils";
import { hasGroupTemplateSettingsAccess } from "../../utils";
import { setEnrollmentRules, setIsLoading, setTemplateUsersOnly } from "../GroupTemplateSlice";
import DataService from "features/Application/services/dataServices/typedDataService";

const saveRules = async (groupId: number, rules: EnrollmentRule[], templateUsersOnly: boolean | null) => {
  if (!isEmpty(rules) && !isDefaultRuleSet(rules)) {
    const enrollmentRulesInServerFormat = enrollmentRulesUtils.mapRulesToSeverFormat(rules);

    await DataService.groupTemplates.updateTemplateEnrollmentRules(
      groupId,
      enrollmentRulesInServerFormat,
      templateUsersOnly,
    );
  }
};

export const saveRulesAction =
  (groupId: number, rules: EnrollmentRule[], templateUsersOnly: boolean | null) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const hasSettingsAccess = hasGroupTemplateSettingsAccess(getState().userProfile.accountTypeId);
      const templateUsersOnlySetting = templateUsersOnly === null && hasSettingsAccess ? false : templateUsersOnly;
      await saveRules(groupId, rules, templateUsersOnlySetting);
      dispatch(setEnrollmentRules(rules));
      dispatch(setTemplateUsersOnly(templateUsersOnlySetting));
    } catch (error) {}
    dispatch(setIsLoading(false));
  };
