import { bindActionCreators } from "@reduxjs/toolkit";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { isUserDeleted, PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { selectPeopleDetails, selectPeopleDetailsValue } from "../state/slices/surveyPerformanceSlice";
import * as surveyPerformanceActions from "../state/thunks/surveyPerformanceThunk";
import { SurveyPeopleDetails } from "../types/state";
import { bindAction } from "interfaces";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { Link } from "react-router-dom";
import { RouteNames } from "enums";
import moboConfigurator from "moboConfigurator";

import styles from "./surveyPerformancePeople.module.scss";
import { ColumnOption } from "interfaces/columnOptions";

export type SurveyPerformancePeopleProps = {
  surveyId: number;
  flowId?: number;
  filter: PerformanceFilter;
  includeAccounts?: boolean;
} & PropsFromRedux;

const getColumnOptions = (includeAccounts: boolean = false) => {
  const baseColumnOptions: ColumnOption<SurveyPeopleDetails>[] = [
    {
      name: "NAME",
      width: 5,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => {
        const useLink = !isUserDeleted(entity) && !includeAccounts;
        const userName = (
          <Tooltip
            target={
              <TextTruncate>
                {entity.FirstName} {entity.LastName}
              </TextTruncate>
            }
            content={`${entity.FirstName} ${entity.LastName}`}
          />
        );
        const secondaryInfo = [entity.UserTitle, entity.Department].filter((x) => x).join(", ");
        return (
          <div>
            {useLink ? (
              <Link
                className={styles.linkButton}
                to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.peopleUsers}/${entity.UserId}`)}
              >
                {userName}
              </Link>
            ) : (
              userName
            )}
            <Tooltip
              content={secondaryInfo}
              target={<TextTruncate className="workTitle">{secondaryInfo}</TextTruncate>}
            />
          </div>
        );
      },
    },
    {
      name: "EMAIL",
      isSortable: false,
      width: 5,
      render: (entity: SurveyPeopleDetails) => (
        <div>
          <Tooltip target={<TextTruncate>{entity.Email}</TextTruncate>} content={entity.Email} />
          <div>&nbsp;</div>
        </div>
      ),
    },
    {
      name: "DATE STARTED",
      width: 5,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => {
        const date = dateTimeUtils.formatDate(entity.StartDate);

        return (
          <div>
            <div>{date}</div>
            <div>&nbsp;</div>
          </div>
        );
      },
    },
    {
      name: "DATE COMPLETED",
      width: 5,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => {
        const date = dateTimeUtils.formatDate(entity.CompletionDate);

        return (
          <div>
            <div>{date}</div>
            <div>&nbsp;</div>
          </div>
        );
      },
    },
    {
      name: "QUESTIONS COMPLETED",
      width: 5,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => (
        <div>
          <div>{entity.QuestionsCompleted}</div>
          <div>&nbsp;</div>
        </div>
      ),
    },
  ];

  return baseColumnOptions;
};

export const SurveyPerformancePeopleComponent: FC<SurveyPerformancePeopleProps> = ({
  actions,
  surveyId,
  flowId,
  filter,
  peopleDetails,
  peopleDetailsStatus,
  setHasExportData,
  includeAccounts,
}) => {
  const columnOptions = getColumnOptions(includeAccounts);
  useEffect(() => {
    actions.fetchPeopleDetails(surveyId, { ...filter, flowId });
  }, [surveyId, actions, filter, flowId]);

  useEffect(() => {
    setHasExportData(peopleDetailsStatus.status === RStatus.Got && !!peopleDetails.length);
  }, [setHasExportData, peopleDetailsStatus.status, peopleDetails]);

  return (
    <RequestStatusRenderer state={peopleDetailsStatus}>
      <GenericPerformanceList rows={peopleDetails} columns={columnOptions} filter={filter} title="People" />
    </RequestStatusRenderer>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    peopleDetails: selectPeopleDetailsValue(state),
    peopleDetailsStatus: selectPeopleDetails(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    actions: bindActionCreators(surveyPerformanceActions, dispatch),
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyPerformancePeopleComponent);
