import { FC, useCallback, useEffect, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import PdfPerformanceBody from "./PdfPerformanceBody";
import { RootState } from "../../../Application/globaltypes/redux";
import { reset } from "../state/slices/pdfPerformanceSlice";
import { bindAction } from "../../../../interfaces/redux";
import ReportingFilter from "../../../../components/reportingFilter/ReportingFilter";
import { ReportingExport } from "../../../../components/reportingExport/ReportingExport";
import { defaultDateFilter, isBsi } from "features/Library/Common/utils/performanceUtils";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import {
  selectExporting,
  reset as resetExport,
  selectExportInfo,
  selectIsExportActive,
  selectHasDataToExport,
} from "features/Reporting/state/export/exportSlice";
import { IWizardStep } from "interfaces";

import "../../Common/utils/performanceSCSSUtils.scss";
import { useAccountFilterShouldShow } from "features/Reporting/Content/queries/useAccountFilterShouldShow";

export type PdfPerformanceProps = PropsFromRedux &
  IWizardStep & {
    pdfId: number;
    pdfTitle: string;
  };

export const PdfPerformance: FC<PdfPerformanceProps> = ({
  pdfId,
  pdfTitle,
  accountId,
  accountType,
  resetState,
  exportStatus,
  isExportEnabled,
  exportInfo,
  acceptHandlers,
  resetExports,
  hasDataToExport,
}) => {
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);
  const [dateFilter, setDateFilter] = useState(
    defaultDateFilter({
      includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
      accountId,
      includeDistinct: true,
    }),
  );
  const reportEnabled = useFeatureFlag(FeatureFlags.PdfReport);

  useEffect(() => {
    setDateFilter(
      defaultDateFilter({
        includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
        accountId,
        includeDistinct: true,
      }),
    );
  }, [accountFilterShouldShow, accountId]);

  useEffect(
    () => () => {
      resetState();
      resetExports();
    },
    [resetState, resetExports],
  );

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo?.args);
  }, [exportInfo]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={"performanceRoot"}>
      <header className="performanceHeader">
        <h2 className="performanceTitle">Summary</h2>
        <div className="performanceActions">
          <ReportingFilter
            currentFilter={dateFilter}
            callback={setDateFilter}
            includeAccountsDropdown={accountFilterShouldShow && isBsi(accountId)}
            ignoreInCountAndHide={["type"]}
            includeDistinct
          />
          {isExportEnabled && (
            <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={!hasDataToExport} />
          )}
        </div>
      </header>
      <div className={"performanceRoot"}>
        <PdfPerformanceBody
          includeAccounts={accountFilterShouldShow && isBsi(accountId) && dateFilter.accounts?.length > 0}
          pdfId={pdfId}
          pdfTitle={pdfTitle}
          pdfFilter={dateFilter}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    exportStatus: selectExporting(state),
    exportInfo: selectExportInfo(state),
    isExportEnabled: selectIsExportActive(state),
    hasDataToExport: selectHasDataToExport(state),
    accountId: state.userProfile.accountId,
    accountType: state.userProfile.accountTypeId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetState: bindAction(reset, dispatch),
  resetExports: bindAction(resetExport, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PdfPerformance);
