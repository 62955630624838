import classnames from "classnames/bind";
import cn from "classnames";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import ReportingFilter from "components/reportingFilter/ReportingFilter";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { usePerformanceBreadcrumbs } from "features/Library/Common/Hooks/usePerformanceBreadcrumbs";
import { PerformanceWithAccountFilter, defaultDateFilter, isBsi } from "features/Library/Common/utils/performanceUtils";
import { EntityType } from "features/Library/Flows/Designer/types";
import Segments from "../../../components/navigation/segments/Segments";
import { DetailsHeader } from "../../../components/sectionHeader";
import { useAccountFilterShouldShow } from "./queries/useAccountFilterShouldShow";
import AssessmentReport from "./sections/Assessments/AssessmentReport";
import { ContentReportDrilldown } from "./sections/Drilldown/ContentReportDrilldown";
import EmailReport from "./sections/Emails/EmailReport";
import FlowsReport from "./sections/Flows/FlowsReport";
import PdfReport from "./sections/PDFs/PdfReport";
import SurveyReport from "./sections/Surveys/SurveyReport";
import VideoReport from "./sections/Videos/VideoReport";
import { ReportingExport } from "components/reportingExport/ReportingExport";
import {
  selectExporting,
  reset as resetExports,
  selectHasDataToExport,
  selectExportInfo,
  selectIsExportActive,
} from "../state/export/exportSlice";
import { bindAction } from "interfaces";
import { selectIsReportEnabled } from "../state/toolbar/toolbarSlice";

import styles from "./contentOverviewReports.module.scss";
import EventsReport from "./sections/Events/EventsReport";

const cx = classnames.bind(styles);

export type ContentReportSelection = {
  id: number;
  name: string;
  type: EntityType;
};

const validAccountPaths = ["assessments", "events"];
const validAccountDropdownPaths = ["videos", "surveys", "emails", "pdfs", "flows"];
const validDistinctPaths = ["emails", "pdfs", "flows", "videos"];

export type Props = PropsFromRedux;
const pages = [
  { to: "flows", label: "Flows" },
  { to: "videos", label: "Videos" },
  { to: "surveys", label: "Surveys" },
  { to: "assessments", label: "Assessments" },
  { to: "pdfs", label: "PDFs" },
  { to: "emails", label: "Emails" },
  { to: "events", label: "Events" },
];

export const ContentOverviewReports: FC<Props> = ({
  accountId,
  accountType,
  resetExports,
  exportStatus,
  hasDataToExport,
  exportInfo,
  isExportEnabled,
  selectIsReportEnabled,
}) => {
  const [selectedContent, setSelectedContent] = useState<ContentReportSelection>();
  const location = useLocation();
  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay, resetBreadcrumbs } = usePerformanceBreadcrumbs();
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);

  const includeAccounts = validAccountPaths.some((path) => location.pathname.endsWith(path)) && accountFilterShouldShow;

  const includeAccountsDropdown =
    validAccountDropdownPaths.some((path) => location.pathname.endsWith(path)) && accountFilterShouldShow;
  const includeDistinctDropdown = validDistinctPaths.some((path) => location.pathname.endsWith(path));

  const defaultFilter = useMemo(
    () =>
      defaultDateFilter({
        includeAccounts,
        includeAccountsDropdown: includeAccountsDropdown && isBsi(accountId),
        accountId,
        includeDistinct: includeDistinctDropdown,
      }),
    [includeAccounts, includeAccountsDropdown, accountId, includeDistinctDropdown],
  );

  const [dateFilter, setDateFilter] = useState<PerformanceWithAccountFilter>(defaultFilter);

  // When changing tabs, remove the drilldown
  const onBreadcrumbReset = useCallback(() => {
    setSelectedContent(undefined);
    resetBreadcrumbs();
  }, [resetBreadcrumbs]);

  useEffect(() => {
    onBreadcrumbReset();
  }, [location, onBreadcrumbReset]);

  // Reset filter when changing tabs
  useEffect(() => {
    setDateFilter(defaultFilter);
  }, [location, defaultFilter]);

  useEffect(() => {
    return () => {
      resetExports();
    };
  }, [resetExports]);

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  const toolbar = useMemo(
    () =>
      selectIsReportEnabled ? (
        <div className={styles.right}>
          <ReportingFilter
            currentFilter={dateFilter}
            callback={setDateFilter}
            includeAccounts={includeAccounts && !hasBreadcrumbsToDisplay}
            includeAccountsDropdown={includeAccountsDropdown && isBsi(accountId)}
            ignoreInCountAndHide={["type", hasBreadcrumbsToDisplay ? "showCustomers" : ""]}
            includeDistinct={includeDistinctDropdown}
          />
          {isExportEnabled && (
            <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={!hasDataToExport} />
          )}
        </div>
      ) : (
        <div></div>
      ),
    [
      accountId,
      dateFilter,
      exportStatus,
      handleExport,
      hasBreadcrumbsToDisplay,
      hasDataToExport,
      includeAccounts,
      includeAccountsDropdown,
      includeDistinctDropdown,
      isExportEnabled,
      selectIsReportEnabled,
    ],
  );
  return (
    <div className={cx("nested-content", styles.scrolling)}>
      <DetailsHeader title="Content" />
      <Segments to={"/reporting/content"}>
        {pages.map((page, i) => (
          <Segments.Segment key={i} {...page} />
        ))}
      </Segments>
      <div
        className={cn(styles.body, {
          [styles.reportUnavailable]: !selectIsReportEnabled,
          [styles.drilldownReportUnavailable]: !selectIsReportEnabled,
        })}
      >
        {selectIsReportEnabled && (
          <div className={styles.header}>
            {hasBreadcrumbsToDisplay ? <div>{domElements}</div> : <h2>Summary</h2>}
            {toolbar}
          </div>
        )}
        {!selectIsReportEnabled && selectedContent && (
          <div className={styles.header}>{hasBreadcrumbsToDisplay ? <div>{domElements}</div> : <h2>Summary</h2>}</div>
        )}
        {!selectedContent ? (
          <Routes>
            <Route
              path="flows"
              element={
                <FlowsReport
                  filter={dateFilter}
                  setSelectedContent={setSelectedContent}
                  includeAccounts={(dateFilter.accounts ?? []).length > 0}
                />
              }
            />
            <Route
              path="pdfs"
              element={
                <PdfReport
                  includeAccounts={(dateFilter.accounts ?? []).length > 0}
                  filter={dateFilter}
                  setSelectedContent={setSelectedContent}
                />
              }
            />
            <Route
              path="videos"
              element={<VideoReport filter={dateFilter} setSelectedContent={setSelectedContent} />}
            />
            <Route
              path="assessments"
              element={<AssessmentReport filter={dateFilter} setSelectedContent={setSelectedContent} />}
            />
            <Route
              path="surveys"
              element={<SurveyReport filter={dateFilter} setSelectedContent={setSelectedContent} />}
            />
            <Route
              path="emails"
              // Ignoring "isDistinct" prop for now
              // @ts-ignore
              element={<EmailReport filter={dateFilter} setSelectedContent={setSelectedContent} />}
            />
            <Route
              path="events"
              element={<EventsReport filter={dateFilter} setSelectedContent={setSelectedContent} />}
            />
            <Route path="*" element={<Navigate to="flows" replace />} />
          </Routes>
        ) : (
          <div className={cn("performanceRoot", { [styles.removePadding]: !selectIsReportEnabled })}>
            <ContentReportDrilldown
              {...selectedContent}
              filter={dateFilter}
              registerBreadcrumb={registerBreadcrumb}
              onReset={onBreadcrumbReset}
              includeAccounts={accountFilterShouldShow && isBsi(accountId) && (dateFilter.accounts ?? []).length > 0}
            />
          </div>
        )}
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  accountType: state.userProfile.accountTypeId,
  exportStatus: selectExporting(state),
  hasDataToExport: selectHasDataToExport(state),
  exportInfo: selectExportInfo(state),
  isExportEnabled: selectIsExportActive(state),
  selectIsReportEnabled: selectIsReportEnabled(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetExports: bindAction(resetExports, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ContentOverviewReports);
