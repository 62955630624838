import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NoResultsWithButton, SearchInput } from "components";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { TemplatesFilterForm } from "features/People/GroupTemplate/Overview/TemplatesFilterForm";
import { bindAction } from "interfaces";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { faBuildingUser } from "@fortawesome/pro-solid-svg-icons";
import { isEmpty } from "lodash";
import {
  resetAppliedFilter,
  setSearch,
  resetOverview,
  setAppliedFilter,
  setSelected,
  resetFilter,
} from "../state/slices/availableTemplatesSlice";
import { Columns, columnToParamMap, getColumnOptions } from "./columnOptions";
import { SortingDirection } from "enums";
import { fetchAvailableTemplates } from "../state/thunks/AccountTemplatesThunk";
import { DisabledRow } from "components/listView/listViewBase/rows/DisabledRow";
import { ListRowProps } from "components/listView/listViewBase/rows";
import { AvailableTemplate } from "../CreateAccount/types";

type Props = PropsFromRedux & {
  accountId: number;
};

const Row = (props: ListRowProps<AvailableTemplate>) => {
  return (
    <DisabledRow
      {...props}
      isDisabled={props.item.isAddedToAccount}
      tooltip="This template has already been added to this account."
    />
  );
};

export const Templates = (props: Props) => {
  const {
    filters,
    items,
    isLoading,
    fetchTemplates,
    applyFilter,
    resetFilter,
    onSearchChanged,
    itemsCount,
    setSelected,
    selected,
    accountId,
    reset,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => reset(), []);

  const reloadListItemsRef = useRef<(enableSorting: boolean) => void>();

  const handleSearchChanged = (search: string) => {
    onSearchChanged(search);
    reloadListItemsRef.current?.(isEmpty(search));
  };

  const handleFetch = useCallback(
    (
      skip: number = 0,
      top: number = 10,
      sortingColumnName: string = Columns.Added,
      sortOrder: SortingDirection = SortingDirection.Descending,
    ) => {
      const sortBy = columnToParamMap[sortingColumnName.toLowerCase() as Lowercase<Columns>];
      fetchTemplates({ skip, top, sortBy, sortOrder, targetAccountId: accountId });
    },
    [fetchTemplates, accountId],
  );

  const renderNoResults = () => {
    return (
      <NoResultsWithButton
        title="You don’t have any templates"
        description="Looks like you don’t have any templates."
        icon={<FontAwesomeIcon icon={faBuildingUser} className="no-results-icon" />}
        filtered={!isEmpty(filters.appliedFilter) || !isEmpty(filters.search)}
      />
    );
  };

  const columnOptions = useMemo(() => {
    return getColumnOptions();
  }, []);

  return (
    <GenericItemsView
      items={items}
      isLoading={isLoading}
      columnOptions={columnOptions}
      fetchData={handleFetch}
      dataCount={itemsCount}
      renderSearch={() => <SearchInput placeholder="Search for template" onChange={handleSearchChanged} />}
      // @ts-ignore
      getFilterForm={() => <TemplatesFilterForm />}
      applyFilter={applyFilter}
      resetFilter={resetFilter}
      setReloadListItems={(reloadListItems) => (reloadListItemsRef.current = reloadListItems)}
      appliedFilter={filters.appliedFilter}
      noResultsContent={renderNoResults()}
      selectedIds={selected}
      onSelectedItemChanged={setSelected}
      isSelectDisabled={(item) => item.isAddedToAccount}
      rowAs={Row}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { overview, filters } = state.accounts.availableTemplates;

  return {
    ...overview,
    filters,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    reset: () => {
      dispatch(resetOverview());
      dispatch(resetFilter());
    },
    fetchTemplates: bindAction(fetchAvailableTemplates, dispatch),
    onSearchChanged: bindAction(setSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetFilter: bindAction(resetAppliedFilter, dispatch),
    setSelected: bindAction(setSelected, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Templates);
