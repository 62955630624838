import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import EmailPerformanceBody from "./EmailPerformanceBody";
import { usePerformanceBreadcrumbs } from "../../../../Common/Hooks/usePerformanceBreadcrumbs";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { reset } from "../../state/slices/emailPerformanceSlice";

import ReportingFilter from "../../../../../../components/reportingFilter/ReportingFilter";
import { ReportingExport } from "../../../../../../components/reportingExport/ReportingExport";
import { defaultDateFilter, isBsi } from "features/Library/Common/utils/performanceUtils";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { bindAction } from "interfaces";
import {
  selectHasDataToExport,
  selectExportInfo,
  selectExporting,
  selectIsExportActive,
} from "features/Reporting/state/export/exportSlice";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

import "../../../../Common/utils/performanceSCSSUtils.scss";
import "./emailPerformance.scss";
import { useAccountFilterShouldShow } from "features/Reporting/Content/queries/useAccountFilterShouldShow";

export type Props = PropsFromRedux & {
  emailId: number;
  emailTitle: string;
};

export const EmailPerformance: FC<Props> = ({
  accountId,
  accountType,
  exportInfo,
  emailId,
  emailTitle,
  exportStatus,
  resetState,
  isExportEnabled,
  hasDataToExport,
}) => {
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);
  const includeAccountsDropdown = useMemo(
    () => accountFilterShouldShow && isBsi(accountId),
    [accountFilterShouldShow, accountId],
  );
  const [dateFilter, setDateFilter] = useState(
    defaultDateFilter({
      includeDistinct: true,
      includeAccountsDropdown,
      accountId,
    }),
  );

  // Ensure date filter updates after initial load if data is still fetching
  useEffect(() => {
    setDateFilter(defaultDateFilter({ includeDistinct: true, includeAccountsDropdown, accountId }));
  }, [accountId, includeAccountsDropdown]);

  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs("All Sends");

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="performanceRoot">
      <header className="emailPerformanceHeader">
        {hasBreadcrumbsToDisplay ? (
          <div className="breadCrumbs">{domElements}</div>
        ) : (
          <h2 className="emailTitle">Summary</h2>
        )}
        <div className="emailActions">
          <ReportingFilter
            currentFilter={dateFilter}
            callback={setDateFilter}
            includeAccounts={false}
            includeAccountsDropdown={includeAccountsDropdown}
            ignoreInCountAndHide={["type"]}
            includeDistinct
          />
          {isExportEnabled && (
            <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={!hasDataToExport} />
          )}
        </div>
      </header>
      <div className={"performanceRoot"}>
        <EmailPerformanceBody
          emailId={emailId}
          emailTitle={emailTitle}
          registerBreadcrumb={registerBreadcrumb}
          // @ts-ignore
          dateFilter={dateFilter}
        />
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
    accountType: state.userProfile.accountTypeId,
    exportInfo: selectExportInfo(state),
    exportStatus: selectExporting(state),
    isExportEnabled: selectIsExportActive(state),
    hasDataToExport: selectHasDataToExport(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetState: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmailPerformance);
