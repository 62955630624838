import { Tooltip } from "components/common/tooltip";
import { Icon } from "semantic-ui-react";
import { FormikProps } from "formik";
import { ValidatedForm } from "components";
import { TemplateConfigurationView } from "../../state/GroupTemplateSlice";

import styles from "./flowsAutoStartToggle.module.scss";

export const FlowsAutoStartToggle = (props: FormikProps<TemplateConfigurationView>) => {
  return (
    <div className={styles.root}>
      <ValidatedForm.CheckboxField
        label="Auto-start all flows that begin with email"
        value={props.values.autoStartFlows}
        propertyName={"autoStartFlows"}
        toggle
        {...props}
      />
      <Tooltip
        className={styles.tooltip}
        target={<Icon className="info circle" />}
        content="Automatically starts all Flows initiated by email within this template. Note: Flows will not restart for users who have already initiated them."
        position="top center"
        styles={{ width: "20rem" }}
        hideOnScroll
        showAlways
      />
    </div>
  );
};
