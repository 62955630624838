import { useQuery } from "@tanstack/react-query";
import { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import {
  AccountType,
  FormattedLineData,
  PerformanceWithAccountFilter,
  QueryFilterWithAccount,
  clicksColor,
  completes,
  completesColor,
  lineChartFactory,
  opensColor,
  roundToTwoDigits,
  sendsColor,
  starts,
  startsColor,
} from "features/Library/Common/utils/performanceUtils";
import * as contentReportingService from "../services/contentReportingService";
import * as dashboardReportingService from "../services/dashboardReportingService";

export const fetchEmailEngagementQuery = async ({ queryKey }: QueryFilterWithAccount) => {
  const { data } = await contentReportingService.getEmailEngagement(queryKey[1]);
  return {
    raw: data,
    barData: [
      {
        id: "sends",
        category: "Sends",
        value: data.Sends ?? 0,
        fillColor: sendsColor,
      },
      {
        id: "opens",
        category: "Opens",
        value: data.Opens ?? 0,
        fillColor: opensColor,
      },
      {
        id: "clicks",
        category: "Clicks",
        value: data.Clicks ?? 0,
        fillColor: clicksColor,
      },
    ],
    cardData: [
      {
        statDescription: "Avg. Send Size",
        stat: roundToTwoDigits(data.AvgSendSize),
        popupBody: "The average number of recipients per email send.",
      },
      {
        statDescription: "Open Rate",
        stat: `${roundToTwoDigits(data.OpenRate * 100)}%`,
        popupBody: "The percentage of emails opened compared to the sum of how often emails have been sent.",
      },
      {
        statDescription: "Click-to-open Rate",
        stat: `${roundToTwoDigits(data.ClickToOpen * 100)}%`,
        popupBody: "The percentage of emails clicked compared to the sum of how often emails have been opened.",
      },
      {
        statDescription: "Clickthrough Rate",
        stat: `${roundToTwoDigits(data.ClickThroughRate * 100)}%`,
        popupBody: "The percentage of emails clicked compared to the sum of how often emails have been sent.",
      },
    ],
  };
};

export type emailEngagementQueryProps = {
  filter: PerformanceWithAccountFilter & { type: AccountType };
  enabled: boolean;
};

export const useEmailEngagementQuery = (props: emailEngagementQueryProps) =>
  useQuery({
    queryKey: ["EmailsReportEngagement", props.filter],
    queryFn: fetchEmailEngagementQuery,
    enabled: props.enabled,
  });

export const fetchFlowGoalsLineQuery = async (): Promise<{
  [key in dashboardReportingService.ObjectiveTypes]: FormattedLineData<dashboardReportingService.FlowGoalLineChart>;
}> => {
  const [onboardingTransitionResponse, remediationResponse, optimizationResponse] = await Promise.all([
    dashboardReportingService.fetchFlowGoalsLineData(dashboardReportingService.ObjectiveTypesEnum.OnboardingTransition),
    dashboardReportingService.fetchFlowGoalsLineData(dashboardReportingService.ObjectiveTypesEnum.Remediation),
    dashboardReportingService.fetchFlowGoalsLineData(dashboardReportingService.ObjectiveTypesEnum.Optimization),
  ]);

  return {
    "onboarding/transition": lineChartFactory(onboardingTransitionResponse.data),
    remediation: lineChartFactory(remediationResponse.data),
    optimization: lineChartFactory(optimizationResponse.data),
  };
};

const getBarData = (response: dashboardReportingService.FlowGoalEngagement): DataPoint[] => {
  return [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: response.InProgress,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: response.Completed,
      fillColor: completesColor,
    },
  ];
};

export const fetchFlowGoalsEngagementQuery = async (): Promise<{
  barData: { [key in dashboardReportingService.ObjectiveTypes]: DataPoint[] };
  cardData: CardReportingItem[];
}> => {
  const [onboardingTransitionResponse, remediationResponse, optimizationResponse] = await Promise.all([
    dashboardReportingService.fetchFlowGoalsEngagement(
      dashboardReportingService.ObjectiveTypesEnum.OnboardingTransition,
    ),
    dashboardReportingService.fetchFlowGoalsEngagement(dashboardReportingService.ObjectiveTypesEnum.Remediation),
    dashboardReportingService.fetchFlowGoalsEngagement(dashboardReportingService.ObjectiveTypesEnum.Optimization),
  ]);

  return {
    barData: {
      "onboarding/transition": getBarData(onboardingTransitionResponse.data),
      remediation: getBarData(remediationResponse.data),
      optimization: getBarData(optimizationResponse.data),
    },
    cardData: [
      {
        statDescription: "Onboarding / Transition",
        stat: onboardingTransitionResponse.data.FlowsCount.toLocaleString(),
      },
      {
        statDescription: "Remediation",
        stat: remediationResponse.data.FlowsCount.toLocaleString(),
      },
      {
        statDescription: "Optimization",
        stat: optimizationResponse.data.FlowsCount.toLocaleString(),
      },
    ],
  };
};

export const useFlowGoalsLineChartQuery = (enabled = true) =>
  useQuery({
    queryKey: ["flowGoals"],
    queryFn: fetchFlowGoalsLineQuery,
    enabled,
  });

export const useFlowGoalsEngagementQuery = (enabled = true) =>
  useQuery({
    queryKey: ["flowGoalsEngagement"],
    queryFn: fetchFlowGoalsEngagementQuery,
    enabled,
  });
