import { GroupTemplatePacksConfirmationModal } from "components/assignmentModals/groupTemplatePacksConfirmationModal/GroupTemplatePacksConfirmationModal";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { useObserver } from "hooks/useObserver";
import { IObservable } from "interfaces/IObservable";
import { FC, useEffect, useMemo, useRef } from "react";
import { Observable } from "utils";
import { bindAction } from "interfaces";
import { connect, ConnectedProps } from "react-redux";
import { PackAccountsContextItem, PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { getTemplatesPacks } from "../state/thunks/AccountTemplatesThunk";
import { AccountPack } from "features/People/GroupTemplate/DataService/types";

type Props = PropsFromRedux & {
  onTriggerModalObserver: IObservable<(onConfirm: (packs: PackAccountsContextItem[]) => void, templateIds: number[]) => void>;
};

export const ConfirmTemplatesPacksModal: FC<Props> = (props) => {
  const { onTriggerModalObserver, getTemplatesPacks } = props;
  const onTriggerPacksConfirmationObserver = useMemo(() => new Observable(), []);
  const packMap = useRef<PackMap>(new Map());

  const [subscribeOnModalTrigger] = useObserver(onTriggerModalObserver);

  const initPackMap = (items: AccountPack[]): PackMap => {
    const map = new Map();
    items.forEach((item) => {
      map.set(item.id, item);
    });
    return map;
  };

  useEffect(() => {
    const onTriggerModal = async (onConfirm: (packs: PackAccountsContextItem[]) => void, templateIds: number[]) => {
      const templatesPacks = await getTemplatesPacks(templateIds);
      if (!templatesPacks.length) {
        onConfirm([]);
        return;
      }
      packMap.current = initPackMap(templatesPacks);
      onTriggerPacksConfirmationObserver.notify(() => onConfirm([...packMap.current.values()]), packMap.current);
    };

    return subscribeOnModalTrigger(onTriggerModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnModalTrigger]);

  const infoText =
    "License quantities for each Pack in the selected templates have been calculated based on previously imported users. " +
    "Quantities may adjust automatically as new users are added.";

  return (
    <GroupTemplatePacksConfirmationModal
      onTriggerModalObserver={onTriggerPacksConfirmationObserver}
      packAccountItemsText={infoText}
    />
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getTemplatesPacks: bindAction(getTemplatesPacks, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfirmTemplatesPacksModal);
