import { FileLike } from "../../interfaces";
import { PackResourceItem } from "../Licensing/Packs/types/state";

export interface AccountInfo {
  parentAccountId: number;
  id: number;
  accountTypeId: number;
  accountTypeName: string;
  name: string;
  dateModified: string;
  parentName: string;
  logoUri: string | null | undefined;
  alias: string | null | undefined;
  contractTypeId: number | null;
  isDemo: boolean;
  magicLinkLogin: boolean;
  canEdit: boolean;
  canChangeAccountType: boolean;
  sessionTimeoutInMinutes?: number;
  timeZone?: string;
}

export interface AccountConfigurationInfo extends AccountInfo {
  sessionTimeoutDays: number;
  sessionTimeoutHours: number;
  sessionTimeoutMinutes: number;
}

export interface ParentAccount {
  id: number;
  name: string;
}

export interface ContractType {
  id: number;
  name: string;
  downloadEndpoint?: string;
}

export interface AccountType {
  id: number;
  name: string;
}

export enum AccountTypes {
  Customer = 1,
  StrategicPartner, // Solution Provider = Strategic Partner = SaaS Vendor
  Distributor,
  BSI, // BrainStorm Inc
  ChannelPartner, // Service Provider
  CSP, // Cloud Service Provider
  MSP, // Managed Service Provider
  Copilot,
}

export interface Account {
  name: string;
}

export interface Subscription {
  isSigned: boolean;
  signature?: ContractSignature;
}

export interface ContractSignature {
  id: number;
  signer: string;
  date: string | null;
  contracts: ContractType[];
}

export interface AddOn {
  id: number;
  name: string;
  description: string;
  isEnabled: boolean;
  expirationDate: string | null;
  canEdit: boolean;
  default: boolean;
}

export interface SavingAddOn {
  id: number;
  expirationDate: string | null;
}

export interface AccountPack {
  id: number;
  title: string;
  description: string;
  thumbnailImageUrl: string;
  dateCreated: Date;
  dateModified: Date;
  issuedDate: Date;
  expirationDate: Date;
  type: PackResourceItem;
  isDraft: boolean;
  isOwn: boolean;
  isLocked: boolean;
  isExpired: boolean;
  isTrial: boolean;
  licensesCount: number;
  licensesUsed: number;
  isArchived?: boolean;
  logoUri: string;
  publisher: string;
  hasSource: boolean;
}

export interface AccountPackLicense {
  packAccountLicensesId: number;
  totalLicensesCount?: number;
  assingedLicensesCount: number;
  expirationDate?: Date;
  licenseTypeId: number;
  packTitle: string;
  thumbnailImageUrl: string;
}

export interface IdentityProviderConfigInfo {
  name: string;
  identityProvider?: string;
  type: IdentityProviderType;
  metadataUrl?: string;
  metadataFile?: FileLike;
  metadataAutoRefresh: boolean;
  identityProviderId?: string;
}

export enum IdentityProviderType {
  None = 0,
  WsFederation = 1,
  Saml2 = 2,
}

export enum CertificateValidationTypes {
  None = 0,
  SelfSigned = 1,
  Full = 2,
}

export interface ClaimType {
  id: number;
  name: string;
  isRequired: boolean;
  alias: string;
}

export interface SigningCertificate {
  name: string;
  thumbprint: string;
}

export interface EncodingType {
  id: number;
  name: string;
}

export interface SignatureAlgorithm {
  id: number;
  name: string;
}

export interface ClaimsMapping {
  id?: number;
  claimTypeId: number;
  accountClaimTypeName: string;
}

export interface ClaimsCustomMapping {
  id?: number;
  fieldName: string;
  fieldValue: string;
}

export interface CreateIdentityProvider {
  name: string;
  type: IdentityProviderType;
  metadataContentUrl: string;
  fileName?: string;
  metadataUrl?: string;
  metadataAutoRefresh: boolean;
  omitAssertionSignatureCheck?: boolean;
  signRequest?: boolean;
  isSiteMinder?: boolean;
  forceAuth?: boolean;
  encodingTypeId?: number;
  isPassive?: boolean;
  certificateValidationType?: CertificateValidationTypes;
  signingCertificateThumbprint?: string;
  signatureAlgorithmId?: number;
  claimsMappings: ClaimsMapping[];
  claimsCustomMappings: ClaimsCustomMapping[];
  updateUserInfo: boolean;
}

export interface IdentityProviderAttributeMapping {
  id?: number;
  claimsMappings: ClaimsMapping[];
  claimsCustomMappings: ClaimsCustomMapping[];
  updateUserInfo: boolean;
}

export interface RestrictedResource<T> {
  items: Array<T>;
  isResourceUnavailable: boolean;
}

export interface IdentityProvider {
  id: number;
  name: string;
  accountId: number;
  identityId: string;
  addedDate: Date | string;
  type: IdentityProviderType;
}

export interface IdentityProviderConfiguration {
  id: number;
  name: string;
  identityId: string;
  type: IdentityProviderType;
  fileName?: string;
  metadataUrl?: string | null;
  metadataAutoRefresh: boolean;
}

export interface UpdateIdentityProviderConfiguration {
  id: number;
  name: string;
  metadataUrl?: string;
  metadataContentUrl?: string;
  metadataAutoRefresh: boolean;
}

export interface IdentityProviderSamlSettings {
  metadataAutoRefresh: boolean;
  omitAssertionSignatureCheck: boolean;
  signRequest: boolean;
  isSiteMinder: boolean;
  forceAuth: boolean;
  encodingTypeId: number;
  isPassive: boolean;
  certificateValidationType: CertificateValidationTypes;
  signingCertificateThumbprint: string;
  signatureAlgorithmId: number;
}

export interface CertificateValidationType {
  name: string;
  type: CertificateValidationTypes;
}

export enum ApiAccessType {
  Full = "Full",
  ReadOnly = "ReadOnly",
}

export interface ApiClient {
  id: number;
  clientId: string;
  clientSecret?: string;
  enabled: boolean;
  accessType: ApiAccessType;
  pendingState?: { operationId?: string; loading?: boolean };
}

export interface EmailSetting {
  id: number | undefined;
  senderName: string;
  senderEmail: string;
  userName: string | undefined;
  password: string | undefined;
  host: string;
  port: number;
  isSsl: boolean;
  replyToEmail: string;
  accountId: number;
}

export interface VerifyEmailModel {
  receiverValidationEmail: string;
}
export interface VerifyEmailSettingsRequest extends EmailSetting, VerifyEmailModel {}
export interface VerificationResult {
  verified: boolean;
  message?: string;
}

export enum PermissionType {
  None = 0,
  Limited,
  Full,
}
