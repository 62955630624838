import axios from "axios";

import {
  formatFiltersV2api,
  mapToV2Filters,
  overviewFiltering,
  serializeArrayAndFilterNullable,
} from "../../../../utils/queryUtils";
import { TagsToContent } from "../../../../interfaces";
import { PdfPerformanceRequest, UpdatePdfFileRequest } from "../types/requests";
import { IPdfDataService } from "../types/services";
import { PdfPayload } from "../types/models";
import { GetPagedDataByIdRequestV3, WithPagedDataV2 } from "../../../../interfaces/getPagedDataRequest";
import { v2FilterMap } from "../../../People/types";
import { escapeTerm } from "../../../../utils/searchUtils";

import KnownHttpHeaders from "../../../Application/services/knownHttpHeaders";
import { BasePerformanceRequestFilterParams } from "../../Common/models";

let lineDataAbortController = new AbortController();
let engagementAbortController = new AbortController();
let peopleDataAbortController = new AbortController();

let exportAbortController = new AbortController();

const basePdfApiUrl = "/api/assets/pdfs";

const pdfsDataService: IPdfDataService = {
  getPublishedPdfsByIdsAsync(ids: string[], showPurchased: boolean) {
    return axios.get(`${basePdfApiUrl}/published`, {
      params: {
        showPurchased,
        pdfIds: ids,
        top: ids.length,
      },
      paramsSerializer: overviewFiltering,
    });
  },

  getTagsFilterOptionsAsync(showPurchased: boolean) {
    return axios.get(`${basePdfApiUrl}/tags`, { params: { showPurchased } });
  },

  addTagsToPdfAsync(data: TagsToContent) {
    return axios.post(`${basePdfApiUrl}/tags`, data);
  },

  savePdfTagsAsync(id: number, payload: TagsToContent) {
    return axios.put(`${basePdfApiUrl}/${id}/tags`, payload);
  },

  deletePdfsAsync(ids: number[]) {
    return axios.delete(basePdfApiUrl, { data: { ids } });
  },

  getPdfAsync(id: number) {
    return axios.get(`${basePdfApiUrl}/${id}`);
  },

  getPublishersFilterOptionsAsync() {
    return axios.get(`${basePdfApiUrl}/publishers`);
  },

  updatePdfAsync(data: PdfPayload) {
    return axios.put(`${basePdfApiUrl}/${data.id}`, data);
  },

  updatePdfFileAsync(request: UpdatePdfFileRequest) {
    return axios.put(`${basePdfApiUrl}/${request.id}/pdf`, request);
  },

  allocateResourcesAsync() {
    return axios.post(`${basePdfApiUrl}/allocate`);
  },

  generateSasAsync(id: number) {
    return axios.post(`${basePdfApiUrl}/${id}/generateSas`);
  },

  updatePdfThumbnailAsync(id: number, fileKey: string) {
    return axios.put(`${basePdfApiUrl}/${id}/thumbnail`, {
      fileKey,
    });
  },

  async getPdfUsersAsync<T>(entityId: number, request: WithPagedDataV2<T>) {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    const response = await axios.get(`/api/v4/content/pdf/${entityId}/users`, config);

    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async getPdfGroupsAsync(request: GetPagedDataByIdRequestV3) {
    const { entityId, skip, top, term, filter } = request;

    const response = await axios.get(`/api/v4/content/pdf/${entityId}/groups`, {
      params: {
        skip,
        top,
        ...formatFiltersV2api(mapToV2Filters(filter!, v2FilterMap)),
        term: escapeTerm(term),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });

    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  getPdfEngagementLineData(pdfId, filterParams) {
    lineDataAbortController.abort();
    lineDataAbortController = new AbortController();
    return axios.get("/api/reports/v3/pdfs/linechart", {
      params: {
        pdfId,
        ...filterParams,
      },
      signal: lineDataAbortController.signal,
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getPdfEngagementHorizontalData(pdfId: number, filterParams: PdfPerformanceRequest) {
    engagementAbortController.abort();
    engagementAbortController = new AbortController();
    return axios.get("/api/reports/v3/pdfs/engagement", {
      params: {
        pdfId,
        ...filterParams,
      },
      signal: engagementAbortController.signal,
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getPdfEngagementPeopleData(pdfId: number, filterParams: PdfPerformanceRequest) {
    peopleDataAbortController.abort();
    peopleDataAbortController = new AbortController();
    return axios.get("/api/reports/v3/pdfs/people-details", {
      params: { pdfId, ...filterParams },
      signal: peopleDataAbortController.signal,
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getExport(pdfId: number, filterParams: BasePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();
    return axios.get("/api/dataexport/v3/pdfs/user-progress", {
      responseType: "blob",
      params: {
        pdfId,
        ...filterParams,
      },
      signal: exportAbortController.signal,
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  changeVisibility(ids: number[], visibility: boolean) {
    return axios.post("/api/assets/pdfs/visibility", {
      ids,
      visibility,
    });
  },
};

export default pdfsDataService;
