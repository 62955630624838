import { FC, useCallback, useMemo } from "react";
import { Table } from "semantic-ui-react";
import { ColumnOption } from "../../../../../interfaces/columnOptions";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { SurveyQuestionAnsweredUser } from "../../types/state";
import { PerformanceTabSectionList } from "features/Library/Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";

import "./surveyPerformanceDrilldownList.scss";
import { OtherAnswerOptions } from "../../SurveyDetails/ContentForm/QuestionForm/types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Tooltip } from "components/common/tooltip";
import { TextTruncate } from "components";
import { RouteNames } from "enums";
import moboConfigurator from "../../../../../moboConfigurator";
import { isUserDeleted } from "features/Library/Common/utils/performanceUtils";

export interface Props {
  answers: SurveyQuestionAnsweredUser[];
  otherOptionInformation: OtherAnswerOptions | undefined;
  includeAccounts?: boolean;
}

export const SurveyPerformanceDrilldownList: FC<Props> = ({ answers, otherOptionInformation, includeAccounts }) => {
  const columns: ColumnOption<SurveyQuestionAnsweredUser>[] = useMemo(
    () => [
      {
        isSortable: false,
        name: "Name",
        width: 7,
        render(user) {
          const useLink = !isUserDeleted(user) && !includeAccounts;
          const userName = (
            <Tooltip
              content={`${user.FirstName} ${user.LastName}`}
              target={<TextTruncate>{`${user.FirstName} ${user.LastName}`}</TextTruncate>}
            />
          );
          return (
            <div>
              {useLink ? (
                <Link
                  className="linkButton"
                  to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.peopleUsers}/${user.UserId}`)}
                >
                  {userName}
                </Link>
              ) : (
                userName
              )}
              <div className="userDepartmentTitle">
                {/* Remove empty job title / department */}
                <Tooltip
                  target={<TextTruncate>{[user.Department, user.Title].filter((v) => v).join(", ")}</TextTruncate>}
                  content={`${[user.Department, user.Title].filter((v) => v).join(", ")}`}
                />
              </div>
            </div>
          );
        },
      },
      {
        name: "Email",
        width: 7,
        isSortable: false,
        render(user) {
          return <Tooltip target={<TextTruncate>{user.Email}</TextTruncate>} content={user.Email} />;
        },
      },
      {
        name: "Date",
        width: 7,
        isSortable: false,
        render(user) {
          return dateTimeUtils.formatEmptyDate(user.AnswerDate);
        },
      },
      {
        name: "Account",
        width: 7,
        isSortable: false,
        render(user) {
          return user.AccountName ?? "-";
        },
      },
    ],
    [includeAccounts],
  );

  const buildRow = useCallback(
    (item: SurveyQuestionAnsweredUser) => {
      return columns.map((column, index) => (
        <Table.Cell width={column.width} key={`${column.name}_${index}`}>
          {column.render(item)}
        </Table.Cell>
      ));
    },
    [columns],
  );

  const shouldDisplayOtherText = useCallback(
    // If IsOtherQuestion, then AnswerResult == "true" or AnswerResult being undefined
    // means other option was selected but text response not required
    (answer: SurveyQuestionAnsweredUser): boolean =>
      answer.IsOtherQuestion && answer.AnswerResult !== undefined && answer.AnswerResult !== "true",
    [],
  );

  return (
    <PerformanceTabSectionList
      title="People"
      count={answers.length}
      renderList={(page) => (
        <div className="survey-drilldown-list">
          <Table sortable className="list-table">
            <Table.Header>
              <Table.Row>
                {
                  // These columns will be fixed / never change, so index
                  // is acceptable
                  columns.map((col, i) => (
                    <Table.HeaderCell width={col.width} key={i}>
                      {col.name}
                    </Table.HeaderCell>
                  ))
                }
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {answers.slice((page - 1) * 10, page * 10).map((answer, i) => (
                <Table.Row key={`${answer.UserId} ${i}`}>
                  <Table.Cell width={16} className="response-container">
                    <Table className="layout-table">
                      <Table.Body>
                        <Table.Row>{buildRow(answer)}</Table.Row>
                        {shouldDisplayOtherText(answer) && (
                          <Table.Row>
                            <Table.Cell width={16} className="other-response">
                              <div
                                className={classNames("header", {
                                  required: otherOptionInformation?.isUserTextFieldRequired,
                                })}
                              >
                                Text Response
                              </div>
                              <div className="answer">{answer.AnswerResult}</div>
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    />
  );
};
