import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReducerNamespaceTypes, ReducerEntityPrefixTypes } from "enums";
import DataService from "features/Application/services/dataServices/typedDataService";
import { getPrefix } from "features/Application/slices/models";
import { AvailableTemplate } from "../../CreateAccount/types";
import type { AppDispatch, AppThunk, RootState } from "features/Application/globaltypes/redux";
import { FetchSuccessPayload } from "features/Application/slices/createFetchingItemsSlice";
import { escapeTerm } from "utils/searchUtils";
import { formatFilters } from "utils/filterMapUtils";
import { FilterType } from "utils/filterUtils";
import { GetAvailableTemplatesRequest, TemplatePacks } from "features/People/GroupTemplate/DataService/types";
import { PackAccountsContextItem } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import dateTimeUtils from "utils/dateTimeUtils";
import { DistributedOpUpdateParams } from "interfaces";
import { pluralize } from "utils/stringUtils";
import backgroundTask from "features/BackgroundTasks/backgroundTask";

export const fetchAvailableTemplates = createAsyncThunk<
  FetchSuccessPayload<AvailableTemplate>,
  GetAvailableTemplatesRequest
>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.AccountCreation,
    name: "templates",
  }),
  async (req: GetAvailableTemplatesRequest, { getState }) => {
    const filtersState = (getState() as RootState).accounts.availableTemplates.filters;
    const result = await DataService.groupTemplates.getAvailableTemplates({
      ...req,
      term: escapeTerm(filtersState.search),
      ...formatFilters(filtersState.appliedFilter, {
        dateCreated: FilterType.DateRange,
      }),
    });

    return { items: result.items, totalCount: result.count };
  },
);

export const addTemplatesThunk =
  (accountId: number, templates: number[]): AppThunk =>
  async () => {
    if (templates.length === 0) return;
    try {
      await DataService.accounts.addTemplates(accountId, templates);
    } catch {}
  };

export const getTemplatesPacks = (templateIds: number[]) => async () => {
  const result = await DataService.groupTemplates.getTemplatesPacks(templateIds);
  return result.data;
};

export const saveTemplatesPacks = (accountId: number, packs: PackAccountsContextItem[]) => {
  return async (dispatch: AppDispatch) => {
    if (packs.length === 0) return;

    const templatesPacks: TemplatePacks[] = packs.map((p) => {
      return {
        packId: p.id,
        templateLicenses: p.templateIds?.length
          ? p.templateIds.map((id) => ({
              templateId: id,
              licenseTypeId: p.licenseTypeId!,
              expirationDate: p.expirationDate
                ? new Date(dateTimeUtils.toServerFormatDate(p.expirationDate))
                : undefined,
              licensesCount: 1,
            }))
          : [],
      };
    });

    const getOperationProps = async () => {
      const data = await DataService.groupTemplates.saveTemplatesPacks(accountId, templatesPacks);
      return data;
    };

    const updateParams: DistributedOpUpdateParams = {
      id: "AddTemplatePacksToAccount",
      title: `Add ${pluralize("template", packs.length)} to account`,
      getOperationProps,
      indeterminate: true,
      successTransientMessage: `${pluralize("Template", packs.length)} has been added to account successfully`,
      failureTransientMessage: `Adding ${pluralize("template", packs.length)} to account failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};
