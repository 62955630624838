import { FC } from "react";

import { OverviewHeader } from "../../../../../components/sectionHeader";
import { DeleteVideoButton } from "./DeleteVideoButton";
import { CreateVideoButton } from "./CreateVideoButton";
import { RolePermissions } from "../../../../../enums";
import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";

import Restricted from "../../../../Application/Restricted";
import useToggle from "../../../../../hooks/useToggle";
import TagsManageContainer from "../containers/TagsManageContainer";
import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";


export interface VideoOverviewHeaderProps {
  selectedIds: Array<number>;
  videoDeletionHandler: (selectedIds: number[]) => () => void;
  purchasedSelected: boolean;
  undeletableSelected: boolean;
  draftSelected: boolean;
  isAnyVideoExists: boolean;
  hiddenSelected: boolean;
  visibleSelected: boolean;
  clearSelection: () => void;
  onToggleVisibility: (selectedIds: number[], hidden: boolean) => () => void;
}

const addToPacksPermissions = [RolePermissions.PacksManage, RolePermissions.FlowsCreate];

export const VideoOverviewHeader: FC<VideoOverviewHeaderProps> = ({
  selectedIds,
  videoDeletionHandler,
  purchasedSelected,
  undeletableSelected,
  draftSelected,
  isAnyVideoExists,
  clearSelection,
  onToggleVisibility,
  hiddenSelected,
  visibleSelected
}) => {
  const [packsModalShown, togglePacksModal] = useToggle();
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility);
  const renderBatchButtons = () => {
    return (
      <>
        {visibility && !(visibleSelected && hiddenSelected) && (<ToggleVisibilityButton onClick={onToggleVisibility(selectedIds, hiddenSelected)} hidden={hiddenSelected}/>)}
        <Restricted permissions={addToPacksPermissions}>
          <AddToPacksButton onClick={togglePacksModal} isDisabled={purchasedSelected || draftSelected} />
        </Restricted>
        <DeleteVideoButton
          purchasedAssetsSelected={purchasedSelected}
          undeletableSelected={undeletableSelected}
          onClick={videoDeletionHandler(selectedIds)}
        />

        <TagsManageContainer
          entity="assets"
          isDisabled={purchasedSelected}
          selectedIds={selectedIds}
          onComplete={clearSelection}
        />

        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={togglePacksModal}
          onComplete={clearSelection}
          selectedItemIds={selectedIds}
          contentType="Video"
        />
      </>
    );
  };

  return (
    <OverviewHeader
      title="Videos"
      itemName="Video"
      titleForGA="Assets - Videos"
      selectedItemsAmount={selectedIds.length}
    >
      {isAnyVideoExists && <div className="drag-drop-title">Drag &amp; Drop to Upload Assets</div>}
      {selectedIds.length ? renderBatchButtons() : <CreateVideoButton />}
    </OverviewHeader>
  );
};
