import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Pdf, PdfDetailsState, PdfTags } from "../../types/state";
import { PdfFormFields } from "../../Details/types";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

export const initialState: PdfDetailsState = {
  pdf: {
    id: -1,
    fileName: "",
    fileUrl: "",
    thumbnailUrl: "",
    isDraft: true,
    isPurchased: false,
    hasBeenPublished: false,
    title: "",
    description: "",
    dateModified: undefined,
    settings: {
      labels: [],
      softwareApplications: [],
      flows: [],
      packs: [],
    },
    visibility: false
  },
  uploadedPdfs: [],
  isLoaded: false,
  isLoading: false,
  isUploaded: false,
  isUploading: false,
};

const pdfDetailsSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Pdfs}/base`,
  initialState: initialState,
  reducers: {
    clearPdf: () => initialState,
    savePdf(state: PdfDetailsState, action: PayloadAction<PdfFormFields>) {
      state.pdf.title = action.payload.title;
      state.pdf.description = action.payload.description;
      state.pdf.fileName = action.payload.fileName;
      state.pdf.thumbnailUrl = action.payload.thumbnailUrl;
      state.uploadedPdfs = action.payload.uploadedPdfs;
    },
    saveSettings(state: PdfDetailsState, action: PayloadAction<PdfTags>) {
      state.pdf.settings.labels = action.payload.labels;
      state.pdf.settings.softwareApplications = action.payload.softwareApplications;
    },
    uploadPdfBegin(state: PdfDetailsState) {
      state.isUploading = true;
    },
    uploadPdfSuccess(state: PdfDetailsState) {
      state.isUploaded = true;
      state.isUploading = false;
    },
    uploadPdfCancel(state: PdfDetailsState) {
      state.isUploaded = false;
      state.isUploading = false;
      state.uploadedPdfs = [];
    },
    savePdfThumbnail(state: PdfDetailsState, action: PayloadAction<string>) {
      state.pdf.thumbnailUrl = action.payload;
    },
    loadPdfBegin(state: PdfDetailsState) {
      state.isLoading = true;
    },
    loadPdfSuccess(state: PdfDetailsState, action: PayloadAction<Pdf>) {
      const { packs, flows, labels, softwareApplications, ...restPdf } = action.payload;

      return {
        ...initialState,
        isLoading: false,
        isLoaded: true,
        pdf: {
          ...initialState.pdf,
          ...restPdf,
          settings: {
            flows,
            labels,
            softwareApplications,
            packs: packs.map((pack) => ({ title: pack.name, id: pack.id })),
          },
        },
      };
    },
    loadPdfFailure(state: PdfDetailsState) {
      state.isLoading = false;
    },
  },
});

export const {
  savePdf,
  saveSettings,
  clearPdf,
  uploadPdfBegin,
  uploadPdfSuccess,
  savePdfThumbnail,
  uploadPdfCancel,
  loadPdfBegin,
  loadPdfSuccess,
  loadPdfFailure,
} = pdfDetailsSlice.actions;

export default pdfDetailsSlice.reducer;
export type pdfDetailsSliceStateType = ReturnType<typeof pdfDetailsSlice.reducer>;
