import ReportingFilter from "components/reportingFilter/ReportingFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import {
  completesColor,
  createDateRange,
  defaultDateFilter,
  fourthColor,
  getFormattedTimeStringFromPeriod,
  isBsi,
  noBarData,
  noData,
  opensColor,
  performanceGray,
  startsColor,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { RootState } from "features/Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { useAccountFilterShouldShow } from "features/Reporting/Content/queries/useAccountFilterShouldShow";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import { useQuery } from "@tanstack/react-query";
import { BasePerformanceQueryContext, PerformancePeriodQueryContext } from "features/Library/Common/models";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import { ChartWrapper, getDateFormatByCount, GroupedBarChart, HorizontalBarChart, LineChart } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";

import CardReporting from "components/cards/CardReporting/CardReporting";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { ColumnOption } from "interfaces/columnOptions";
import { AcquisitionCampaign } from "features/Reporting/types/people";
import { Tooltip } from "components/common/tooltip";
import { TextTruncate } from "components";
import dateTimeUtils from "utils/dateTimeUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import "../peopleStyles.scss";
import { sharedGoogleReportingBarProps } from "features/Reporting/Google/utils/shared";
import moment from "moment";
import { useGoogleTooltipFormats } from "features/Reporting/Google/utils/useGoogleTooltipFormats";
import {
  selectExportInfo,
  selectExporting,
  selectHasDataToExport,
  reset as resetExport,
  setHasDataToExport,
  setExportAction,
} from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { ReportingExport } from "components/reportingExport/ReportingExport";
import { getPeopleAcquisitionExport } from "../actions/peopleActions";
import { queryHasData } from "../Activity/PeopleActivityReport";

const colorRange = [opensColor, startsColor, completesColor, fourthColor, performanceGray];
const colorRangeForSix = [opensColor, startsColor, completesColor, fourthColor, "#7B6FB8", performanceGray];

interface IncomingAcquisitionData {
  ClickDate: string;
  Clicks: number;
  CumulativeClicks: number;
  Medium: string;
}

export const lineChartFactoryAcquisition = (
  dataPoints: IncomingAcquisitionData[],
): { Date: Date[]; Clicks: number[][]; CumulativeClicks: number[][]; Mediums: string[] } => {
  if (dataPoints.length === 0) return { Date: [], Clicks: [], CumulativeClicks: [], Mediums: [] };

  // Group the data by date
  const groupedData: { [key: string]: IncomingAcquisitionData[] } = dataPoints.reduce(
    (acc, dataPoint) => {
      const date = dataPoint.ClickDate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(dataPoint);
      return acc;
    },
    {} as { [key: string]: IncomingAcquisitionData[] },
  );

  const Dates: Date[] = [];
  const Clicks: number[][] = [];
  const CumulativeClicks: number[][] = [];
  const mediumSet = new Set<string>();

  // Fill the Dates, Clicks, and CumulativeClicks arrays
  Object.entries(groupedData).forEach(([date, dataPoints]) => {
    Dates.push(moment(date).local(true).toDate());
    const mediumMap: { [key: string]: { Clicks: number; CumulativeClicks: number } } = {};

    dataPoints.forEach((dataPoint) => {
      mediumSet.add(dataPoint.Medium);
      if (!mediumMap[dataPoint.Medium]) {
        mediumMap[dataPoint.Medium] = { Clicks: 0, CumulativeClicks: 0 };
      }
      mediumMap[dataPoint.Medium].Clicks += dataPoint.Clicks;
      mediumMap[dataPoint.Medium].CumulativeClicks += dataPoint.CumulativeClicks;
    });

    const sortedMediums = Array.from(mediumSet).sort((a, b) => a.localeCompare(b));

    sortedMediums.forEach((medium) => {
      if (!mediumMap[medium]) {
        mediumMap[medium] = { Clicks: 0, CumulativeClicks: 0 };
      }
    });

    Clicks.push(sortedMediums.map((medium) => mediumMap[medium].Clicks));
    CumulativeClicks.push(sortedMediums.map((medium) => mediumMap[medium].CumulativeClicks));
  });

  // Transpose the Clicks and CumulativeClicks arrays
  const transposedClicks = Clicks[0].map((_, colIndex) => Clicks.map((row) => row[colIndex]));
  const transposedCumulativeClicks = CumulativeClicks[0].map((_, colIndex) =>
    CumulativeClicks.map((row) => row[colIndex]),
  );

  const Mediums = Array.from(mediumSet).sort((a, b) => a.localeCompare(b));

  // Create an array of objects containing Medium, Clicks, and CumulativeClicks
  const combined = Mediums.map((medium, index) => ({
    medium,
    clicks: transposedClicks[index],
    cumulativeClicks: transposedCumulativeClicks[index],
  }));

  // Sort the combined array based on the last value of the cumulativeClicks array.
  combined.sort(
    (a, b) => b.cumulativeClicks[b.cumulativeClicks.length - 1] - a.cumulativeClicks[a.cumulativeClicks.length - 1],
  );

  // Extract the sorted Mediums, Clicks, and CumulativeClicks
  const sortedMediums = combined.map((item) => item.medium);
  const sortedClicks = combined.map((item) => item.clicks);
  const sortedCumulativeClicks = combined.map((item) => item.cumulativeClicks);

  return { Date: Dates, Clicks: sortedClicks, CumulativeClicks: sortedCumulativeClicks, Mediums: sortedMediums };
};

const getLineChart = async ({ queryKey, signal }: PerformancePeriodQueryContext) => {
  const { data } = await peopleReportingService.acquisition.getLineChart(
    { ...queryKey[1], aggregation: queryKey[2] },
    signal!,
  );

  const factory = lineChartFactoryAcquisition(data);

  const barData = factory.Date.map((d, index) => {
    const mediums = factory.Mediums;
    const clicks = factory.Clicks;

    const result: { group: string; [key: string]: string | number } = {
      group: moment(d, "YYYY-MM-DD").format("YYYY-MM-DD"),
    };

    mediums.forEach((medium, mediumIndex) => {
      result[medium] = clicks[mediumIndex][index];
    });

    return result;
  });

  let dynamicColorRange: string[] = colorRangeForSix;

  if (factory.Mediums.length < 6) {
    const minimumRequiredLength = 1;
    dynamicColorRange = colorRange.slice(0, factory.Mediums.length ? factory.Mediums.length : minimumRequiredLength);
  }

  return {
    barProps: {
      ...sharedGoogleReportingBarProps,
      legendLabels: [...factory.Mediums],
      data: barData,
      order: factory.Mediums,
      colorRange: dynamicColorRange,
    },
    lineProps: {
      ...sharedAccountReportingLineProps,
      legendLabels: [...factory.Mediums],
      xData: Array.from({ length: factory.Clicks.length }).fill(factory.Date),
      clicks: factory.Clicks,
      cumulativeClicks: factory.CumulativeClicks,
      yData: [...factory.Clicks],
      xFormatterFunc: getDateFormatByCount(factory.Date.length),
      colorRange: dynamicColorRange,
    },
  };
};

const getEngagementChart = async ({ queryKey, signal }: BasePerformanceQueryContext) => {
  const { data } = await peopleReportingService.acquisition.getEngagementChart(queryKey[1], signal!);

  data.sort((a, b) => b.Clicks - a.Clicks);

  const barData = data.map((item, index) => ({
    id: item.Medium.toLowerCase(),
    category: item.Medium,
    value: item.Clicks,
    fillColor: data.length === 6 ? colorRangeForSix[index] : colorRange[index],
  }));

  return barData;
};

const getEngagementCards = async ({ queryKey, signal }: BasePerformanceQueryContext) => {
  const { data } = await peopleReportingService.acquisition.getEngagementCards(queryKey[1], signal!);

  const cards = [
    {
      statDescription: "Sources",
      popupBody:
        "Total number of campaign sources used to drive user acquisition across your account during the specified time period.",
      stat: data.DistinctSources,
    },
    {
      statDescription: "Mediums",
      popupBody:
        "Total number of mediums used to drive user acquisition across your account during the specified time period.",
      stat: data.DistinctMediums,
    },
    {
      statDescription: "Campaigns",
      popupBody:
        "Total number of campaigns launched to drive user acquisition across your account during the specified time period.",
      stat: data.DistinctCampaigns,
    },
    {
      statDescription: "Clicks",
      popupBody: "Total number of clicks from all campaigns across your account during the specified time period.",
      stat: data.Clicks,
    },
  ];

  return cards;
};

const getCampaigns = async ({ queryKey, signal }: BasePerformanceQueryContext) => {
  const { data } = await peopleReportingService.acquisition.getCampaigns(queryKey[1], signal!);

  const result = data.map((item, index) => ({
    Campaign: item.Campaign,
    Clicks: item.Clicks,
    Source: item.Source,
    Medium: item.Medium,
    LastActivity: item.LastActivity,
    id: index,
  }));

  return result;
};

const TITLE = "Activity - Line Chart";
const TITLE_BAR = "Activity - Bar Chart";

export const AcquisitionReport = ({
  accountId,
  accountType,
  resetExport,
  hasDataToExport,
  exportInfo,
  setIsExportEnabled,
  setExport,
  exporting,
  getPeopleAcquisitionExport,
}: PropsFromRedux) => {
  const isReportEnabled = useFeatureFlag(FeatureFlags.AcquisitionReport);
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);
  const [chartState, setChartState] = useState(TITLE);
  const [filter, setFilter] = useState(
    defaultDateFilter({
      includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
      accountId,
      includeDistinct: true,
    }),
  );

  const includeAccountsDropdown = useMemo(
    () => accountFilterShouldShow && isBsi(accountId),
    [accountFilterShouldShow, accountId],
  );

  useEffect(() => {
    setFilter(
      defaultDateFilter({
        includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
        accountId,
        includeDistinct: true,
      }),
    );
  }, [accountFilterShouldShow, accountId]);

  const dateRange = useMemo(() => createDateRange(filter.dateFrom, filter.dateTo), [filter.dateFrom, filter.dateTo]);

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );

  const { groupedBarFormatter } = useGoogleTooltipFormats(chartPeriod, dateRange);

  const lineChartQuery = useQuery({
    queryFn: getLineChart,
    queryKey: ["acquisition line chart", filter, chartPeriod],
    enabled: chartPeriod !== "UNSET" && isReportEnabled,
  });

  const engagementChartQuery = useQuery({
    queryFn: getEngagementChart,
    queryKey: ["acquisition engagement chart", filter],
    enabled: isReportEnabled,
  });

  const engagementCardsQuery = useQuery({
    queryFn: getEngagementCards,
    queryKey: ["acquisition engagement cards", filter],
    enabled: isReportEnabled,
  });

  const campaignsQuery = useQuery({
    queryFn: getCampaigns,
    queryKey: ["acquisition campaigns", filter],
    enabled: isReportEnabled,
  });

  const lineWrapperProps = useMemo(
    () => ({
      titles: [TITLE, TITLE_BAR],
      showLegend: true,
      legendLabels: lineChartQuery.data?.lineProps.legendLabels ?? [],
      colorRange: lineChartQuery.data?.lineProps.colorRange ?? colorRange,
    }),
    [lineChartQuery],
  );

  const activitySection = useMemo(() => {
    if (!lineChartQuery.data) return null;

    if (chartState === TITLE) {
      return <LineChart {...lineChartQuery.data.lineProps} />;
    } else {
      return (
        <GroupedBarChart
          {...lineChartQuery.data.barProps}
          tooltipFormatter={groupedBarFormatter}
          xTickFormatter={(d) => getFormattedTimeStringFromPeriod(d.toString(), chartPeriod, dateRange)}
        />
      );
    }
  }, [lineChartQuery.data, chartState, groupedBarFormatter, chartPeriod, dateRange]);

  const columns = useMemo<ColumnOption<AcquisitionCampaign>[]>(
    () => [
      {
        name: "Source",
        width: 5,
        isSortable: false,
        render(item) {
          return (
            <Tooltip target={<TextTruncate className="wordBreak">{item.Source}</TextTruncate>} content={item.Source} />
          );
        },
      },
      {
        render(item) {
          return (
            <Tooltip target={<TextTruncate className="wordBreak">{item.Medium}</TextTruncate>} content={item.Medium} />
          );
        },
        name: "Medium",
        isSortable: false,
        width: 4,
      },
      {
        width: 4,
        name: "Campaign",
        render(item) {
          return (
            <Tooltip
              target={<TextTruncate className="wordBreak">{item.Campaign}</TextTruncate>}
              content={item.Campaign}
            />
          );
        },
        isSortable: false,
      },
      {
        isSortable: false,
        width: 3,
        name: "Clicks",
        render(item) {
          return <div>{item.Clicks}</div>;
        },
      },
      {
        name: "Last Activity",
        width: 4,
        isSortable: false,
        render(item) {
          return <div>{dateTimeUtils.formatDate(item.LastActivity)}</div>;
        },
      },
    ],
    [],
  );

  // Setting appropriate export method
  useEffect(() => {
    setExport({
      method: getPeopleAcquisitionExport,
      args: [filter],
    });
  }, [filter, setExport, getPeopleAcquisitionExport]);

  useEffect(() => {
    setIsExportEnabled(queryHasData(campaignsQuery));
  }, [setIsExportEnabled, campaignsQuery]);

  useEffect(() => {
    return () => resetExport();
  }, [resetExport]);

  const handleExportClick = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  if (!isReportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div>
      <div className="performanceRoot">
        <div className={cn("performanceHeader", "peopleActivityHeader")}>
          <div className="performanceHeader">
            <h2 className="performanceTitle">Summary</h2>
            <div className="performanceActions">
              <ReportingFilter
                currentFilter={filter}
                callback={setFilter}
                includeAccountsDropdown={includeAccountsDropdown}
                ignoreInCountAndHide={["type"]}
                includeDistinct
              />
              <ReportingExport onClick={handleExportClick} currentlyExporting={exporting} disabled={!hasDataToExport} />
            </div>
          </div>
        </div>
        <div className="performanceBody">
          <div className="graphs">
            <div className="lineChartContainer" ref={measureRef}>
              <ChartWrapper {...lineWrapperProps} onChange={setChartState}>
                <RequestStatusRenderer state={lineChartQuery.status}>
                  {lineChartQuery.isSuccess && validLineData(lineChartQuery.data.lineProps.yData)
                    ? activitySection
                    : noData(filter)}
                </RequestStatusRenderer>
              </ChartWrapper>
            </div>
            <div className={cn("funnelChartContainer", "user_chart_container")}>
              <ChartWrapper titles={["Top Mediums"]}>
                <RequestStatusRenderer state={engagementChartQuery.status}>
                  {engagementChartQuery.data && !noBarData(...engagementChartQuery.data.map((item) => item.value)) ? (
                    <HorizontalBarChart
                      {...sharedAccountReportingHorizontalBarProps}
                      data={engagementChartQuery.data}
                      domain={[0, Math.max(...engagementChartQuery.data.map((e) => e.value))]}
                    />
                  ) : (
                    noData(filter)
                  )}
                </RequestStatusRenderer>
              </ChartWrapper>
            </div>
          </div>
          <div className="performanceCardSection">
            <RequestStatusRenderer state={engagementCardsQuery.status}>
              {engagementCardsQuery.isSuccess && <CardReporting items={engagementCardsQuery.data} />}
            </RequestStatusRenderer>
          </div>
          <div className="table">
            <RequestStatusRenderer state={campaignsQuery.status}>
              {campaignsQuery.isSuccess && (
                <GenericPerformanceList
                  title="Medium Interactions"
                  rows={campaignsQuery.data}
                  columns={columns}
                  filter={filter}
                />
              )}
            </RequestStatusRenderer>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
    accountType: state.userProfile.accountTypeId,
    exportInfo: selectExportInfo(state),
    exporting: selectExporting(state),
    hasDataToExport: selectHasDataToExport(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setIsExportEnabled: bindAction(setHasDataToExport, dispatch),
    setExport: bindAction(setExportAction, dispatch),
    resetExport: bindAction(resetExport, dispatch),
    getPeopleAcquisitionExport: bindActionCreators(getPeopleAcquisitionExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AcquisitionReport);
