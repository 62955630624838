import { QueryFunctionContext } from "@tanstack/react-query";
import { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { LinechartProps } from "components/charts";
import {
  PerformanceFilter,
  PerformanceWithAccountFilter,
  lineChartFactory,
  longFormDateFilter,
  roundToTwoDigits,
} from "features/Library/Common/utils/performanceUtils";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import moment from "moment";

export const yearDateRange = (): PerformanceFilter => {
  const today = moment().startOf("day").toISOString();
  const todayMinus30 = moment().subtract(1, "year").startOf("month").startOf("day").toISOString();
  return longFormDateFilter(todayMinus30, today);
};

type QueryContext = QueryFunctionContext<
  [_name: string, dateFilter: PerformanceWithAccountFilter], AbortSignal
>;

type LineResponse = Pick<LinechartProps, "xData" | "yData">;

// For now, these are 2 separate charts
export const fetchPeopleOverviewLineCharts = async ({
  queryKey,
  signal,
}: QueryContext): Promise<{ activeUsers: LineResponse; interactions: LineResponse; }> => {
  const { data } = await peopleReportingService.overview.getLineChart({ ...queryKey[1] }, signal!);

  const lineChartData = lineChartFactory(data);

  return {
    activeUsers: { xData: [lineChartData.Date], yData: [lineChartData.ActiveUsersCount] },
    interactions: { xData: [lineChartData.Date], yData: [lineChartData.InteractionCount] },
    // Re-add once data team adds license back in
    // activeUsersTooltips: data.map(
    //   (d) => `${d.Users.toLocaleString()} Users\n${roundToTwoDigits(d.LicenseAssignedRate * 100)}% of Licenses`,
    // ),
  };
};

export const fetchPeopleOverviewStatistics = async ({ queryKey, signal }: QueryContext): Promise<CardReportingItem[]> => {
  const { data } = await peopleReportingService.overview.getStatistics({ ...queryKey[1] }, signal!);

  return [
    {
      stat: data.ActiveUserCount?.toLocaleString() ?? 0,
      statDescription: "Licensed Users",
    },
    {
      stat: data.InteractionCount?.toLocaleString() ?? 0,
      statDescription: "Interactions",
    },
    {
      stat: roundToTwoDigits(data.AvgInteractionPerUser ?? 0).toLocaleString(),
      statDescription: "Avg. Interactions / User",
    },
  ];
};

export const fetchPeopleOverviewAccountsTable = async ({ queryKey, signal }: QueryContext) => {
  const { data } = await peopleReportingService.overview.getAccountsTable({ ...queryKey[1] }, signal!);

  // Add id field for table keys
  return data.map((d) => ({ ...d, id: d.AccountId }));
};

export const fetchPeopleOverviewUsersTable = async ({ queryKey, signal }: QueryContext) => {
  const { data } = await peopleReportingService.overview.getUsersTable({ ...queryKey[1] }, signal!);

  // Add id field for table keys
  return data.map((d) => ({ ...d, id: d.UserId }));
};

export const sharedOverviewLineProps = {
  xFormatterFunc: (date: Date) => moment(date).format("MMM 'YY"),
  tooltipFormatter: (date: Date, value: number) =>
    `${moment(date).format("MMM 'YY")}: ${value.toLocaleString()} Interactions`,
} as const;
