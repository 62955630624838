import * as Yup from "yup";

const maxNameLength = 128;
const maxFieldLength = 256;
const requiredDescription = (max: number) => {
  return Yup.string()
    .trim()
    .required("Description is required")
    .min(3, "Description must have at least 3 characters")
    .max(max, `Description must be maximum of ${max} characters long`);
};
export const templateSchema = Yup.object().shape({
  name: Yup.string()
    .required("Template name is required")
    .max(maxNameLength, `Template name must be maximum of ${maxNameLength} characters long`),
  description: requiredDescription(maxFieldLength),
});
