import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";

import { usePerformanceBreadcrumbs } from "../../Common/Hooks/usePerformanceBreadcrumbs";
import FlowPerformanceBody from "./FlowPerformanceBody";

import cn from "classnames";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import {
  reset as resetExports,
  selectExportInfo,
  selectExporting,
  selectHasDataToExport,
  selectIsExportActive,
} from "features/Reporting/state/export/exportSlice";
import { IWizardStep, bindAction } from "interfaces";
import { EntityType } from "../../../../components/charts/types/Sankey";
import { ReportingExport } from "../../../../components/reportingExport/ReportingExport";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "../../../../featureFlags";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { RStatus } from "../../../Application/globaltypes/fetchRequest";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { reset, selectDateRangeValue, selectDateRanges } from "../state/slices/flowPerformanceSlice";
import { fetchFlowDateRanges } from "../state/thunks/flowPerformanceThunk";
import { FlowFilter } from "./Filter/FlowPerformanceFilter";

import { selectIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import "../../Common/utils/performanceSCSSUtils.scss";
import "./flowPerformance.scss";
import { useAccountFilterShouldShow } from "features/Reporting/Content/queries/useAccountFilterShouldShow";
import { allCustomerAccounts, fetchAccounts } from "components/reportingFilter/ReportingFilter";
import { AccountType, accountType as accountTypeFunc, isBsi } from "features/Library/Common/utils/performanceUtils";
import { useLocation } from "react-router-dom";

const SERVER_FORMAT = "MM/DD/YYYY HH:mm:ss";

function formatForServer(date: string) {
  return moment(date).format(SERVER_FORMAT);
}

export type Props = PropsFromRedux &
  IWizardStep & {
    flowId: number;
    flowTitle: string;
  };

export interface AssetInfo {
  id: number;
  type: EntityType;
  name: string;
}

export const FlowPerformance: React.FC<Props> = ({
  flowId,
  flowTitle,
  exportInfo,
  dateRangeState,
  dateRangeValue,
  exportStatus,
  getDateRanges,
  reset,
  acceptHandlers,
  resetExports,
  isExportEnabled,
  reportEnabled,
  accountId,
  accountType,
  hasDataToExport,
}) => {
  const { state } = useLocation();
  const type = accountTypeFunc(accountId);
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);
  const [selectedAsset, setSelectedAsset] = useState<AssetInfo>();
  const [filterIndex, setFilterIndex] = useState(-1);
  const [currentFilter, setCurrentFilter] = useState<{
    date: number;
    includeOwnData?: boolean;
    accounts?: any[];
    type: AccountType;
    isDistinct: boolean;
    versionNumber: number;
  }>({
    date: filterIndex,
    isDistinct: false,
    type,
    versionNumber: filterIndex,
  });
  const [listOfAccounts, setListOfAccounts] = useState<any[]>([]);
  const initialFilterSet = useRef(false); // Sets local state once date ranges are first loaded
  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs();

  const reportFlagEnabled = useFeatureFlag(FeatureFlags.FlowReport);

  useEffect(() => {
    if (accountFilterShouldShow && isBsi(accountId))
      setCurrentFilter({
        date: -1,
        includeOwnData: true,
        accounts: [allCustomerAccounts.text],
        type,
        isDistinct: false,
        versionNumber: -1,
      });
  }, [accountFilterShouldShow, type, accountId]);

  useEffect(() => {
    if (filterIndex !== currentFilter.date) setFilterIndex(currentFilter.date);
  }, [filterIndex, currentFilter]);

  useEffect(() => {
    if (accountFilterShouldShow && isBsi(accountId)) {
      fetchAccounts(setListOfAccounts);
    }
  }, [accountFilterShouldShow, accountId]);

  useEffect(() => {
    if (reportFlagEnabled) {
      getDateRanges(flowId);
    }

    return () => {
      reset();
      resetExports();
    };
  }, [flowId, getDateRanges, reportFlagEnabled, reset, resetExports]);

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load filter index to last value on first load
  useEffect(() => {
    if (dateRangeState.status === RStatus.Got && !initialFilterSet.current) {
      initialFilterSet.current = true;

      const whichDateInRange = (dateTo: string) => {
        const date = new Date(dateTo);

        for (let i = 0; i < dateRangeValue.length; i++) {
          const range = dateRangeValue[i];
          const startDate = new Date(range.DATETIME_START);
          const endDate = range.DATETIME_END ? new Date(range.DATETIME_END) : null;

          if (date >= startDate && (endDate === null || date <= endDate) && range.VERSION !== null) {
            return i;
          }
        }

        return dateRangeValue.length - 1;
      };

      const navigationDateIndex = state?.dateTo ? whichDateInRange(state.dateTo) : dateRangeValue.length - 1;
      setFilterIndex(navigationDateIndex);
      setCurrentFilter({ ...currentFilter, date: navigationDateIndex, versionNumber: navigationDateIndex });
    }
  }, [dateRangeState.status, dateRangeValue, currentFilter, state]);

  const filterParamFormatted = useMemo(() => {
    let tempFilterIndex = filterIndex;
    if (dateRangeValue.length > 0 && filterIndex === -1) tempFilterIndex = dateRangeValue.length - 1;
    if (tempFilterIndex < 0) {
      return null;
    }
    let date = dateRangeValue[tempFilterIndex];
    if (!date) return null;

    return {
      dateFrom: formatForServer(date.DATETIME_START),
      dateTo:
        date.DATETIME_END === null
          ? dateTimeUtils.formatCurrentDateToUtc(SERVER_FORMAT)
          : formatForServer(date.DATETIME_END),
      ...(accountFilterShouldShow && isBsi(accountId) && { includeMyData: currentFilter.includeOwnData }),
      ...(accountFilterShouldShow && isBsi(accountId) && { accounts: currentFilter.accounts }),
      isDistinct: currentFilter.isDistinct,
      type: currentFilter.type,
      versionNumber: date.VERSION,
    };
  }, [
    dateRangeValue,
    filterIndex,
    accountFilterShouldShow,
    currentFilter.includeOwnData,
    currentFilter.accounts,
    currentFilter.type,
    accountId,
    currentFilter.isDistinct,
  ]);

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  const handleFilterReset = useCallback(() => {
    setFilterIndex(dateRangeValue.length - 1);
    setCurrentFilter({
      date: dateRangeValue.length - 1,
      ...(accountFilterShouldShow && isBsi(accountId) && { includeOwnData: true }),
      ...(accountFilterShouldShow && isBsi(accountId) && { accounts: ["All Customer Accounts"] }),
      isDistinct: false,
      type: currentFilter.type,
      versionNumber: currentFilter.versionNumber,
    });
  }, [dateRangeValue.length, accountFilterShouldShow, accountId, currentFilter.type, currentFilter.versionNumber]);

  if (!reportFlagEnabled) {
    return <ReportUnavailable />;
  }

  const tools = (
    <>
      <div className="flowActions">
        <FlowFilter
          currentFilter={currentFilter}
          dateRanges={dateRangeValue}
          setCurrentFilter={setCurrentFilter}
          includeAccountsDropdown={accountFilterShouldShow && isBsi(accountId)}
          accounts={listOfAccounts}
          handleReset={handleFilterReset}
          // Disabled filter if drilled down
          // as the lower item may not exist
          // in the new flow version
          disabled={hasBreadcrumbsToDisplay}
        />
        {isExportEnabled && (
          <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={!hasDataToExport} />
        )}
      </div>
    </>
  );

  const toolbar = reportEnabled ? tools : <div></div>;

  return (
    <div className={cn("flowPerformanceRoot stretch", { drilldownReportUnavailable: !reportEnabled })}>
      {/* Skips renderer check if feature flag is disabled */}
      <RequestStatusRenderer state={dateRangeState}>
        <header className="flowPerformanceHeader">
          {hasBreadcrumbsToDisplay ? <div>{domElements}</div> : <h2 className="performanceTitle">Summary</h2>}
          {selectedAsset ? toolbar : tools}
        </header>

        <div className={cn("flowPerformanceRoot", { flowPerformanceBody: selectedAsset && !reportEnabled })}>
          <FlowPerformanceBody
            includeAccounts={
              accountFilterShouldShow && isBsi(accountId) && currentFilter.accounts && currentFilter.accounts.length > 0
            }
            setSelectedAssetFlow={setSelectedAsset}
            flowId={flowId}
            flowTitle={flowTitle}
            registerBreadcrumb={registerBreadcrumb}
            dateFilter={filterParamFormatted}
          />
        </div>
      </RequestStatusRenderer>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    dateRangeState: selectDateRanges(state),
    dateRangeValue: selectDateRangeValue(state),
    exportStatus: selectExporting(state),
    exportInfo: selectExportInfo(state),
    isExportEnabled: selectIsExportActive(state),
    reportEnabled: selectIsReportEnabled(state),
    accountId: state.userProfile.accountId,
    accountType: state.userProfile.accountTypeId,
    hasDataToExport: selectHasDataToExport(state),
  };
};

/* istanbul ignore next */
const mapActionToProps = (dispatch: AppDispatch) => {
  return {
    getDateRanges: bindAction(fetchFlowDateRanges, dispatch),
    reset: bindAction(reset, dispatch),
    resetExports: bindAction(resetExports, dispatch),
  };
};

const connector = connect(mapStateToProps, mapActionToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowPerformance);
