import { FC, useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { max } from "d3";

import { ChartWrapper, HorizontalBarChart, NoData } from "../../../../../components/charts";
import { RequestStatusRenderer } from "../../../../../components/requestStatsRenderer/RequestStatusRenderer";

import { RStatus } from "../../../../Application/globaltypes/fetchRequest";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import {
  reset,
  selectLicenseDepartmentBarChartData,
  selectLicenseUsage,
  selectTotalAccountLicenses,
  selectTotalAccountPieChartData,
} from "../../state/slices/packPurchasedPerformanceSlice";
import * as packPerformanceActions from "../../state/thunks/packPurchasedPerformanceThunk";

import "./packPurchasedPerformance.scss";
import { completesColor, startsColor } from "features/Library/Common/utils/performanceUtils";
import { unlimitedNumber } from "utils/miscellaneousUtils";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";

export interface PackPurchasedPerformanceProps extends PropsFromRedux {
  packId: number;
  moboId?: number;
}

export const PackPurchasedPerformance: FC<PackPurchasedPerformanceProps> = ({
  packId,
  moboId,
  totalAccountLicenseState,
  licenseUsageState,
  accountLicensePieData,
  licenseDepartmentData,
  actions,
  resetState,
}) => {
  const noAccountLicenseData = useMemo<boolean>(
    () =>
      totalAccountLicenseState.status === RStatus.Got && accountLicensePieData && accountLicensePieData.length === 0,
    [totalAccountLicenseState.status, accountLicensePieData],
  );

  const noLicenseUsageData = useMemo<boolean>(
    () => licenseUsageState.status === RStatus.Got && licenseDepartmentData && licenseDepartmentData.length === 0,
    [licenseUsageState.status, licenseDepartmentData],
  );

  const isOwnPack = useMemo(
    () => totalAccountLicenseState.value?.TotalActiveLicenses === unlimitedNumber,
    [totalAccountLicenseState],
  );

  useEffect(() => {
    actions.fetchTotalAccountLicenses(packId, moboId);
    actions.fetchLicenseUsage(packId, moboId);

    return () => {
      resetState();
    };
  }, [actions, packId, moboId, resetState]);

  return (
    <div className="packPurchasedPerformanceRoot">
      <header className="packPurchasedHeader">
        <h2 className="packPurchasedTitle">Summary</h2>
      </header>
      <div className="firstRow">
        <div className="activeLicenseChart">
          <ChartWrapper
            titles={["Active Licenses"]}
            showLegend
            legendLabels={
              isOwnPack || totalAccountLicenseState.status !== RStatus.Got ? ["Issued"] : ["Issued", "Remaining"]
            }
            colorRange={
              isOwnPack || totalAccountLicenseState.status !== RStatus.Got
                ? [startsColor]
                : [startsColor, completesColor]
            }
          >
            <RequestStatusRenderer state={totalAccountLicenseState}>
              {noAccountLicenseData ? (
                <NoData />
              ) : (
                <>
                  {isOwnPack ? (
                    <HorizontalBarChart
                      {...sharedAccountReportingHorizontalBarProps}
                      data={[accountLicensePieData[0]]}
                      domain={[0, max([accountLicensePieData[0]], (d) => d.value) || 100]}
                    />
                  ) : (
                    <HorizontalBarChart
                      {...sharedAccountReportingHorizontalBarProps}
                      data={accountLicensePieData}
                      domain={[0, (accountLicensePieData && max(accountLicensePieData, (d) => d.value)) || 100]}
                      countBarsToShowAtOnce={2}
                    />
                  )}
                </>
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div className="licenseUsageChart">
          <ChartWrapper titles={["License Usage - Department"]}>
            <RequestStatusRenderer state={licenseUsageState}>
              {noLicenseUsageData ? (
                <NoData />
              ) : (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  data={licenseDepartmentData}
                  domain={[0, (licenseDepartmentData && max(licenseDepartmentData, (d) => d.value)) || 100]}
                  countBarsToShowAtOnce={7}
                />
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  totalAccountLicenseState: selectTotalAccountLicenses(state),
  licenseUsageState: selectLicenseUsage(state),
  accountLicensePieData: selectTotalAccountPieChartData(state),
  licenseDepartmentData: selectLicenseDepartmentBarChartData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(packPerformanceActions, dispatch),
  resetState: bindActionCreators(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PackPurchasedPerformance);
