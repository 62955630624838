import React, { useCallback, useRef, useState } from "react";
import FormFooter from "../../../../components/forms/FormFooter";
import Configuration, { ConfigurationRef } from "../Details/Configuration";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { saveTemplate } from "../state/thunks/TemplateThunk";
import { ConnectedProps, connect } from "react-redux";

interface Props {
  isLoading: boolean;
  groupId: number;
  disabled?: boolean;
}

type AllProps = Props & PropsFromRedux;

export const EditConfiguration = (props: AllProps) => {
  const { groupId, disabled } = props;
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const configRef = useRef<ConfigurationRef>(null);

  const onSave = useCallback(() => {
    if (!isDirty) return;
    const config = configRef.current!.getValues();
    props.saveTemplate(groupId, config);
  }, [isDirty, props, groupId]);

  const onCancel = useCallback(() => {
    configRef.current!.resetForm();
  }, []);

  return (
    <>
      <div className="edit-form scrollable-content">
        <Configuration
          ref={configRef}
          disabled={disabled}
          onIsValidChange={setIsValid}
          onIsDirtyChange={setIsDirty}
          isLoadingGroupInfo={props.isLoading}
        />
      </div>
      <FormFooter
        isSaveBtnDisabled={!(isDirty && isValid) || !!disabled}
        isCancelBtnDisabled={!isDirty || !!disabled}
        onSave={onSave}
        onCancel={onCancel}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    saveTemplate: bindAction(saveTemplate, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditConfiguration);
