import { FetchActionPayload } from "interfaces/redux";
import { AvailablePacksRequest, PackView } from "../../types/models";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { getPrefix } from "features/Application/slices/models";
import { GetPacksRequest } from "features/Licensing/Packs/types/requests";
import DataService from "features/Application/services/dataServices/typedDataService";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { DistributedOpUpdateParams } from "interfaces";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import { pluralize } from "utils/stringUtils";
import { TemplatePackAccounts } from "../../DataService/types";
import { PackAccountsContextItem } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import dateTimeUtils from "utils/dateTimeUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const loadGridItemsCount: any = process.env.REACT_APP_LOAD_ITEMS_COUNT;
const loadAddedAccountsCount: number = 100000;

export const fetchAvailablePacks = createAsyncThunk<FetchActionPayload<PackView>, AvailablePacksRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "AvailablePacks",
  }),
  async (requestData: AvailablePacksRequest, { signal }) => {
    const request: GetPacksRequest = {
      top: loadGridItemsCount,
      skip: requestData.skip,
      orderBy: requestData.orderBy,
      searchTerm: requestData.searchTerm,
    };

    const result = await DataService.groupTemplates.getAvailablePacks(requestData.templateGroupId, request, signal);

    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: result.data,
      totalCount: recordsCount,
      append: true,
    };
  },
);

export const savePacks = (
  templateId: number,
  packs: PackAccountsContextItem[],
  accountIds: number[],
  getAccountIds: boolean = false,
) => {
  return async (dispatch: AppDispatch) => {
    if (packs.length === 0) return;

    let selectedAccountIds = accountIds;
    if (getAccountIds) {
      const {
        data: { items: addedAccounts },
      } = await DataService.groupTemplates.getCustomers(templateId, { top: loadAddedAccountsCount, skip: 0 });
      selectedAccountIds = addedAccounts.map((account) => account.id);
    }

    const groupPacks: TemplatePackAccounts[] = packs.map((p) => {
      return {
        packId: p.id,
        accountLicenses: selectedAccountIds.length
          ? selectedAccountIds.map((id) => ({
              accountId: id,
              licenseTypeId: p.licenseTypeId!,
              expirationDate: p.expirationDate
                ? new Date(dateTimeUtils.toServerFormatDate(p.expirationDate))
                : undefined,
              licensesCount: 1,
            }))
          : [],
      };
    });

    const getOperationProps = async () => {
      const data = await DataService.groupTemplates.savePacks(templateId, groupPacks);
      return data;
    };

    const updateParams: DistributedOpUpdateParams = {
      id: "AddPacksToTemplateAccounts",
      title: `Add ${pluralize("pack", groupPacks.length)} to group template`,
      getOperationProps,
      indeterminate: true,
      successTransientMessage: `${pluralize("Pack", groupPacks.length)} has been added to group template successfully`,
      failureTransientMessage: `Adding ${pluralize("pack", groupPacks.length)} to group template failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};
