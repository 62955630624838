import axios, { CancelToken } from "axios";
import { AddPeopleToEmailRequest, DeletePeopleFromEmailRequest, SendTestEmailRequest } from "../types/requests";
import { IEmailsDataService } from "../types/services";
import { Email, Template } from "../types/state";
import { EmailSendSession } from "../SendSessions/CreateEmailSendSession";
import { AccountTypePerformanceRequestFilterParams } from "features/Library/Common/models";
import { serializeArrayAndFilterNullable } from "utils/queryUtils";

const apiUrl = "/api/communications";
const apiUrlV2 = "/api/v2/communications";
const apiUrlV4 = "/api/v4/communications";
const apiUrlReports = "/api/reports/v4/emails";

let exportAbortController = new AbortController();

// Added to prevent issues with launch darkly resolving late
// with new endpoint versions
let emailDensityAbortController = new AbortController();
let emailFunnelAbortController = new AbortController();
let emailSendSessionsAbortController = new AbortController();
let emailSendSessionsDetailsAbortController = new AbortController();

export const emailsDataService: IEmailsDataService = {
  getPublishedEmailsByIdsAsync(ids: string[], showPurchased: boolean) {
    const config = {
      params: {
        showPurchased,
        filter: ids.map((x) => `(PrimaryId eq ${x})`).join(" or "),
      },
    };

    return axios.get(`${apiUrl}/emails/published`, config);
  },

  updateEmailAsync(data: Email) {
    return axios.put(`${apiUrl}/emails/${data.id}/info`, data);
  },

  updateEmailTemplateAsync(data: Template) {
    return axios.put(`${apiUrl}/emails/${data.id}/content`, data);
  },

  getEmailAsync(id: number, cancelToken?: CancelToken) {
    return axios.get(`${apiUrl}/emails/${id}`, { cancelToken });
  },

  getEmailTemplateAsync(id: number) {
    return axios.get(`${apiUrl}/emails/${id}/template`);
  },

  addPeopleToEmailAsync(request: AddPeopleToEmailRequest) {
    const data = {
      peopleIds: request.peopleIds,
    };
    return axios.put(`${apiUrl}/emails/${request.id}/users`, data);
  },

  updateEmailPeopleAsync(request: AddPeopleToEmailRequest) {
    const data = {
      peopleIds: request.peopleIds,
    };
    return axios.post(`${apiUrl}/emails/${request.id}/users`, data);
  },

  deletePeopleFromEmailAsync(request: DeletePeopleFromEmailRequest) {
    const data = {
      peopleIds: request.peopleIds,
    };
    return axios.delete(`${apiUrl}/emails/${request.id}/users`, { data });
  },

  duplicateEmails(ids: number[]) {
    const data = {
      emailIds: ids,
    };

    return axios.post(`${apiUrlV2}/emails/duplicate`, data);
  },

  getEmailPublishInfoAsync(id: number) {
    return axios.get(`${apiUrl}/emails/${id}/publish-info`);
  },

  sendTestEmailAsync(request: SendTestEmailRequest) {
    const data = {
      email: request.email,
    };
    return axios.post(`${apiUrl}/emails/${request.id}/send-test`, data);
  },

  async getPublishersFilterOptions() {
    return axios.get(`${apiUrl}/emails/publishers`, { params: { showPurchased: true } });
  },

  async getTags(showPurchased: boolean = true) {
    return axios.get(`${apiUrl}/emails/tags`, { params: { showPurchased: showPurchased } });
  },

  async addTags(ids: number[], labels: string[]) {
    return axios.put(`${apiUrl}/emails/tags`, { ids, labels });
  },

  createEmailSend(emailId: number, data: EmailSendSession) {
    return data.isTemplateGroupSendSession
      ? axios.post(`${apiUrlV4}/emails/${emailId}/templates-send-sessions`, data)
      : axios.post(`${apiUrlV4}/emails/${emailId}/send-sessions`, data);
  },

  async getEmailSendDetailsV3(
    emailId: number,
    sendSessionId: number,
    filterParams: AccountTypePerformanceRequestFilterParams,
  ) {
    emailSendSessionsDetailsAbortController.abort();
    emailSendSessionsDetailsAbortController = new AbortController();
    return axios.get(`${apiUrlReports}/send-session-users`, {
      params: {
        emailId,
        sendSessionId,
        ...filterParams,
        // https://brainstorm.atlassian.net/browse/SAAS-29089
        // see comment on above ticket, isDistinct has no effect
        // and improves performance
        isDistinct: false,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: emailSendSessionsDetailsAbortController.signal,
    });
  },

  async getEmailLineChart(
    emailId: number,
    sendSessionId: number | undefined,
    filterParams: AccountTypePerformanceRequestFilterParams,
  ) {
    emailDensityAbortController.abort();
    emailDensityAbortController = new AbortController();

    return axios.get(`${apiUrlReports}/linechart`, {
      params: {
        emailId,
        sendSessionId,
        ...filterParams,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: emailDensityAbortController.signal,
    });
  },

  getEmailEngagement(
    emailId: number,
    sendSessionId: number | undefined,
    filterParams: AccountTypePerformanceRequestFilterParams,
  ) {
    emailFunnelAbortController.abort();
    emailFunnelAbortController = new AbortController();
    return axios.get(`${apiUrlReports}/engagement`, {
      params: {
        emailId,
        sendSessionId,
        ...filterParams,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: emailFunnelAbortController.signal,
    });
  },

  getEmailSendSessions(emailId: number, filterParams: AccountTypePerformanceRequestFilterParams) {
    emailSendSessionsAbortController.abort();
    emailSendSessionsAbortController = new AbortController();
    return axios.get(`${apiUrlReports}/send-sessions`, {
      params: {
        emailId,
        ...filterParams,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: emailSendSessionsAbortController.signal,
    });
  },

  async cancelSendSession(sendId: number) {
    return axios.post(`${apiUrlV2}/emails/send-sessions/${sendId}/cancel`);
  },

  async deleteEmails(ids: number[]) {
    const data = {
      emailIds: ids,
    };

    return axios.delete(`${apiUrl}/emails`, { data });
  },

  async getExport(emailId: number, filterParams: AccountTypePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();
    return axios.get("/api/dataexport/v4/emails/send-sessions", {
      responseType: "blob",
      params: {
        emailId,
        ...filterParams,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: exportAbortController.signal,
    });
  },

  getSendSessionExport(sendSessionId: number, filterParams: AccountTypePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();

    return axios.get("/api/dataexport/v4/emails/user-progress", {
      responseType: "blob",
      params: {
        sendSessionId,
        ...filterParams,
        // https://brainstorm.atlassian.net/browse/SAAS-29089
        // see comment on above ticket, isDistinct has no effect
        // and improves performance
        isDistinct: false,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: exportAbortController.signal,
    });
  },
};

export default emailsDataService;
