import { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Icon, SemanticICONS } from "semantic-ui-react";

import { ColumnOption } from "../../../../interfaces/columnOptions";
import { bindAction } from "../../../../interfaces/redux";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { selectEngagementPeopleData, selectEngagementPeopleValue } from "../selectors/performanceSelectors";
import { fetchEngagementPeopleData } from "../state/thunks/pdfPerformanceThunk";
import { EngagementPeople, EngagementStatus } from "../types/state";

import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { EMPTY_CELL_CONTENT } from "../../../../utils/columnUtils";
import { TextTruncate } from "components";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { Tooltip } from "components/common/tooltip";
import { Link } from "react-router-dom";
import { RouteNames } from "enums";
import moboConfigurator from "moboConfigurator";

import styles from "./PdfPerformanceBody.module.scss";

export type PdfPerformancePeopleProps = {
  pdfId: number;
  filter: PerformanceFilter;
  includeAccounts?: boolean;
  flowId?: number;
} & PropsFromRedux;

export type EmailStatusColors = "icon-send-complete" | "icon-send-progress" | "icon-send-canceled";

const StatusToString: { [key in EngagementStatus]: string } = {
  [EngagementStatus.Completed]: "Completed",
  [EngagementStatus.NotStarted]: "Not Started",
};

const StatusToIcon: { [key in EngagementStatus]: SemanticICONS } = {
  [EngagementStatus.Completed]: "check circle",
  [EngagementStatus.NotStarted]: "circle outline",
};

const StatusToColor: { [key in EngagementStatus]: EmailStatusColors } = {
  [EngagementStatus.Completed]: "icon-send-complete",
  [EngagementStatus.NotStarted]: "icon-send-canceled",
};

const getColumnOptions = (includeAccounts: boolean = false) => {
  const baseColumnOptions: ColumnOption<EngagementPeople>[] = [
    {
      name: "NAME",
      width: 5,
      isSortable: false,
      render: (entity: EngagementPeople) => {
        const secondaryInfo = [entity.UserTitle, entity.UserDepartment].filter((x) => x).join(", ");
        return (
          <div>
            {includeAccounts ? (
              <Tooltip
                target={
                  <TextTruncate>
                    {entity.UserFirstName} {entity.UserLastName}
                  </TextTruncate>
                }
                content={`${entity.UserFirstName} ${entity.UserLastName}`}
              />
            ) : (
              <Link
                to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.peopleUsers}/${entity.UserId}`)}
                className={styles.linkButton}
              >
                <Tooltip
                  target={
                    <TextTruncate>
                      {entity.UserFirstName} {entity.UserLastName}
                    </TextTruncate>
                  }
                  content={`${entity.UserFirstName} ${entity.UserLastName}`}
                />
              </Link>
            )}
            <div className={styles.department}>
              <Tooltip target={<TextTruncate>{secondaryInfo}</TextTruncate>} content={secondaryInfo} />
            </div>
          </div>
        );
      },
    },
    {
      name: "EMAIL",
      width: 5,
      isSortable: false,
      render: (entity: EngagementPeople) => (
        <Tooltip target={<TextTruncate>{entity.UserEmail}</TextTruncate>} content={`${entity.UserEmail}`} />
      ),
    },
    {
      name: "DATE VIEWED",
      width: 4,
      isSortable: false,
      render: (entity: EngagementPeople) =>
        entity.DateWatched ? dateTimeUtils.formatDate(entity.DateWatched) : EMPTY_CELL_CONTENT,
    },
    {
      name: "STATUS",
      width: 3,
      isSortable: false,
      render: (entity: EngagementPeople) => (
        <div className={styles.status}>
          <Icon name={StatusToIcon[entity.Status]} className={`${StatusToColor[entity.Status]} ${styles.icon}`} />
          {StatusToString[entity.Status]}
        </div>
      ),
    },
  ];

  return baseColumnOptions;
};

export const PdfPerformancePeople: FC<PdfPerformancePeopleProps> = ({
  fetchEngagementPeopleData,
  pdfId,
  includeAccounts,
  peopleDetailsEntities,
  filter,
  flowId,
  peopleDetailsEntitiesStatus,
  setHasExportData,
}) => {
  const columnOptions = getColumnOptions(includeAccounts);

  useEffect(() => {
    setHasExportData(peopleDetailsEntitiesStatus.status === RStatus.Got && !!peopleDetailsEntities.length);
  }, [setHasExportData, peopleDetailsEntities, peopleDetailsEntitiesStatus.status]);

  useEffect(() => {
    fetchEngagementPeopleData(pdfId, { ...filter, flowId });
  }, [fetchEngagementPeopleData, pdfId, filter, flowId]);

  return (
    <RequestStatusRenderer state={peopleDetailsEntitiesStatus}>
      {peopleDetailsEntities && (
        <GenericPerformanceList title="People" rows={peopleDetailsEntities} columns={columnOptions} filter={filter} />
      )}
    </RequestStatusRenderer>
  );
};

const mapStateToProps = (state: RootState) => ({
  peopleDetailsEntities: selectEngagementPeopleValue(state),
  peopleDetailsEntitiesStatus: selectEngagementPeopleData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchEngagementPeopleData: bindAction(fetchEngagementPeopleData, dispatch),
  setHasExportData: bindAction(setHasDataToExport, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PdfPerformancePeople);
