import { FC, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import {
  QueryFilter,
  chartColorScale,
  chartLegendLabels,
  noBarData,
  noData,
  validLineData,
  completes,
  dailyActivity,
  lineChartTitles,
  starts,
  totalActivity,
} from "features/Library/Common/utils/performanceUtils";
import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "components/charts";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { ColumnOption } from "interfaces/columnOptions";
import { GenericPerformanceList } from "../../shared/GenericPerformanceList";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FlowDetails } from "features/Reporting/types/content";
import dateTimeUtils from "utils/dateTimeUtils";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { CommonAccountReportProps } from "../../types";
import { sharedAccountReportingLineProps, sharedAccountReportingHorizontalBarProps } from "../../shared";
import * as contentReportingService from "features/Reporting/services/contentReportingService";
import { Link } from "react-router-dom";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { ConnectedProps, connect } from "react-redux";

import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { doFlowExport } from "features/Reporting/state/content/contentActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useFlowEngagementQuery, useFlowLineChartQuery } from "../../queries/useFlowLineChartQuery";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import styles from "../../contentOverviewReports.module.scss";
import { setIsReportEnabled, reset } from "features/Reporting/state/toolbar/toolbarSlice";
import moboConfigurator from "../../../../../moboConfigurator";
import * as performanceUtils from "../../../../Library/Common/utils/performanceUtils";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";

const fetchFlowDetailsQuery = async ({ queryKey }: QueryFilter) => {
  let result = (await contentReportingService.getFlowsDetails(queryKey[1])).data;
  return result.map((item, index) => {
    return {
      ...item,
      id: index,
    };
  });
};

export type Props = CommonAccountReportProps &
  PropsFromRedux & {
    includeAccounts: boolean;
  };

export const FlowsReport: FC<Props> = ({
  filter,
  includeAccounts,
  setHasExportData,
  setExportAction,
  flowExport,
  setIsReportEnabled,
  resetIsReportEnabled,
}) => {
  const [chartState, setChartState] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.FlowPerformanceOverview);

  const dateRange = useMemo(() => {
    return performanceUtils.createDateRange(filter.dateFrom, filter.dateTo);
  }, [filter]);

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    performanceUtils.lineChartMargins.left + performanceUtils.lineChartMargins.right,
  );

  const lineChartQuery = useFlowLineChartQuery(filter, chartPeriod);

  const engagementQuery = useFlowEngagementQuery(filter);

  const detailsQuery = useQuery({
    queryKey: ["FlowsReportDetails", filter],
    queryFn: fetchFlowDetailsQuery,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setHasExportData(!!detailsQuery.data && detailsQuery.data.length > 0);
  }, [detailsQuery.data, setHasExportData]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: flowExport,
      args: [filter],
      isExportEnabled: true,
    });
  }, [flowExport, filter, setExportAction]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const columns: ColumnOption<FlowDetails>[] = [
    {
      name: "Flow",
      width: 7,
      isSortable: false,
      render(item) {
        return (
          <>
            {includeAccounts ? (
              <Tooltip
                target={
                  <TextTruncate lines={2} className={styles.wordBreak}>
                    {item.Title}
                  </TextTruncate>
                }
                content={item.Title}
                showAlways
              />
            ) : (
              <Tooltip
                target={
                  <Link
                    to={moboConfigurator.appendMoboIdToUrl(`/content/flows/${item.FlowId}`)}
                    state={{ dateTo: filter.dateTo }}
                  >
                    <TextTruncate lines={2} className={styles.wordBreak}>
                      {item.Title}
                    </TextTruncate>
                  </Link>
                }
                content={item.Title}
                showAlways
              />
            )}
          </>
        );
      },
    },
    {
      name: "Objective",
      width: 7,
      isSortable: false,
      render(item) {
        return (
          <TextTruncate lines={2} className={styles.wordBreak}>
            {item.Goal}
          </TextTruncate>
        );
      },
    },
    {
      name: "Status",
      width: 3,
      isSortable: false,
      render(item) {
        // waiting to find out which is which
        return <div>{item.Status}</div>;
      },
    },
    {
      name: "Total Users",
      width: 4,
      isSortable: false,
      render(item) {
        return <div>{item.Users.toLocaleString()}</div>;
      },
    },
    {
      name: starts,
      width: 4,
      isSortable: false,
      render(item) {
        return <div>{item.Started.toLocaleString()}</div>;
      },
    },
    {
      name: completes,
      width: 4,
      isSortable: false,
      render(item) {
        return <div>{item.Completed.toLocaleString()}</div>;
      },
    },
    {
      name: "Last Activity",
      width: 4,
      isSortable: false,
      render(item) {
        return <div>{dateTimeUtils.toLocalFormatDateTime(new Date(item.LastActivity), "MM/DD/YYYY")}</div>;
      },
    },
  ];

  const lineData = useMemo(() => {
    if (chartState === dailyActivity) {
      return {
        xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date],
        yData: [lineChartQuery.data?.InProgress, lineChartQuery.data?.Completed],
      };
    }
    return {
      xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date],
      yData: [lineChartQuery.data?.InProgressTotal, lineChartQuery.data?.CompletedTotal],
    };
  }, [chartState, lineChartQuery.data]);

  // Update later to use the add-on / selected filter option
  const cardData = useMemo(() => engagementQuery.data?.cardData.slice(2), [engagementQuery.data?.cardData]);

  const barDomain = useMemo(() => {
    if (engagementQuery.isSuccess) {
      let maxValue = Math.max(...engagementQuery.data.barData.map((d) => d.value));
      return [0, Math.max(maxValue, 1)];
    }
  }, [engagementQuery.data?.barData, engagementQuery.isSuccess]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={styles.reportBody}>
      <div className={styles.lineChartContainer} ref={measureRef}>
        <ChartWrapper
          titles={lineChartTitles}
          showLegend
          legendLabels={chartLegendLabels}
          colorRange={chartColorScale}
          onChange={setChartState}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
            validLineData([
              lineChartQuery.data.Completed,
              lineChartQuery.data.CompletedTotal,
              lineChartQuery.data.InProgress,
              lineChartQuery.data.InProgressTotal,
            ]) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                colorRange={chartColorScale}
                {...lineData}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.funnelChartContainer}>
        <ChartWrapper titles={["Engagement"]}>
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess && !noBarData(...engagementQuery.data.barData.map((d) => d.value)) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                domain={barDomain}
                data={engagementQuery.data.barData}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.performanceCardSection}>
        <RequestStatusRenderer state={engagementQuery.status}>
          {engagementQuery.isSuccess && <CardReporting items={cardData!} />}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <RequestStatusRenderer state={detailsQuery.status}>
          {detailsQuery.isSuccess && (
            <GenericPerformanceList title="Flows" rows={detailsQuery.data} columns={columns} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
  flowExport: bindActionCreators(doFlowExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowsReport);
