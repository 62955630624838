import "./pdfs.scss";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { isEmpty, noop } from "lodash";

import SearchInput from "../../../../components/searchInput/SearchInput";
import ViewType from "../../../../enums/ViewType";
import SortOptions from "../../../../enums/SortOptions";
import Restricted from "../../../Application/Restricted";
import PdfsNoResults from "../../../../views/library/pdfs/overview/PdfsNoResults";
import pdfListUtils from "../../../../utils/pdfListUtils";
import PdfsContainer from "./containers/PdfsContainer";

import { ItemsTypes, RolePermissions, RouteNames, SortingDirection } from "../../../../enums";
import { Columns, getColumnOptions, sortOptions } from "./getColumnOptions";
import { RootState } from "../../../Application/globaltypes/redux";
import { Filters } from "../../../../utils/queryUtils";
import {
  deletePdfs as deletePdfsAction,
  fetchOverviewPdfs as fetchOverviewPdfsAction,
  updatePdf as updatePdfAction,
  changeVisibility
} from "../state/thunks/pdfsOverviewThunk";
import {
  normalizedPdfsSelector,
  pdfSearchSelector,
  pdfsStateSelector,
  pdfPaginationSelector,
  pdfSortingColumnNameSelector,
  pdfSortingDirectionSelector,
} from "../selectors";
import { PdfOverview } from "../types/state";
import {
  resetAppliedFilter,
  setAppliedFilter,
  setSearch,
  resetSearch,
  setPagination,
  resetPagination,
  setSortingColumnName,
  resetSortingColumnName,
  setSortingDirection,
  resetSortingDirection,
} from "../state/slices/pdfFiltersSlice";
import { NotifyStepSettings, bindAction } from "../../../../interfaces";
import {
  fetchDraftPdfEntity as fetchDraftPdfEntityAction,
  publishPdf as publishPdfAction,
} from "../state/actions/pdfEntityStateActions";
import { reset } from "../state/slices/pdfOverviewSlice";
import { resetTags } from "../state/slices/pdfBaseSlice";
import { PdfOverviewHeader } from "./PdfOverviewHeader/PdfOverviewHeader";
import { CreatePdfButton } from "./PdfOverviewHeader/CreatePdfButton";
import { CardsViewerItem } from "../../../../components/cardsViewer/types";
import { PdfOverviewCard } from "./Card/PdfOverviewCard";
import { PdfsFilterForm } from "../../../../components/filterForms";
import { DEFAULT_SORTING_COLUMN } from "../types/constants";
import { PdfModalsContainer } from "../common/PdfModalsContainer";
import { useRtn } from "../../../../hooks/useRtn";
import { ModalHandlers, PdfPublishConfirmationParams } from "../types/models";
import { HandleOnSelectionChanged, SelectionChangedArgs } from "../../../../interfaces/onSelectionChanged";
import {
  PdfDiscardSuccess,
  PdfLockSuccess,
  PdfPublishSuccess,
} from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { useNavigate } from "react-router-dom";
import { useSearchSorting } from "components/listView/hooks/useSearchSorting";
import { DEFAULT_FETCH_GRID_TOP } from "components/contentAssignment/pdfsAssignmentList/constants";
import Observable from "utils/Observable";
import { AssetVisibilityConfirmationModal } from "components/assetVisibilityConfirmationModal/AssetVisibilityConfirmationModal";

export type PdfsOverviewProps = PropsFromRedux;

// Handling conversion from redux filter name to API filter param
const sortChangeNameMap = {
  modified: SortOptions.ModifiedDateDesc,
  added: SortOptions.CreatedDateDesc,
  title: SortOptions.Title,
  "": "",
} as const;

const publishOrDiscardRtns = [PdfPublishSuccess, PdfDiscardSuccess];
const lockRtns = [PdfLockSuccess];
type UnparsedColumnName = keyof typeof sortChangeNameMap;

export const Pdfs: FC<PdfsOverviewProps> = (props) => {
  const navigate = useNavigate();
  const { updatePdf, publishPdf, deletePdfs, resetOverviewPdfs, fetchOverviewPdfs, fetchDraftPdfEntity } = props;
  const [selectedPdfIds, setSelectedPdfIds] = useState<number[]>([]);
  const [selectedViewType, setSelectedViewType] = useState<ViewType>(ViewType.GRID);
  const [purchasedSelectedCount, setPurchasedSelectedCount] = useState(0);
  const [undeletableSelectedCount, setUndeletableSelectedCount] = useState(0);
  const [hiddenSelectedCount, setHiddenSelectedCount] = useState(0);
  const [visibleSelectedCount, setVisibleSelectedCount] = useState(0);
  const [draftSelectedCount, setDraftSelectedCount] = useState(0);
  // Used to handle resetting sort terms
  const { sortingColumn, onSearchChange: sortingSearchChange } = useSearchSorting({
    sortingColumnName: props.sortingColumnName,
    defaultSortingColumn: DEFAULT_SORTING_COLUMN,
  });

  const reloadListItemsRef = useRef<((enableSorting: boolean) => void) | undefined>(undefined);
  const modalHandlers = useRef<ModalHandlers>({ delete: () => noop, publish: () => noop, revert: () => noop });
  const isGridView = useMemo(() => selectedViewType === ViewType.GRID, [selectedViewType]);

  /**
   * Handles conversion from sort name ("modified") to
   * an actual valid sort parameter "DateModified desc"
   */
  const parsedSortParameters = useMemo<string>(() => {
    const defaultColumn: UnparsedColumnName = props.sortingColumnName || "added";
    return sortChangeNameMap[defaultColumn];
  }, [props.sortingColumnName]);

  useEffect(() => {
    return () => {
      resetOverviewPdfs();
      if (!window.location.pathname.includes(`/${RouteNames.contentPdfs}`)) props.resetSearchFilters();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePdfCard = useCallback(
    (payload: { id: number; }) => {
      updatePdf(payload.id);
    },
    [updatePdf],
  );

  const goToPage = useCallback(
    (id: number, pageName: string) => {
      navigate(`${id}/${pageName}`);
    },
    [navigate],
  );

  const goToEditEntity = useCallback((payload: { id: number; }) => goToPage(payload.id, "configuration"), [goToPage]);

  useRtn(publishOrDiscardRtns, updatePdfCard);
  useRtn(lockRtns, goToEditEntity);

  const handleEditClick = useCallback(
    (id: number, isDraft: boolean) => {
      isDraft ? goToPage(id, "configuration") : fetchDraftPdfEntity(id);
    },
    [goToPage, fetchDraftPdfEntity],
  );

  const handlePublishClick = useCallback((params: PdfPublishConfirmationParams) => {
    modalHandlers.current.publish(params);
  }, []);

  const handleDeleteClick = useCallback((ids: number[], flowsCount?: number, packsCount?: number) => {
    modalHandlers.current.delete(ids, flowsCount, packsCount);
  }, []);

  const loadPdfs = (skip?: number, top?: number, sortingColumnName?: string, sortDirection?: SortingDirection) => {
    // Manages if a search is active
    const pdfCardSort = sortingColumn ? parsedSortParameters : "";
    isGridView
      ? loadPdfCards(props.pdfs.length, pdfCardSort)
      : loadPdfList(skip, top, sortingColumnName, sortDirection);
  };

  const loadPdfList = (skip?: number, top?: number, sortingColumnName?: string, sortDirection?: SortingDirection) => {
    fetchOverviewPdfs({
      skip: skip,
      top: top,
      sortBy: pdfListUtils.getSortBy(sortingColumnName as Columns),
      sortDirection: sortDirection,
    });
  };

  const loadPdfCards = useCallback(
    (skip?: number, sortByParams?: string) => {
      const [sortBy, sortDirection] = sortByParams?.split(" ") || [undefined, undefined];
      fetchOverviewPdfs({
        skip: skip,
        top: DEFAULT_FETCH_GRID_TOP,
        sortBy: sortBy,
        sortDirection: sortDirection,
        append: true,
      });
    },
    [fetchOverviewPdfs],
  );

  const onSortChange = async (_: React.SyntheticEvent<HTMLElement>, data: { value?: SortOptions; }) => {
    resetOverviewPdfs();
    clearSelection();

    const gridViewSort = {
      [SortOptions.ModifiedDateDesc]: () => onSortingColumnNameChange("modified"),
      [SortOptions.CreatedDateDesc]: () => onSortingColumnNameChange("added"),
      [SortOptions.Title]: () => onSortingColumnNameChange("title"),
    };
    // Updates sort by prop
    gridViewSort[data.value as keyof typeof gridViewSort]();
    loadPdfCards(0, data.value);
  };

  const applyFilter = (filter: Filters) => {
    clearSelection();
    props.applyFilter(filter);
    if (isGridView) {
      // Manages if a search is active
      const pdfCardSort = sortingColumn ? parsedSortParameters : "";
      resetOverviewPdfs();
      loadPdfCards(0, pdfCardSort);
    }
  };

  const resetFilter = () => {
    clearSelection();
    props.resetFilter();
    if (isGridView) {
      resetOverviewPdfs();
      loadPdfCards();
    }
  };

  const clearSelection = () => {
    setSelectedPdfIds([]);
    setPurchasedSelectedCount(0);
    setUndeletableSelectedCount(0);
    setHiddenSelectedCount(0);
    setVisibleSelectedCount(0);
    setDraftSelectedCount(0);
  };

   /* istanbul ignore next */
  const onSelectionChanged = (args: SelectionChangedArgs<PdfOverview>) => {
    let purchasedCount = 0;
    let undeletableCount = 0;
    let draftCount = 0;
    let hiddenCount = 0;
    let visibleCount = 0;

    const onAdded = (pdf: PdfOverview) => {
      pdf.isPurchased && ++purchasedCount;
      !pdf.canBeDeleted && ++undeletableCount;
      pdf.isDraft && ++draftCount;
      !pdf.visibility && ++hiddenCount;
      pdf.visibility && ++visibleCount;
    };
    const onRemoved = (pdf: PdfOverview) => {
      pdf.isPurchased && --purchasedCount;
      !pdf.canBeDeleted && --undeletableCount;
      pdf.isDraft && --draftCount;
      !pdf.visibility && --hiddenCount;
      pdf.visibility && --visibleCount;
    };

    HandleOnSelectionChanged(args, onAdded, onRemoved, clearSelection);

    setPurchasedSelectedCount(purchasedSelectedCount + purchasedCount);
    setUndeletableSelectedCount(undeletableSelectedCount + undeletableCount);
    setDraftSelectedCount(draftSelectedCount + draftCount);
    setHiddenSelectedCount(hiddenSelectedCount + hiddenCount);
    setVisibleSelectedCount(visibleSelectedCount + visibleCount);
  };

  const onViewTypeChange = (viewType: ViewType) => {
    resetOverviewPdfs();
    setSelectedViewType(viewType);
    clearSelection();
    if (viewType === ViewType.GRID) {
      loadPdfCards(0, parsedSortParameters);
    }
  };

  const createReloadListMethod = (reloadListItems: (enableSorting: boolean) => void) => {
    reloadListItemsRef.current = reloadListItems;
  };

  const onSearchChange = (search: string) => {
    const sortBy = search ? "" : parsedSortParameters;
    props.setSearch(search);
    sortingSearchChange(search);
    refetchItemsFromStart({ search, sortBy });
  };

  const onPaginationChange = (page: number) => props.setPagination(page);
  const onSortingColumnNameChange = (name: string) => props.setSortingColumnName(name);
  const onSortingDirectionChange = (direction: SortingDirection) => props.setSortingDirection(direction);

  const refetchItemsFromStart = useCallback(
    ({ search, sortBy }: { search?: string; sortBy?: string; }) => {
      if (isGridView) {
        resetOverviewPdfs();
        loadPdfCards(0, sortBy);
      } else {
        // @ts-ignore
        reloadListItemsRef.current?.(isEmpty(search));
      }
    },
    [resetOverviewPdfs, isGridView, loadPdfCards],
  );

  const onPublishConfirm = useCallback(
    (id: number, notificationSettings?: NotifyStepSettings, notifyTypes?: number[]) => {
      publishPdf(id, notificationSettings, notifyTypes);
    },
    [publishPdf],
  );

  const onDeleteConfirm = useCallback(
    (ids: number[]) => {
      // Manages if a search is active
      const pdfCardSort = sortingColumn ? parsedSortParameters : "";
      deletePdfs(ids, () => refetchItemsFromStart({ sortBy: pdfCardSort, search: props.search }));
      clearSelection();
    },
    [deletePdfs, refetchItemsFromStart, sortingColumn, parsedSortParameters, props.search],
  );

  const publishModal = useMemo(() => {
    return {
      acceptHandler: (handler: (params: PdfPublishConfirmationParams) => void) =>
        (modalHandlers.current.publish = handler),
      onConfirm: onPublishConfirm,
    };
  }, [onPublishConfirm]);

  const deleteModal = useMemo(() => {
    return {
      acceptHandler: (handler: (ids: number[]) => void) => (modalHandlers.current.delete = handler),
      onConfirm: onDeleteConfirm,
    };
  }, [onDeleteConfirm]);

  const onTriggerChangeVisibilityObserver = useMemo(
    () => new Observable<(onConfirm: () => void, visible: boolean, ids: number[]) => void>(),
    [],
  );

  const handleToggleVisibility = (ids: number[], visible: boolean) => () => {
    const pdfCardSort = sortingColumn ? parsedSortParameters : "";
    onTriggerChangeVisibilityObserver.notify(
      () => {
        props.toggleVisibility(ids, visible, () => { clearSelection(); refetchItemsFromStart({ sortBy: pdfCardSort, search: props.search })});
      },
      visible,
      ids,
    );
  };

  const hideDisabled = !props.userPermissions.includes(RolePermissions.AssetsCreate);

  return (
    <section className="nested-content pdfs">
      <PdfOverviewHeader
        purchasedSelected={purchasedSelectedCount > 0}
        undeletableSelected={undeletableSelectedCount > 0}
        hiddenSelected={hiddenSelectedCount > 0}
        visibleSelected={visibleSelectedCount > 0}
        draftSelected={draftSelectedCount > 0}
        selectedIds={selectedPdfIds}
        pdfDeletionHandler={modalHandlers.current.delete}
        clearSelection={clearSelection}
        onToggleVisibility={handleToggleVisibility}
      />
      <PdfModalsContainer publishModal={publishModal} deleteModal={deleteModal} />
      <Restricted
        permissions={[RolePermissions.AssetsCreate, RolePermissions.AssetsManage]}
        renderContent={(hasAnyPermission) => (
          <PdfsContainer
            blur
            items={props.pdfs}
            viewType={selectedViewType}
            itemsType={ItemsTypes.Pdf}
            sortOptions={sortOptions}
            onSortChange={onSortChange}
            fetchData={loadPdfs}
            isLoading={props.changingEntityState || props.arePdfsLoading}
            // @ts-ignore
            getFilterForm={() => <PdfsFilterForm />}
            resetFilter={resetFilter}
            applyFilter={applyFilter}
            onSelectionChanged={onSelectionChanged}
            onSelectedGridItemsChanged={setSelectedPdfIds}
            onSelectedItemChanged={setSelectedPdfIds}
            selectedIds={selectedPdfIds}
            renderCard={(props: CardsViewerItem<PdfOverview>) => {
              return (
                <PdfOverviewCard
                  {...props}
                  handleEditClick={handleEditClick}
                  handlePublishClick={handlePublishClick}
                  handleDeleteClick={handleDeleteClick}
                  onToggleVisibility={handleToggleVisibility}
                  readonly={!hasAnyPermission}
                  hideDisabled={hideDisabled}
                />
              );
            }}
            onViewTypeChange={onViewTypeChange}
            orderBy={sortingColumn ? parsedSortParameters : ""}
            sortingDirection={props.sortingDirection || SortingDirection.Descending}
            defaultSortingColumnName={sortingColumn}
            onLoad={isGridView ? loadPdfs : undefined}
            isSelectDisabled={() => !hasAnyPermission}
            setReloadListItems={createReloadListMethod}
            columnOptions={getColumnOptions({
              handleEditClick: handleEditClick,
              handlePublishClick: handlePublishClick,
              handleDeleteClick: handleDeleteClick,
              onToggleVisibility: handleToggleVisibility,
              readonly: !hasAnyPermission,
              hideDisabled: hideDisabled
            })}
            renderSearch={() => (
              <SearchInput placeholder="Search for PDFs..." onChange={onSearchChange} defaultValue={props.search} />
            )}
            noResultsContent={
              <PdfsNoResults filtered={props.filtered || !isEmpty(props.search)} createButton={<CreatePdfButton />} />
            }
            permissions={[RolePermissions.AssetsManage]}
            setPagination={onPaginationChange}
            pagination={props.pagination}
            setSortingColumnName={onSortingColumnNameChange}
            setSortingDirection={onSortingDirectionChange}
          />
        )}
      />
      <AssetVisibilityConfirmationModal observer={onTriggerChangeVisibilityObserver} assetType="PDF" />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const pdfsState = pdfsStateSelector(state);
  const base = pdfsState.base;
  const overview = pdfsState.overview;

  return {
    pdfs: overview.pdfs.items,
    search: pdfSearchSelector(state),
    pagination: pdfPaginationSelector(state),
    sortingColumnName: pdfSortingColumnNameSelector(state) as UnparsedColumnName | undefined,
    sortingDirection: pdfSortingDirectionSelector(state),
    arePdfsLoading: overview.pdfs.isLoading,
    filtered: !isEmpty(overview.filters.appliedFilter),
    entityId: base.pdfEntityStateReducer.entityId,
    changingEntityState: base.pdfEntityStateReducer.changingEntityState,
    normalizedPdfs: normalizedPdfsSelector(state),
    userPermissions: state.userProfile.permissions,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchOverviewPdfs: bindAction(fetchOverviewPdfsAction, dispatch),
    setSearch: bindAction(setSearch, dispatch),
    setPagination: bindAction(setPagination, dispatch),
    setSortingColumnName: bindAction(setSortingColumnName, dispatch),
    setSortingDirection: bindAction(setSortingDirection, dispatch),
    fetchDraftPdfEntity: bindAction(fetchDraftPdfEntityAction, dispatch),
    publishPdf: bindAction(publishPdfAction, dispatch),
    deletePdfs: bindAction(deletePdfsAction, dispatch),
    resetOverviewPdfs: bindAction(reset, dispatch),
    updatePdf: bindAction(updatePdfAction, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetFilter: bindAction(resetAppliedFilter, dispatch),
    toggleVisibility: bindAction(changeVisibility, dispatch),

    resetSearchFilters: () => {
      batch(() => {
        dispatch(reset());
        dispatch(resetTags());
        dispatch(resetSearch());
        dispatch(resetPagination());
        dispatch(resetAppliedFilter());
        dispatch(resetSortingColumnName());
        dispatch(resetSortingDirection());
      });
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Pdfs);
