import axios from "axios";
import {
  BasePerformanceRequestFilterParams,
  UserPerformanceRequestFilterWithPeriodParams,
} from "features/Library/Common/models";
import {
  PeopleOverviewAccountsItem,
  PeopleActivityDetails,
  PeopleInteractionItem,
  PeopleInteractionStatistics,
  PeopleLineChart,
  PeopleOutreachItem,
  PeopleOutreachStatistics,
  PeopleOverviewLineChart,
  PeopleStatistics,
  UsersLineChart,
  UsersTable,
  PeopleOverviewUsersItem,
  PeopleOverviewStatistics,
  AcquisitionLineChart,
  AcquisitionEngagementChart,
  AcquisitionEngagementCards,
  AcquisitionCampaign,
} from "../types/people";
import { serializeArrayAndFilterNullable } from "utils/queryUtils";
import { PerformanceWithAccountFilter } from "features/Library/Common/utils/performanceUtils";

export const OVERVIEW_LINE_CHART_ENDPOINT = "/api/reports/v3/users/overview/linechart";
export const OVERVIEW_STATISTICS_ENDPOINT = "/api/reports/v3/users/overview/engagement";
export const OVERVIEW_ACCOUNTS_TABLE_ENDPOINT = "/api/reports/v3/users/overview/accounts";
export const OVERVIEW_USERS_TABLE_ENDPOINT = "/api/reports/v3/users/overview/users";

export const ACTIVITY_LINE_CHART_ENDPOINT = "/api/reports/v2/users/activity/linechart";
export const INTERACTION_STATISTICS_ENDPOINT = "/api/reports/v2/users/interaction/statistics";
export const INTERACTION_TABLE_ENDPOINT = "/api/reports/v2/users/interaction/table";
export const OUTREACH_STATISTICS_ENDPOINT = "/api/reports/v2/users/outreach/statistics";
export const OUTREACH_TABLE_ENDPOINT = "/api/reports/v2/users/outreach/table";
export const ACTIVITY_DETAILS_ENDPOINT = "/api/reports/v2/users/activity/details";
export const OUTREACH_DETAILS_ENDPOINT = "/api/reports/v2/users/outreach/details";
export const INTERACTION_DETAILS_ENDPOINT = "/api/reports/v2/users/interaction/details";

export const USERS_LINE_CHART_ENDPOINT = "/api/reports/v2/users/linechart";
export const USERS_STATISTICS_ENDPOINT = "/api/reports/v2/users/statistics";
export const USERS_TABLE_ENDPOINT = "/api/reports/v2/users/table";

export const ACTIVITY_EXPORT_ENDPOINT = "/api/dataexport/v2/users/activity";
export const OUTREACH_DETAILS_EXPORT_ENDPOINT = "/api/dataexport/v2/users/outreach/details";
export const INTERACTION_DETAILS_EXPORT_ENDPOINT = "/api/dataexport/v2/users/interaction/details";

export const ACQUISITION_LINE_CHART_ENDPOINT = "/api/reports/v1/acquisition/linechart";
export const ACQUISITION_ENGAGEMENT_CHART_ENDPOINT = "/api/reports/v1/acquisition/engagement-chart";
export const ACQUISITION_ENGAGEMENT_CARDS_ENDPOINT = "/api/reports/v1/acquisition/engagement-cards";
export const ACQUISITION_CAMPAIGNS_ENDPOINT = "/api/reports/v1/overview/acquisition/campaigns";
export const ACQUISITION_EXPORT_ENDPOINT = "/api/dataexport/v1/overview/acquisition/performance";

type DetailsFilterParams = BasePerformanceRequestFilterParams & { type: string };

export const peopleReportingService = {
  activity: {
    getLineChart: (params: UserPerformanceRequestFilterWithPeriodParams, signal: AbortSignal) =>
      axios.get<PeopleLineChart[]>(ACTIVITY_LINE_CHART_ENDPOINT, { params, signal }),
    getActivityExport: (params: BasePerformanceRequestFilterParams) =>
      axios.get(ACTIVITY_EXPORT_ENDPOINT, {
        responseType: "blob",
        params,
      }),
    getPeopleInteractionStatistics: (params: BasePerformanceRequestFilterParams) =>
      axios.get<PeopleInteractionStatistics>(INTERACTION_STATISTICS_ENDPOINT, { params }),
    getPeopleInteractionTable: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<PeopleInteractionItem[]>(INTERACTION_TABLE_ENDPOINT, { params, signal }),
    getPeopleOutreachStatistics: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<PeopleOutreachStatistics>(OUTREACH_STATISTICS_ENDPOINT, { params, signal }),
    getPeopleOutreachTable: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<PeopleOutreachItem[]>(OUTREACH_TABLE_ENDPOINT, { params, signal }),
    getPeopleOutreachDetails: (params: DetailsFilterParams) =>
      axios.get<PeopleActivityDetails[]>(OUTREACH_DETAILS_ENDPOINT, { params }),
    getPeopleInteractionDetails: (params: DetailsFilterParams) =>
      axios.get<PeopleActivityDetails[]>(INTERACTION_DETAILS_ENDPOINT, { params }),
    getPeopleInteractionExport: (params: DetailsFilterParams) =>
      axios.get(INTERACTION_DETAILS_EXPORT_ENDPOINT, {
        responseType: "blob",
        params,
      }),
    getPeopleOutreachExport: (params: DetailsFilterParams) =>
      axios.get(OUTREACH_DETAILS_EXPORT_ENDPOINT, {
        responseType: "blob",
        params,
      }),
  },
  acquisition: {
    getLineChart: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<AcquisitionLineChart[]>(ACQUISITION_LINE_CHART_ENDPOINT, {
        params,
        signal,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
    getEngagementChart: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<AcquisitionEngagementChart[]>(ACQUISITION_ENGAGEMENT_CHART_ENDPOINT, {
        params,
        signal,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
    getEngagementCards: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<AcquisitionEngagementCards>(ACQUISITION_ENGAGEMENT_CARDS_ENDPOINT, {
        params,
        signal,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
    getCampaigns: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<AcquisitionCampaign[]>(ACQUISITION_CAMPAIGNS_ENDPOINT, {
        params,
        signal,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
    getAcquisitionExport: (params: BasePerformanceRequestFilterParams) =>
      axios.get(ACQUISITION_EXPORT_ENDPOINT, {
        responseType: "blob",
        params,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
  },
  users: {
    getLineChart: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<UsersLineChart[]>(USERS_LINE_CHART_ENDPOINT, { params, signal }),
    getStatistics: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<PeopleStatistics>(USERS_STATISTICS_ENDPOINT, { params, signal }),
    getTable: (params: BasePerformanceRequestFilterParams, signal: AbortSignal) =>
      axios.get<UsersTable[]>(USERS_TABLE_ENDPOINT, { params, signal }),
  },
  overview: {
    getLineChart: (params: PerformanceWithAccountFilter, signal: AbortSignal) =>
      axios.get<PeopleOverviewLineChart[]>(OVERVIEW_LINE_CHART_ENDPOINT, {
        params,
        signal,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
    getStatistics: (params: PerformanceWithAccountFilter, signal: AbortSignal) =>
      axios.get<PeopleOverviewStatistics>(OVERVIEW_STATISTICS_ENDPOINT, {
        params,
        signal,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
    getAccountsTable: (params: PerformanceWithAccountFilter, signal: AbortSignal) =>
      axios.get<PeopleOverviewAccountsItem[]>(OVERVIEW_ACCOUNTS_TABLE_ENDPOINT, {
        params,
        signal,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
    getUsersTable: (params: PerformanceWithAccountFilter, signal: AbortSignal) =>
      axios.get<PeopleOverviewUsersItem[]>(OVERVIEW_USERS_TABLE_ENDPOINT, {
        params,
        signal,
        paramsSerializer: serializeArrayAndFilterNullable,
      }),
  },
} as const;
