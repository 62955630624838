import { StatusCodes } from "enums";
import { AppDispatch } from "features/Application/globaltypes/redux";
import RtnEventsEmitter from "features/Application/services/realTimeNotification/rtnEventsEmitter";
import enrollmentRulesUtils from "utils/enrollmentRulesUtils";
import dataService, { DataService } from "../../../../Application/services/dataServices/typedDataService";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/people/peopleEvents";
import {
  setAccessAuthorized,
  setConfiguration,
  setEnrollmentRules,
  setGroupId,
  setIsLoading,
  setIsPreview,
  setTemplateUsersOnly,
  TemplateConfigurationView,
} from "../GroupTemplateSlice";

export const fetchTemplate = (templateId: number, isPreview?: boolean) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));
  try {
    const result = await DataService.groupTemplates.getTemplateInfo(templateId, isPreview);
    const { enrollmentRules, ...configuration } = result.data;
    dispatch(setConfiguration(configuration));
    dispatch(setEnrollmentRules(enrollmentRulesUtils.mapRulesToLocalFormat(enrollmentRules)!));
    dispatch(setTemplateUsersOnly(configuration.templateUsersOnly));
    dispatch(setGroupId(templateId));
    dispatch(setIsPreview(configuration.isPreview));
  } catch (e) {
    if (e?.response?.status === StatusCodes.Forbidden) {
      setAccessAuthorized(false);
    }
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const saveTemplate = (id: number, configuration: TemplateConfigurationView) => async (dispatch: AppDispatch) => {
  const rtnTimeout = 40000;
  try {
    dispatch(setIsLoading(true));
    if (id > 0) {
      await DataService.groupTemplates.updateTemplate(id, configuration);
      await RtnEventsEmitter.subscribeOnceAndWait(rtnEvents.GroupInfoUpdated, rtnTimeout);
    } else {
      await dataService.groupTemplates.createTemplate(configuration);
      const createdTemplate = await RtnEventsEmitter.subscribeOnceAndWait(rtnEvents.GroupCreated, rtnTimeout);
      dispatch(setGroupId(createdTemplate.GroupId));
    }
    dispatch(setConfiguration(configuration));
  } catch (error) {}
  dispatch(setIsLoading(false));
};
