import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";

import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";
import { AccountType, PerformanceWithAccountFilter } from "../../../../../Common/utils/performanceUtils";
import { selectSendDetailsData, selectSendDetailsState } from "../../../state/slices/emailPerformanceSlice";
import { EmailSendDetailStatusToColor, EmailSendDetailStatusToIcon, EmailSendDetailStatusToString } from "./constants";

import "../../../../../Common/utils/performanceSCSSUtils.scss";
import "../emailPerformance.scss";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RouteNames } from "enums";
import { ColumnOption } from "interfaces/columnOptions";
import moboConfigurator from "moboConfigurator";
import { Link } from "react-router-dom";
import dateTimeUtils from "utils/dateTimeUtils";
import { EmailSendDetailEntity } from "../types";

import styles from "./statuses.module.scss";
import { fetchEmailSendDetails } from "../../../state/thunks/emailPerformanceThunk";

export interface Props extends PropsFromRedux {
  emailId: number;
  sendSessionId: number;
  flowId?: number;
  dateFilter: PerformanceWithAccountFilter & { type: AccountType };
}

const getColumnOptions = (filter: PerformanceWithAccountFilter): ColumnOption<EmailSendDetailEntity>[] => [
  {
    name: "Name",
    width: 4,
    isSortable: false,
    render: (entity) => {
      const useLink = !filter.showCustomers && !filter.accounts?.length;
      const userName = (
        <Tooltip target={<TextTruncate>{entity.UserFullName}</TextTruncate>} content={entity.UserFullName} />
      );
      return (
        <div className="emailListClamp">
          {useLink ? (
            <Link
              to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.peopleUsers}/${entity.UserId}`)}
              className={styles.linkButton}
            >
              {userName}
            </Link>
          ) : (
            userName
          )}
        </div>
      );
    },
  },
  {
    name: "Email",
    width: 3,
    isSortable: false,
    render: (entity) => (
      <div className="emailListClamp">
        <Tooltip target={<TextTruncate>{entity.UserEmail}</TextTruncate>} content={entity.UserEmail} />
      </div>
    ),
  },
  {
    name: "Send date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.SendDate),
  },
  {
    name: "Open date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.OpenDate),
  },
  {
    name: "Click date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.ClickDate),
  },
  {
    name: "Status",
    width: 3,
    isSortable: false,
    render: (entity) => (
      <div className="emailSendStatusIcon">
        <FontAwesomeIcon
          icon={EmailSendDetailStatusToIcon[entity.Status]}
          className={`${styles[EmailSendDetailStatusToColor[entity.Status]]}`}
        />
        &nbsp;
        {EmailSendDetailStatusToString[entity.Status]}
      </div>
    ),
  },
];

export const EmailPerformanceDrilldown: FC<Props> = ({
  emailId,
  sendSessionId,
  flowId,
  dateFilter,
  sendDetailsState,
  sendDetailsData,
  setHasExportData,
  fetchEmailSendDetails,
}) => {
  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  useEffect(() => {
    setHasExportData(sendDetailsState.status === RStatus.Got && !!sendDetailsData.length);
  }, [sendDetailsData, setHasExportData, sendDetailsState.status]);

  useEffect(() => {
    if (reportEnabled) {
      fetchEmailSendDetails(emailId, sendSessionId, { ...dateFilter, flowId });
    }
  }, [fetchEmailSendDetails, emailId, dateFilter, flowId, sendSessionId, reportEnabled]);

  if (!reportEnabled) return null;

  return (
    <div>
      <RequestStatusRenderer state={sendDetailsState}>
        <GenericPerformanceList
          rows={sendDetailsData}
          columns={getColumnOptions(dateFilter)}
          title="People"
          filter={dateFilter}
        />
      </RequestStatusRenderer>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    sendDetailsState: selectSendDetailsState(state),
    sendDetailsData: selectSendDetailsData(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchEmailSendDetails: bindAction(fetchEmailSendDetails, dispatch),
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmailPerformanceDrilldown);
