import axios, { AxiosResponse } from "axios";
import {
  Customer,
  GetAvailableCustomersRequest,
  GetTemplateInfoResponse,
  TemplateOverviewResponse,
  Pack,
  TemplatePackAccounts,
  GetPacksRequest,
  AccountPack,
  PackLicenseInfo,
  AvailableTemplateResponse,
  GetAvailableTemplatesRequest,
  TemplatePacks,
} from "./types";
import { isEmpty } from "lodash";
import { PageOfItems } from "interfaces";
import { TemplateConfigurationView } from "../state/GroupTemplateSlice";
import { DistributedOperationResult } from "types";
import { GetPagedDataRequestV2 } from "interfaces/getPagedDataRequest";
import KnownHttpHeaders from "features/Application/services/knownHttpHeaders";
import { ContentResponse } from "features/Application/services/dataServices/contentDataService";
import { buildQueryFromArray, serializeArrayAndFilterNullable } from "utils/queryUtils";
import { EnrollmentRule } from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/utils";

export const groupTemplateDataService = {
  async getInheritedGroups(request: GetPagedDataRequestV2) {
    const { data, headers } = await axios.get<TemplateOverviewResponse[]>("/api/template-groups", {
      params: request,
    });
    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },
  async getTemplates(request: GetPagedDataRequestV2) {
    const { data, headers } = await axios.get<TemplateOverviewResponse[]>("/api/templates", {
      params: request,
    });
    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },
  async getAvailableTemplates(request: GetAvailableTemplatesRequest) {
    const { data, headers } = await axios.get<AvailableTemplateResponse[]>("/api/template-groups/available", {
      params: request,
    });
    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },
  getTemplateInfo(templateId: number, isPreview?: boolean) {
    return axios.get<GetTemplateInfoResponse>(
      isPreview ? `/api/templates/${templateId}/preview` : `/api/templates/${templateId}/info`,
    );
  },
  updateTemplate(templateId: number, data: TemplateConfigurationView) {
    return axios.put(`/api/template-groups/${templateId}/info`, {
      ...data,
      isTemplate: true,
    });
  },
  createTemplate(data: TemplateConfigurationView) {
    return axios.post("/api/template-groups", {
      ...data,
      isTemplate: true,
    });
  },
  deleteTemplate(id: number) {
    return axios.delete(`api/template-groups/${id}`);
  },
  updateTemplateEnrollmentRules(
    groupId: number,
    enrollmentRules: EnrollmentRule[],
    templateUsersOnly?: boolean | null,
  ) {
    return axios.put(`/api/template-groups/${groupId}/rules`, {
      rules: enrollmentRules,
      templateUsersOnly,
    });
  },
  getAvailableCustomers(request: GetAvailableCustomersRequest) {
    return axios.get<PageOfItems<Customer>>("/api/accounts/customers", {
      params: {
        top: request.top,
        skip: request.skip,
        sortBy: request.sortBy,
        sortOrder: request.sortOrder,
        searchTerm: !isEmpty(request.term) ? request.term : undefined,
        templateId: request.templateId,
      },
    });
  },
  getCustomers(id: number, request: GetPagedDataRequestV2) {
    return axios.get<PageOfItems<Customer>>(`/api/template-groups/${id}/accounts`, {
      params: {
        top: request.top,
        skip: request.skip,
        sortBy: request.sortBy,
        sortOrder: request.sortOrder,
        searchTerm: !isEmpty(request.term) ? request.term : undefined,
      },
    });
  },
  saveCustomers(templateId: number, customers: number[]) {
    return axios.post(`/api/template-groups/${templateId}/accounts`, {
      accountIds: customers,
    });
  },
  removeCustomers(templateId: number, customers: number[]) {
    return axios.delete(`/api/template-groups/${templateId}/accounts`, {
      data: {
        accountIds: customers,
      },
    });
  },
  getFlows(templateId: number, request: GetPagedDataRequestV2) {
    return axios.get<ContentResponse[]>(`/api/template-groups/${templateId}/flows/preview`, {
      params: {
        ...request,
        term: !isEmpty(request.term) ? request.term : undefined,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },
  getContent(templateId: number, type: string, request: GetPagedDataRequestV2) {
    return axios.get<ContentResponse[]>(`/api/template-groups/${templateId}/content`, {
      params: {
        ...request,
        term: !isEmpty(request.term) ? request.term : undefined,
        showPurshased: true,
        contentType: type,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },
  getAvailablePacks(id: number, request: GetPacksRequest, signal?: AbortSignal): Promise<AxiosResponse<Pack[]>> {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: signal,
    };

    return axios.get<Pack[]>(`/api/template-groups/${id}/available-packs`, config);
  },
  getPacks(id: number, request: GetPagedDataRequestV2) {
    return axios.get<Pack[]>(`/api/template-groups/${id}/packs`, {
      params: {
        top: request.top,
        skip: request.skip,
        sortBy: request.sortBy,
        sortOrder: request.sortOrder,
      },
    });
  },
  async savePacks(templateId: number, groupPacks: TemplatePackAccounts[]): Promise<DistributedOperationResult> {
    const response = await axios.post(`/api/template-groups/${templateId}/packs`, { templateGroupPacks: groupPacks });
    return response.data;
  },
  async removePacks(templateId: number, packIds: number[]): Promise<DistributedOperationResult> {
    const response = await axios.delete(`/api/template-groups/${templateId}/packs`, { data: { packIds: packIds } });
    return response.data;
  },

  approve(templateId: number) {
    return axios.post(`/api/template-groups/${templateId}/approve`);
  },

  decline(templateId: number) {
    return axios.post(`/api/template-groups/${templateId}/decline`);
  },
  getAccountPacks(templateId: number, accountId: number) {
    return axios.get<AccountPack[]>(`/api/template-groups/${templateId}/accounts/${accountId}/packs`);
  },
  async saveAccountPacks(
    templateId: number,
    accountId: number,
    packs: PackLicenseInfo[],
  ): Promise<DistributedOperationResult> {
    const response = await axios.put(`/api/template-groups/${templateId}/accounts/${accountId}/packs`, {
      packs: packs,
    });
    return response.data;
  },
  async savePackAccounts(
    templateId: number,
    packId: number,
    expirationDate: Date,
  ): Promise<DistributedOperationResult> {
    const response = await axios.put(`/api/template-groups/${templateId}/packs/${packId}/accounts`, {
      expirationDate,
    });
    return response.data;
  },
  getPacksWithTrialInfo(templateId: number) {
    return axios.get<Pack[]>(`/api/template-groups/${templateId}/packs-trial-info`);
  },
  getTemplatesPacks(templateIds: number[]) {
    const query = buildQueryFromArray("templateIds", templateIds);
    return axios.get<AccountPack[]>(`/api/template-groups/packs?${query}`);
  },
  async saveTemplatesPacks(accountId: number, templatesPacks: TemplatePacks[]): Promise<DistributedOperationResult> {
    const response = await axios.post(`/api/template-groups/accounts/${accountId}/packs`, {
      templatesPacks: templatesPacks,
    });
    return response.data;
  },
};
