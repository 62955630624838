import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { get, isEqual } from "lodash";

import { ValidatedForm } from "../../../../../../../components/forms";
import { ErrorPosition } from "../../../../../../../components/forms/ValidatedField";
import { SessionPayload, SessionView } from "../../../../types/state";
import { CommonSessionBuilderProps } from "./SingleSessionBuilder";
import { getPropName } from "../../Utils/getSessionPropName";

import "./singleSessionBuilderRow.scss";
import { TimezoneDropdown } from "features/Library/Common/Fields/timezone-dropdown/TimezoneDropdown";

export interface SingleSessionBuilderRowProps extends CommonSessionBuilderProps {
  session: SessionView;
  index: number;
  onRemove: (id: number) => void;
  hideRemove?: boolean;
}

export const SingleSessionBuilderRow = (props: SingleSessionBuilderRowProps) => {
  const { session, validatedFieldProps, index, disabled, onRemove, hideRemove, onBlur } = props;
  const { touched, ...otherValidatedProps } = validatedFieldProps;

  return (
    <React.Fragment key={index}>
      <div className="single-session-builder-row">
        <Grid stackable columns="equal" verticalAlign="bottom" className="overridden-bottom-margin">
          <Grid.Column className="session-url-input">
            <ValidatedForm.InputField
              label="Event URL"
              value={session.webinarUrl}
              propertyName={getPropName<SessionView>("webinarUrl", index)}
              markAsRequired
              disabled={disabled}
              errorPosition="bottom"
              {...validatedFieldProps}
              onBlur={onBlur}
            />
          </Grid.Column>
          <Grid.Column className="session-detail-inputs">
            <ValidatedForm.DatePickerField
              label="Start date"
              selected={session.startDate || ""}
              propertyName={getPropName<SessionView>("startDate", index)}
              disabled={disabled}
              errorPosition={ErrorPosition.bottom}
              markAsRequired
              {...validatedFieldProps}
              onBlur={() => {
                onBlur(getPropName<SessionPayload>("sessionStartDate", index));
              }}
            />
          </Grid.Column>
          <Grid.Column className="session-detail-inputs">
            <ValidatedForm.TimePickerField
              label="Starts at"
              selected={session.startsAt || ""}
              propertyName={getPropName<SessionView>("startsAt", index)}
              markAsRequired
              disabled={disabled}
              errorPosition={ErrorPosition.bottom}
              className={"single-session-builder__time-picker"}
              {...validatedFieldProps}
              onBlur={() => {
                onBlur(getPropName<SessionPayload>("startsAtTicksMinutes", index));
              }}
            />
          </Grid.Column>
          <Grid.Column className="session-detail-inputs">
            <ValidatedForm.TimePickerField
              label="Ends at"
              selected={session.endsAt || ""}
              propertyName={getPropName<SessionView>("endsAt", index)}
              markAsRequired
              disabled={disabled}
              errorPosition={ErrorPosition.bottom}
              className={"single-session-builder__time-picker"}
              {...validatedFieldProps}
              onBlur={() => {
                onBlur(getPropName<SessionPayload>("endsAtTicksMinutes", index));
              }}
            />
          </Grid.Column>
          <Grid.Column className="session-detail-inputs">
            <TimezoneDropdown
              className={"timezone-width"}
              label="Time zone"
              propertyName={getPropName<SessionView>("timeZone", index)}
              value={session.timeZone || ""}
              markAsRequired
              disabled={disabled}
              error=""
              touched={false}
              icon={<Icon className="fa-clock" />}
              {...otherValidatedProps}
            />
          </Grid.Column>
          {!hideRemove && (
            <Grid.Column verticalAlign={"middle"} className="session-detail-inputs">
              <div className="remove-btn">
                <Icon
                  disabled={disabled}
                  className="minus circle icon"
                  size="big"
                  color="blue"
                  onClick={() => onRemove(index)}
                />
              </div>
            </Grid.Column>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

/* istanbul ignore next */
const getNeededProps = (props: SingleSessionBuilderRowProps) => {
  const { arrayHelpers, validatedFieldProps, onRemove, ...rest } = props;

  const errors = get(props.validatedFieldProps.errors, `sessions.${props.index}`);
  const touched = get(props.validatedFieldProps.touched, `sessions.${props.index}`);

  return { ...rest, errors, touched };
};

/* istanbul ignore next */
export default React.memo(SingleSessionBuilderRow, (prev, next) => {
  return isEqual(getNeededProps(prev), getNeededProps(next));
});
