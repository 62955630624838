import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import { useCallback, useEffect, useState } from "react";

import CardReporting from "components/cards/CardReporting/CardReporting";
import { ChartWrapper, LineChart } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "featureFlags";
import { usePerformanceBreadcrumbs } from "features/Library/Common/Hooks/usePerformanceBreadcrumbs";
import { defaultDateFilter, isBsi, noData, validLineData } from "features/Library/Common/utils/performanceUtils";
import { sharedAccountReportingLineProps } from "features/Reporting/Content/shared";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { AccountsTable } from "./components/AccountsTable";
import { EnterpriseTable } from "./components/EnterpriseTable";
import {
  fetchPeopleOverviewLineCharts,
  fetchPeopleOverviewStatistics,
  sharedOverviewLineProps,
  yearDateRange,
} from "./utils";
import moment from "moment";
import { RootState } from "features/Application/globaltypes/redux";
import { connect } from "react-redux";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import "../peopleStyles.scss";
import ReportingFilter from "components/reportingFilter/ReportingFilter";
import { useAccountFilterShouldShow } from "features/Reporting/Content/queries/useAccountFilterShouldShow";

export type Props = PropsFromRedux & {
  scrollToTop: () => void;
};

// Remove account id once data team is finished with bsi table.
export const PeopleOverviewReport = ({ scrollToTop, accountId, accountType }: Props) => {
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);
  const reportEnabled = !!useFeatureFlag(FeatureFlags.MauReports);
  const [dateFilter, setDateFilter] = useState(
    defaultDateFilter({
      initialDateRange: yearDateRange(),
      includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
      accountId,
    }),
  );

  useEffect(() => {
    setDateFilter(
      defaultDateFilter({
        initialDateRange: yearDateRange(),
        includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
        accountId,
      }),
    );
  }, [accountFilterShouldShow, accountId]);

  // Remove account id once data team is finished with bsi table.
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(accountId);

  const lineChartQuery = useQuery({
    queryFn: fetchPeopleOverviewLineCharts,
    queryKey: ["PeopleOverviewLineCharts", dateFilter],
    enabled: reportEnabled,
  });

  const cardsQuery = useQuery({
    queryFn: fetchPeopleOverviewStatistics,
    queryKey: ["PeopleOverviewStatistics", dateFilter],
    enabled: reportEnabled,
  });

  const { registerBreadcrumb, hasBreadcrumbsToDisplay, domElements } = usePerformanceBreadcrumbs();

  const handleAccountSelect = useCallback(
    (id: number, name: string) => {
      setSelectedAccountId(id);
      const isOwnAccount = id === accountId;
      setDateFilter({ ...dateFilter, accounts: isOwnAccount ? [] : [id], includeMyData: isOwnAccount ? true : false });
      registerBreadcrumb({ text: name, action: () => setSelectedAccountId(null) });
      scrollToTop();
    },
    [registerBreadcrumb, scrollToTop, accountId, dateFilter],
  );

  useEffect(() => {
    if (accountFilterShouldShow && isBsi(accountId)) setSelectedAccountId(null);
  }, [accountFilterShouldShow, accountId]);

  const tooltipFormatter = useCallback((x: Date, y: number) => {
    return `${moment(x).format("MMM 'YY")}: ${y.toLocaleString()} Users`;
    // Below is future state for when Interaction % are added to the endpoint
    // const xToString = x.toString();
    // const i = lineChartQuery.data!.activeUsers.xData[0].findIndex((date: Date) => date.toString() === xToString);
    // return i === -1 ? y.toLocaleString() : lineChartQuery.data!.activeUsersTooltips[i];
  }, []);

  return (
    <div className="performanceRoot">
      <div className="performanceHeader peopleActivityHeader">
        {hasBreadcrumbsToDisplay ? (
          <div className="breadCrumbs">{domElements}</div>
        ) : (
          <h2 className="performanceTitle">Summary</h2>
        )}
        {accountFilterShouldShow && isBsi(accountId) && (
          <div className="videoActions">
            <ReportingFilter
              currentFilter={{
                ...dateFilter,
              }}
              callback={setDateFilter}
              includeAccountsDropdown={accountFilterShouldShow && isBsi(accountId)}
              initialDateRange={yearDateRange()}
              ignoreInCountAndHide={["type", ...(hasBreadcrumbsToDisplay ? ["accounts", "includeMyData"] : "")]}
            />
          </div>
        )}
      </div>

      <div className={cn("lineChartContainer")}>
        <ChartWrapper titles={["Monthly Active Users"]} showLegend legendLabels={["Users"]}>
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isFetched &&
            lineChartQuery.data &&
            validLineData(lineChartQuery.data.activeUsers?.yData) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineChartQuery.data.activeUsers}
                {...sharedOverviewLineProps}
                tooltipFormatter={tooltipFormatter}
              />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={cn("lineChartContainer", "tabSpacer")}>
        <ChartWrapper titles={["Monthly Interactions"]} showLegend legendLabels={["Interactions"]}>
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isFetched &&
            lineChartQuery.data &&
            validLineData(lineChartQuery.data.interactions?.yData) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineChartQuery.data.interactions}
                {...sharedOverviewLineProps}
              />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className="tabSpacer">
        <RequestStatusRenderer state={cardsQuery.status}>
          <CardReporting items={cardsQuery.data!} />
        </RequestStatusRenderer>
      </div>

      {selectedAccountId === null ? (
        <AccountsTable dateFilter={dateFilter} handleAccountSelection={handleAccountSelect} />
      ) : (
        <EnterpriseTable
          includeHyperlink={
            (accountFilterShouldShow &&
              isBsi(accountId) &&
              dateFilter.accounts?.length > 0 &&
              selectedAccountId === accountId) ||
            selectedAccountId === accountId
          }
          dateFilter={dateFilter}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
    accountType: state.userProfile.accountTypeId,
  };
};

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PeopleOverviewReport);
