import { SortingDirection } from "enums";
import { AppThunk } from "features/Application/globaltypes/redux";
import DataService from "features/Application/services/dataServices/typedDataService";
import { formatFilters } from "utils/filterMapUtils";
import { FilterType } from "utils/filterUtils";
import { escapeTerm } from "utils/searchUtils";
import { fetchBegin, fetchSuccess } from "../slice/TemplateOverviewSlice";

export const fetchTemplates =
  (skip: number, top: number, sortBy: string, sortOrder: SortingDirection): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());
      const filtersState = getState().library.groupTemplatesAssignment.filters;
      const result = await DataService.groupTemplates.getTemplates({
        top,
        skip,
        sortBy,
        sortOrder,
        term: escapeTerm(filtersState.search),
        ...formatFilters(filtersState.appliedFilter, {
          dateCreated: FilterType.DateRange,
        }),
      });

      dispatch(
        fetchSuccess({
          items: result.items.map((x) => ({ ...x, isOwn: true })),
          totalCount: result.count,
        }),
      );
    } catch {}
  };
