import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { RootState, AppDispatch } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { reset } from "../state/GroupTemplateSlice";
import { reset as resetCustomers, setSelectedIds } from "../state/TemplateCustomersSlice";
import { reset as resetPacks } from "../state/TemplatePacksSlice";
import {
  ChangePeopleContentPriorityModal,
  EditPriorityLinkButton,
  GenericSectionHeader,
  RemoveLinkButton,
  RestrictedResource,
} from "../../../../components";
import navigationUtils from "../../../../utils/navigationUtils";
import { AssignmentPeopleContext, ContentTypesEnum, RolePermissions, TemplateTypes } from "../../../../enums";
import Segments from "../../../../components/navigation/segments/Segments";
import EditConfiguration from "./EditConfiguration";
import EditTemplateEnrollment from "./EditTemplateEnrollment";
import EditTemplateCustomers, { CustomersRef } from "./EditTemplateCustomers";
import { bindAction, ContentPriorityLevel } from "interfaces";
import { fetchTemplate } from "../state/thunks/TemplateThunk";
import SectionSubHeader from "components/sectionHeader/sectionSubHeader/SectionSubHeader";
import PeopleContent from "../Details/Content/PeopleContent";
import EditTemplatePacks from "./EditTemplatePacks";
import { RemovePacksConfirmationModal } from "./Packs/RemovePacksConfirmationModal";
import { Observable } from "utils";
import { removePacks } from "../state/thunks/TemplatePacksThunk";
import { Button } from "components/buttons/button/Button";
import AddPacksModal from "./Packs/AddPacksModal";
import { ContentType, PeopleType } from "features/Library/PeopleAssignments/types";
import { FlowItem, SelectedContentMap } from "../types/models";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import {
  getUpdatedPriorityItems,
  mapUserAssignmentsToPriorityItems,
} from "features/Library/PeopleAssignments/utils/peopleAssignmentUtils";
import {
  editPeopleContentPriority,
  removeContentPrioritiesDistributed,
} from "features/Library/PeopleAssignments/state/actions/commonActions";
import { setSelected } from "../state/TemplateContentSlice";
import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";
import { RemovePriorityConfirmationModal } from "components/people";
import { removeCustomers } from "../state/thunks/TemplateCustomersThunk";
import { RemoveCustomersModal } from "./Customers/RemoveCustomersModal";
import EditNotifySettings from "components/notifyStep/EditNotifySettings/EditNotifySettings";
import { useRestrictedCheck } from "features/Application/Restricted";

export type Props = PropsFromRedux & {
  preview?: boolean;
};

enum Pages {
  Configuration = "configuration",
  Enrollment = "enrollment",
  Packs = "packs",
  Customers = "customers",
  Content = "content",
  Notify = "notify",
}

export function EditGroupTemplate(props: Props) {
  const {
    isLoadingGroupInfo,
    groupInfo,
    isAccessAuthorized,
    fetchTemplate,
    resetTemplateState,
    resetCustomersState,
    resetPacksState,
    removePacksFromGroupTemplate,
    selectedFlowIds,
    editPriority,
    removeContentPriorities,
    setSelectedContentIds,
    selectedCustomerIds,
    removeCustomers,
    setSelectedCustomers,
    isRequested,
    preview,
  } = props;
  const flexiblePriorityDueDate = useFeatureFlag(FeatureFlags.FlexiblePriorityDueDate);
  const location = useLocation();
  const { pathname } = location;
  const rootPath = useResolvedPath("..").pathname;
  const params = useParams();
  const customersRef = useRef<CustomersRef>(null);
  const onTriggerRemovePacksObserver = useMemo(() => new Observable(), []);
  const onTriggerChangePriorityObserver = useMemo(() => new Observable(), []);
  const onRemoveCustomerObserver = useMemo(() => new Observable<(onSubmit: () => void, ids: number[]) => void>(), []);
  const onClearPriorityObserver = useMemo(() => new Observable<(onSubmit: () => void) => void>(), []);
  const [selectedPackIds, setSelectedPackIds] = useState<number[]>([]);
  const [showAddPacksModal, setShowAddPacksModal] = useState(false);
  const selectedContent = useRef<SelectedContentMap>(new Map());
  const hasManagePermission = useRestrictedCheck([RolePermissions.TemplatesManage]);

  const disabled = isRequested || !!preview || !hasManagePermission;

  const groupId = useMemo(
    () => parseInt(params.id!.toString()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    return () => {
      resetTemplateState();
      resetCustomersState();
      resetPacksState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTemplate(groupId, preview);
  }, [groupId, fetchTemplate, preview]);

  const navigate = useNavigate();

  const handleCustomerRemove = (ids: number[]) => {
    onRemoveCustomerObserver.notify(() => {
      removeCustomers(groupId, ids);
      setSelectedCustomers([]);
    }, ids);
  };

  const renderHeaderButtons = () => {
    if (disabled) {
      return [];
    }
    switch (currentStep) {
      case Pages.Packs:
        if (selectedPackIds.length) {
          return [<RemoveLinkButton key="remove-packs" onClick={() => onRemovePacksClick()} />];
        }
        return [
          <Button
            key="add-packs"
            primary
            content={"Add Packs"}
            className="create-button"
            onClick={() => setShowAddPacksModal(true)}
          />,
        ];
      case Pages.Customers:
        if (selectedCustomerIds.length) {
          return [
            <RemoveLinkButton key="remove-customers" onClick={() => handleCustomerRemove(selectedCustomerIds)} />,
          ];
        }
        return [customersRef.current?.renderAddCustomersButton()];
      case Pages.Content:
        if (selectedFlowIds?.length) {
          return [
            <EditPriorityLinkButton key="edit-priority" onClick={() => onEditPriorityClick()} />,
            renderClearPriorityButton(),
          ];
        }
        return [];
      default:
        return [];
    }
  };

  const onRemovePacksClick = (id?: number) => {
    onTriggerRemovePacksObserver.notify(
      () => removePacksFromGroupTemplate(groupId, id ? [id] : selectedPackIds),
      id ? 1 : selectedPackIds.length,
    );
  };
  const renderClearPriorityButton = () => {
    const assignments = [...selectedContent.current.values()];
    const unremovableFlowSelected = assignments.some((f) => !(f as FlowItem).canRemove);
    return (
      <RemovePriorityButton
        key="remove-priority-header-button"
        onClick={() => onClearPriorityClick(selectedFlowIds!)}
        isDisabled={unremovableFlowSelected}
        context="people"
        entityType={ContentType.Flow}
        peopleType={PeopleType.Group}
        showTooltip={unremovableFlowSelected}
        multiple
      />
    );
  };

  const onEditPriorityClick = (item?: FlowItem) => {
    const assignments = item ? [item] : [...selectedContent.current.values()];
    const priorityItems = mapUserAssignmentsToPriorityItems(assignments, ContentType.Flow, flexiblePriorityDueDate);
    onTriggerChangePriorityObserver.notify(() => {}, { [PeopleType.Group]: priorityItems });
  };

  const onClearPriorityClick = (ids: number[]) => {
    onClearPriorityObserver.notify(() => {
      const flowItems = { [ContentType.Flow]: ids };
      removeContentPriorities(groupId, AssignmentPeopleContext.TemplateGroup, flowItems);
      clearSelectedContent();
    });
  };

  const clearSelectedContent = () => {
    setSelectedContentIds({ context: ContentTypesEnum.Flows, selected: [] });
    selectedContent.current.clear();
  };

  const currentStep = useMemo(() => {
    return pathname.split("/").at(-1);
  }, [pathname]);

  const isInfoTab = currentStep === Pages.Configuration;

  return (
    <>
      <Dimmer active={isLoadingGroupInfo} inverted>
        <Loader active={isLoadingGroupInfo} />
      </Dimmer>

      <RestrictedResource isAuthorized={!!isAccessAuthorized}>
        <section className="nested-content">
          <GenericSectionHeader
            title={groupInfo.name || ""}
            titleForGA="Template Details"
            goBackAction={() => navigationUtils.goBackOrDefault(location, navigate, rootPath)}
            buttons={renderHeaderButtons()}
          />
          <SectionSubHeader
            lastModifiedDateTime={isInfoTab ? groupInfo.dateModified : undefined}
            isUpdateInProgress={false}
            publishedStatus={"published"}
          >
            <Segments to={`${rootPath}/${groupId}`}>
              {!isRequested && <Segments.Segment label="Configuration" />}
              <Segments.Segment to={Pages.Enrollment} label="Enrollment" />
              <Segments.Segment to={Pages.Packs} label="Packs" />
              {!isRequested && <Segments.Segment to={Pages.Customers} label="Customers" />}
              <Segments.Segment to={Pages.Content} label="Content" />
              {!isRequested && <Segments.Segment to={Pages.Notify} label="Notify" />}
            </Segments>
          </SectionSubHeader>
          <Routes>
            {isRequested || (
              <Route
                path="/"
                element={<EditConfiguration isLoading={isLoadingGroupInfo} groupId={groupId} disabled={disabled} />}
              />
            )}
            <Route
              path={Pages.Enrollment}
              element={<EditTemplateEnrollment templateId={groupId} disabled={disabled} />}
            />
            <Route
              path={Pages.Packs}
              element={
                <EditTemplatePacks
                  templateId={groupId}
                  selectedIds={selectedPackIds}
                  setSelectedIds={setSelectedPackIds}
                  onRemovePackClick={onRemovePacksClick}
                  disabled={disabled}
                />
              }
            />
            {isRequested || (
              <Route
                path={Pages.Customers}
                element={
                  <EditTemplateCustomers
                    handleRemoveClick={handleCustomerRemove}
                    templateId={groupId}
                    ref={customersRef}
                    disabled={disabled}
                  />
                }
              />
            )}
            <Route
              path={Pages.Content}
              element={
                <PeopleContent
                  templateId={groupId}
                  contentMap={selectedContent.current}
                  contextMenuButtonHandlers={{
                    onEditPriority: onEditPriorityClick,
                    onClearPriority: onClearPriorityClick,
                  }}
                  disabled={disabled}
                />
              }
            />
            {isRequested || (
              <Route
                path={Pages.Notify}
                element={
                  <EditNotifySettings
                    templateType={TemplateTypes.ApproveGroupTemplate}
                    notificationId={`TemplateGroup_${groupId}`}
                    switchLabelText="Notify New Customers?"
                    switchTooltipText="Enable this option to automatically notify new customer admins when they are added to this template."
                    saveComplete={() => {
                      fetchTemplate(groupId, preview);
                    }}
                  />
                }
              />
            )}
            <Route path="*" element={<Navigate to={isRequested ? `../${Pages.Enrollment}` : "../"} replace />} />
          </Routes>
          <RemovePriorityConfirmationModal peopleType={PeopleType.Group} removeObserver={onClearPriorityObserver} />
          <ChangePeopleContentPriorityModal
            onSave={(assignments: { [key in PeopleType]?: ContentPriorityLevel[] }) => {
              getUpdatedPriorityItems(assignments, (items: ContentPriorityLevel[]) => {
                editPriority(items, groupId, flexiblePriorityDueDate);
              });
              clearSelectedContent();
            }}
            onTriggerModalObserver={onTriggerChangePriorityObserver}
          />
          <RemovePacksConfirmationModal
            onTriggerRemovePacksObserver={onTriggerRemovePacksObserver}
            onConfirmed={() => setSelectedPackIds([])}
          />
          <AddPacksModal showModal={showAddPacksModal} setShowModal={setShowAddPacksModal} templateId={groupId} />
          <RemoveCustomersModal removeObserver={onRemoveCustomerObserver} />
        </section>
      </RestrictedResource>
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { groupTemplate } = state.people;

  return {
    groupInfo: groupTemplate.base.configuration,
    isLoadingGroupInfo: groupTemplate.base.isLoading,
    isAccessAuthorized: groupTemplate.base.isAccessAuthorized,
    selectedFlowIds: groupTemplate.content.content.selected.Flows,
    selectedCustomerIds: groupTemplate.customers.selectedIds,
    // need to handle the actual template status until own and vendor templates are separated on BE side
    isRequested: groupTemplate.base.isPreview,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetTemplateState: () => dispatch(reset()),
    resetCustomersState: () => dispatch(resetCustomers()),
    resetPacksState: () => dispatch(resetPacks()),
    fetchTemplate: bindAction(fetchTemplate, dispatch),
    removePacksFromGroupTemplate: bindAction(removePacks, dispatch),
    editPriority: (priorityLevels: ContentPriorityLevel[], peopleId: number, useFlexibleDueDate: boolean) =>
      dispatch(editPeopleContentPriority(priorityLevels, peopleId, PeopleType.TemplateGroup, useFlexibleDueDate)),
    removeContentPriorities: bindAction(removeContentPrioritiesDistributed, dispatch),
    setSelectedContentIds: bindAction(setSelected, dispatch),
    removeCustomers: bindAction(removeCustomers, dispatch),
    setSelectedCustomers: bindAction(setSelectedIds, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditGroupTemplate);
