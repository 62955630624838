import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, AppThunk, RootState } from "../../../../Application/globaltypes/redux";
import { VideoOverview, VideoOverviewRequest, videoFilterTypes } from "../../types/models";
import { escapeTerm } from "../../../../../utils/searchUtils";
import { Filters } from "../../../../../utils/queryUtils";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { getPrefix } from "../../../../Application/slices/models";
import { DistributedOpUpdateParams, FetchActionPayload } from "../../../../../interfaces";
import { videoAppliedFilterSelector, videoSearchSelector } from "../selectors/overviewSelectors";
import { DEFAULT_LOAD_COUNT } from "../../types/constants";
import {
  fetchFailure,
  insertVideoAssetSuccess,
  updateVideoAssetBegin,
  updateVideoAssetSuccess,
} from "../slices/videoOverviewSlice";
import { pluralize } from "../../../../../utils/stringUtils";

import DataService from "../../../../Application/services/dataServices/typedDataService";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";
import { withCancel } from "features/Application/services/dataServices/utils";
import { VideoArgs, VideoContentResponse } from "features/Application/services/dataServices/contentDataService";
import { AxiosResponse } from "axios";
import { formatFilters } from "utils/filterMapUtils";

export interface VideosRequest {
  appliedFilter: Filters;
  skip: number;
  top: number;
  term: string;
  sortBy?: string;
  sortOrder?: string;
}

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const sendGetVideoRequest = (request: VideosRequest) => {
  const { appliedFilter, skip, top, term, sortBy, sortOrder } = request;
  const filterQueryParams = formatFilters(appliedFilter, videoFilterTypes);

  const requestV4: VideoArgs = {
    top,
    skip,
    sortBy,
    sortOrder,
    filters: filterQueryParams,
    term,
    showPurchased: true,
    contentType: "video",
  };

  const result = withCancel<[VideoArgs], Promise<AxiosResponse<VideoContentResponse[]>>>(
    DataService.content.getContent,
  )(requestV4);

  return result;
};

export const fetchOverviewVideos = createAsyncThunk<FetchActionPayload<VideoOverview>, VideoOverviewRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Videos,
    name: "overview",
  }),
  async (requestData: VideoOverviewRequest, { getState, signal }) => {
    const rootState = getState() as RootState;
    const searchTerm = escapeTerm(videoSearchSelector(rootState));
    const appliedFilters = videoAppliedFilterSelector(rootState);

    const { promise, cancel } = sendGetVideoRequest({
      appliedFilter: appliedFilters,
      skip: requestData.skip ?? 0,
      top: requestData.top ?? DEFAULT_LOAD_COUNT,
      term: searchTerm ?? "",
      sortBy: requestData.sortBy,
      sortOrder: requestData.sortDirection,
    });

    signal.addEventListener("abort", cancel);

    const result = await promise;

    const data = result.data.map((item) => ({
      ...item,
      thumbnailUrl: item.bag.thumbnailUrl,
      durationInSeconds: item.bag.duration
    }));

    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: data,
      totalCount: recordsCount,
      append: requestData.append,
    };
  },
);

export const updateVideoAsset = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(updateVideoAssetBegin());
  try {
    const result = await DataService.assets.getVideoInfo(id);
    dispatch(updateVideoAssetSuccess(result.data));
  } catch (error) {
    dispatch(fetchFailure(error));
  }
};

export const insertVideoAsset =
  (
    params: { videoAssetId: number; position: number; needToRemoveItem: boolean; count: number },
    onSuccess: () => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const result = await DataService.assets.getVideoInfo(params.videoAssetId);
      dispatch(
        insertVideoAssetSuccess({
          videoAsset: result.data,
          position: params.position,
          count: params.count,
          needToRemoveItem: params.needToRemoveItem,
        }),
      );
      onSuccess?.();
    } catch (error) {
      dispatch(fetchFailure(error));
    }
  };

export const deleteVideosAction =
  (ids: number[], fetchVideos: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getOperationProps = async () => {
      const { data } = await DataService.assets.deleteVideos(ids);
      return data;
    };

    const idsCount = ids.length;
    const entity = pluralize("Video", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "DeletionVideoAssets",
      title: `Deletion of Videos`,
      onCompleted: () => fetchVideos(),
      getOperationProps,
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been deleted!`,
      failureTransientMessage: `${entity} delete failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const changeVisibility =
  (ids: number[], visible: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const idsCount = ids.length;
    const entity = pluralize("Video", idsCount);
    const asset = pluralize("Asset", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "VisibilityVideosDistributedOperation",
      title: `${visible ? "Unhiding" : "Hiding"} ${asset}`,
      getOperationProps: async () => {
        const { data } = await DataService.assets.changeVisibility(ids, visible);
        return data;
      },
      successTitle:`${entity} ${visible ? "Unhidden" : "Hidden"}!`,
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been ${visible ? "unhidden" : "hidden"} successfully!`,
      failureTransientMessage: `${entity} ${visible ? "unhiding" : "hiding"} failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };