import { batch } from "react-redux";

import * as libraryEventTypes from "../events/library/libraryEvents";

import AlertTypes from "../../../../../enums/alertTypes";
import { Dispatcher } from "../../../../../interfaces";
import { IMapping } from "./types";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import {
  fetchLockedFlowSuccess,
  publishDraftFlowSuccess,
  revertFlowEntityToPublishedSuccess,
  updateLockedFlowCommandCompleted,
} from "../../../../Library/Flows/state/actions/flowEntityStateActionCreators";
import { processExternalFlowUpdate } from "../../../../Library/Flows/state/actions/flowActionCreators";
import { createDispatcher } from "../dispatcher";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import {
  updateFailedTaskDistributedOperation,
  updateSucceededTaskDistributedOperation,
  updateProgressTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";

const flowErrorIsShowedInSeconds = 10;

const handleIdenticalInMapping = (mapping: IMapping) => (eventTypes: string[], handler: (payload: any) => void) => {
  for (const eventType of eventTypes) {
    mapping[eventType] = handler;
  }
};

export const flowMapping = (mapping: IMapping, dispatch: Dispatcher) => {
  const handleIdentical = handleIdenticalInMapping(mapping);
  const { dispatchTaskOperationSuccess, dispatchTaskOperationFailure } = createDispatcher(dispatch);

  const flowsUpdated = (payload: any) => {
    const { id: flowId } = payload;
    const flowUpdate = {
      dateModified: dateTimeUtils.getCurrentDate().toISOString(),
    };

    dispatch(processExternalFlowUpdate(flowId, flowUpdate));
  };

  const payloadMapping = (data: { MessageId: string; FlowId: number }) => ({
    messageId: data.MessageId,
    entityId: data.FlowId,
  });

  mapping[libraryEventTypes.FlowCreated] = (payload) => {
    dispatch(sendTransientNotification(payload));
  };

  mapping[libraryEventTypes.FlowPublishSuccess] = (payload) => {
    batch(() => {
      dispatch(publishDraftFlowSuccess(payload.id));
      dispatch(sendTransientNotification({ ...payload, type: AlertTypes.success }));
    });
  };

  mapping[libraryEventTypes.FlowDiscardSuccess] = (payload) => {
    const { id } = payload;
    dispatch(revertFlowEntityToPublishedSuccess(id));
  };

  mapping[libraryEventTypes.FlowEditSuccess] = (payload) => flowsUpdated(payload);
  mapping[libraryEventTypes.FlowEditGoalsSuccess] = (payload) => flowsUpdated(payload);
  mapping[libraryEventTypes.FlowEditDefinitionSuccess] = (payload) => flowsUpdated(payload);
  mapping[libraryEventTypes.FlowEditExternalTriggersSuccess] = (payload) => flowsUpdated(payload);

  mapping[libraryEventTypes.FlowsCommandCompleted] = (payload) => {
    dispatch(updateLockedFlowCommandCompleted(payload.id));
  };

  mapping[libraryEventTypes.SetAllFlowUserPrioritiesSuccess] = (payload) => {
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  };
  mapping[libraryEventTypes.SetAllFlowUserPrioritiesFailure] = (payload) => {
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  };
  mapping[libraryEventTypes.SetFlowUserPrioritiesSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.SetFlowUserPrioritiesFailure] = (payload) => {
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  };

  mapping[libraryEventTypes.SetAllFlowGroupPrioritySuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.SetAllFlowGroupPriorityFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.SetFlowGroupPrioritySuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.SetFlowGroupPriorityFailure] = (payload) => {
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  };

  mapping[libraryEventTypes.SetAllFlowTemplateGroupPrioritySuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.SetAllFlowTemplateGroupPriorityFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.SetFlowTemplateGroupPrioritySuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.SetFlowTemplateGroupPriorityFailure] = (payload) => {
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
};

  mapping[libraryEventTypes.AddAllFlowUserSuccess] = (payload) => {
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  };
  mapping[libraryEventTypes.AddFlowUserSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.AddAllFlowUserFail] = (payload) => {
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  };
  mapping[libraryEventTypes.AddFlowGroupSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.AddFlowGroupFailure] = (payload) => {
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  };
  mapping[libraryEventTypes.AddAllFlowGroupSuccess] = (payload) => {
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  };
  mapping[libraryEventTypes.AddAllFlowGroupFailure] = (payload) => {
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  };

  mapping[libraryEventTypes.RemoveFlowUserSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.RemoveFlowUserFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.RemoveAllFlowUserSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.RemoveAllFlowUserFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.RemoveFlowGroupSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.RemoveFlowGroupFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.RemoveAllFlowGroupSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.RemoveAllFlowGroupFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.RemoveFlowUserPrioritiesSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.RemoveFlowUserPrioritiesFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.RemoveAllFlowUserPrioritiesSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.RemoveAllFlowUserPrioritiesFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.RemoveFlowGroupPrioritySuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.RemoveFlowGroupPriorityFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.RemoveAllFlowGroupPrioritySuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.RemoveAllFlowGroupPriorityFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.FlowAddTagsSuccess] = dispatchTaskOperationSuccess((data) => data);
  mapping[libraryEventTypes.FlowAddTagsFailure] = dispatchTaskOperationFailure((data) => data);

  mapping[libraryEventTypes.FlowDuplicateSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.FlowDuplicateAllSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowDuplicateFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowDuplicateAllFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.FlowEditVisibilitySuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.FlowEditVisibilityAllSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowEditVisibilityFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowEditVisibilityAllFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.FlowStartSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.FlowStartAllSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowStartFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowStartAllFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.FlowGroupStartSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.FlowGroupStartAllSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowGroupStartFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowGroupStartAllFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.FlowDeleteSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.FlowDeleteAllSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowDeleteFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.FlowDeleteAllFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.FlowLockSuccess] = (payload) => {
    dispatch(fetchLockedFlowSuccess(payload.id));
  };

  mapping[libraryEventTypes.UserFlowPriorityChangedSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[libraryEventTypes.UserFlowPriorityChangedFailure] = dispatchTaskOperationFailure(payloadMapping);
  mapping[libraryEventTypes.GroupFlowPriorityChangedSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[libraryEventTypes.GroupFlowPriorityChangedFailure] = dispatchTaskOperationFailure(payloadMapping);

  handleIdentical(
    [
      libraryEventTypes.FlowLockFailure,
      libraryEventTypes.FlowDiscardFailure,
      libraryEventTypes.FlowEditDefinitionFailure,
      libraryEventTypes.FlowEditExternalTriggersFailure,
      libraryEventTypes.FlowEditFailure,
      libraryEventTypes.FlowEditGoalsFailure,
      libraryEventTypes.FlowPublishFailure,
    ],
    (payload: { messageId: string; errorMessage: string }) => {
      dispatch(
        sendTransientNotification(
          {
            message: `Flow failure. ${payload.errorMessage}`,
            type: AlertTypes.error,
          },
          flowErrorIsShowedInSeconds,
        ),
      );
    },
  );
};
