import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import { RequestState, RStatus } from "../../../../../Application/globaltypes/fetchRequest";
import { RootState } from "../../../../../Application/globaltypes/redux";
import {
  EmailActivity,
  EmailLineData,
  EmailSendDetailEntity,
  EmailSendSession,
} from "../../EmailDetails/Performance/types";
import { clicksColor, lineChartFactory, opensColor, sendsColor } from "../../../../Common/utils/performanceUtils";

import { DataPoint } from "components/charts/types/HorizontalBarChart";

interface EmailPerformanceSliceState {
  selectedSendSession?: EmailSendSession;
  sendDensity: RequestState<EmailLineData[]>;
  engagement: RequestState<EmailActivity>;
  sendSessions: RequestState<EmailSendSession[]>;
  sendsDetails: RequestState<EmailSendDetailEntity[]>;
}

const initialRequestState: RequestState<any[]> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

export const initialState: EmailPerformanceSliceState = {
  selectedSendSession: undefined,
  sendDensity: initialRequestState,
  engagement: {
    status: RStatus.Idle,
    value: {
      Sends: 0,
      Opens: 0,
      Clicks: 0,
      OpenRate: 0,
      ClickRate: 0,
    },
    errorMessage: undefined,
  },
  sendsDetails: initialRequestState,
  sendSessions: initialRequestState,
};

const emailPerformanceSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Emails}/emailPerformance`,
  initialState,
  reducers: {
    setSelectedSendSession(state, action: PayloadAction<EmailPerformanceSliceState["selectedSendSession"]>) {
      state.selectedSendSession = action.payload;
    },
    reqSendDensity(state) {
      state.sendDensity.status = RStatus.Pending;
    },
    setSendDensity(state, action: PayloadAction<{ data: EmailLineData[] }>) {
      state.sendDensity = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSendDensityError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.sendDensity = {
        ...state.sendDensity,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqEngagementData(state) {
      state.engagement.status = RStatus.Pending;
    },
    setEngagementData(state, action: PayloadAction<{ data: EmailActivity }>) {
      state.engagement = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.engagement = {
        ...state.engagement,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqSendSessions(state) {
      state.sendSessions.status = RStatus.Pending;
    },
    setSendSessions(state, action: PayloadAction<{ data: EmailPerformanceSliceState["sendSessions"]["value"] }>) {
      state.sendSessions = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSendSessionsError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.sendSessions = {
        ...state.sendSessions,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqSendsDetails(state) {
      state.sendsDetails.status = RStatus.Pending;
      state.sendsDetails.value = [];
    },
    setSendsDetails(state, action: PayloadAction<{ data: EmailSendDetailEntity[] }>) {
      state.sendsDetails = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSendsDetailsError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.sendsDetails = {
        ...state.sendsDetails,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const emailPerformanceReducer = emailPerformanceSlice.reducer;

export const {
  setSelectedSendSession,
  reqSendDensity,
  setSendDensity,
  setSendDensityError,
  reqEngagementData,
  setEngagementData,
  setEngagementError,
  reqSendSessions,
  setSendSessions,
  setSendSessionsError,
  reqSendsDetails,
  setSendsDetails,
  setSendsDetailsError,
  reset,
} = emailPerformanceSlice.actions;

export const selectActiveSendSession = (state: RootState) => state.library.emails.performance.selectedSendSession;
export const selectSendDensityState = (state: RootState) => state.library.emails.performance.sendDensity;
export const selectEngagmentState = (state: RootState) => state.library.emails.performance.engagement;
export const selectSendDetailsState = (state: RootState) => state.library.emails.performance.sendsDetails;
export const selectSendSessions = (state: RootState) => state.library.emails.performance.sendSessions;

export const selectSendDensityStatus = createSelector(selectSendDensityState, (r) => r.status);
export const selectSendDensityData = createSelector(selectSendDensityState, (r) => r.value);
export const selectSendDensityError = createSelector(selectSendDensityState, (r) => r.errorMessage);
export const selectEngagementStatus = createSelector(selectEngagmentState, (r) => r.status);
export const selectEngagementData = createSelector(selectEngagmentState, (r) => r.value);
export const selectEngagementError = createSelector(selectEngagmentState, (r) => r.errorMessage);
export const selectSendDetailsStatus = createSelector(selectSendDetailsState, (r) => r.status);
export const selectSendDetailsData = createSelector(selectSendDetailsState, (r) => r.value);
export const selectSendDetailsError = createSelector(selectSendDetailsState, (r) => r.errorMessage);

export const selectSendDensityLineData = createSelector(selectSendDensityData, (density) => {
  return lineChartFactory(density);
});

export const selectEngagementChart = createSelector(selectEngagementData, (engagementData): DataPoint[] => [
  {
    id: "sends",
    category: "Sends",
    fillColor: sendsColor,
    value: engagementData.Sends,
  },
  {
    id: "opens",
    category: "Opens",
    fillColor: opensColor,
    value: engagementData.Opens,
  },
  {
    id: "clicks",
    category: "Clicks",
    fillColor: clicksColor,
    value: engagementData.Clicks,
  },
]);
