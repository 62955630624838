import { AppDispatch } from "../../../../../Application/globaltypes/redux";
import { EmailSendSession } from "../../SendSessions/CreateEmailSendSession";
import emailsDataService from "../../services/emailsDataService";
import { cancelBegin, cancelFailure, cancelSuccess } from "../slices/emailCancelSendSlice";
import { createBegin, createFailure, createSuccess } from "../slices/emailCreateSendSlice";

const createEmailSend = (emailId: number, config: EmailSendSession) => async (dispatch: AppDispatch) => {
  dispatch(createBegin());
  try {
    await emailsDataService.createEmailSend(emailId, config);
    dispatch(createSuccess());
  } catch (e) {
    dispatch(createFailure(e.message));
  }
};

const cancelEmailSend = (sendId: number) => async (dispatch: AppDispatch) => {
  dispatch(cancelBegin());
  try {
    await emailsDataService.cancelSendSession(sendId);
    dispatch(cancelSuccess());
  } catch (e) {
    dispatch(cancelFailure(e.message));
  }
};

export { cancelEmailSend, createEmailSend };
